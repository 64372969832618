import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import {
  OutlinedInput,
  InputAdornment,
  Stack,
  TextField,
  InputLabel,
  FormControl
} from '@mui/material';
import { profileService } from '../../../services/profiles';
import EditRegistrationDataModal from '../../../components/Profile/EditRegistrationDataModal';
import { useNavigate } from 'react-router-dom';
import axios from '../../../services/axios';
import { useAuth } from '../../../hooks/auth';
import { useDispatch } from 'react-redux';
import AuthLayout from '../../../bidder/layouts/AuthLayout';

const Profile = () => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [jobPosition, setJobPosition] = useState('');
  const [errors, setErrors] = useState([]);
  const [openRegistrationDataModal, setOpenRegistrationDataModal] = useState(false);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useAuth({
    middleware: 'auth',
    redirectIfAuthenticated: '/'
  });

  const logout = async () => {
    await axios.post('/logout');

    window.location.pathname = '/login';
  };

  const submitForm = (event) => {
    event.preventDefault();
    setErrors([]);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    return profileService
      .index()
      .then((res) => {
        setUser(res.data);
        setName(res.data.name);
        setEmail(res.data.email);
        setSurname(res.data.surname);
        setPhone(res.data.phone);
        setCompany(res.data.company);
        setJobPosition(res.data.job_position);
      })
      .catch((err) => console.log('error', err));
  };

  const redirectUser = () => {
    if (user.role === 'admin') {
      navigate('/dashboard');
    }
    if (user.role === 'vendor') {
      navigate('/vendor/biddings');
    }
    if (user.role === 'client') {
      navigate('/client/biddings');
    }
  };

  const handleSave = () => {
    profileService
      .update({
        name: name,
        surname: surname,
        password: password,
        password_confirmation: passwordConfirmation
      })
      .then(function () {
        dispatch({
          type: 'SHOW',
          text: `Profile updated`,
          toast_type: 'success'
        });

        setErrors([]);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  return (
    <>
      <AuthLayout>
        <Container
          component="main"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Box
            sx={{
              width: '100%',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              padding: '58px 106px 38px 106px',
              boxShadow:
                '0px 100px 80px rgba(0, 0, 0, 0.1), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0718854), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0596107), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.05), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0403893), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0281146)'
            }}>
            <Typography variant="authTitle" mb={1.8}>
              Sign in
            </Typography>
            <Typography variant="smallGreyText" mb={4.5}>
              Verify the following information
            </Typography>
            <Box component="form" onSubmit={submitForm} sx={{ mt: 1 }}>
              <Stack spacing={2}>
                <TextField
                  variant={'outlined'}
                  size={'small'}
                  fullWidth
                  error={errors.name}
                  label="Name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  helperText={errors.name}
                />
                <TextField
                  variant={'outlined'}
                  size={'small'}
                  fullWidth
                  error={errors.surname}
                  label="Surname"
                  value={surname}
                  onChange={(event) => setSurname(event.target.value)}
                  helperText={errors.surname}
                />
                <FormControl fullWidth variant="outlined" size={'small'}>
                  <InputLabel htmlFor="standard-adornment-email">Email</InputLabel>
                  <OutlinedInput
                    id="standard-adornment-email"
                    type={'text'}
                    disabled
                    value={email}
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <Button
                          color={'success'}
                          style={{ paddingRight: '39px' }}
                          variant={'fm-mini'}
                          href={`mailto:${process.env.REACT_APP_ADMIN_EMAIL}?subject=Change email`}>
                          Change email
                        </Button>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <TextField
                  disabled={true}
                  variant={'outlined'}
                  size={'small'}
                  fullWidth
                  error={errors.phone}
                  label="Phone"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                  helperText={errors.phone}
                />
                <TextField
                  disabled={true}
                  variant={'outlined'}
                  size={'small'}
                  fullWidth
                  error={errors.company}
                  label="Company"
                  value={company}
                  onChange={(event) => setCompany(event.target.value)}
                  helperText={errors.company}
                />
                <TextField
                  disabled={true}
                  variant={'outlined'}
                  size={'small'}
                  fullWidth
                  error={errors.job_position}
                  label="Job position"
                  value={jobPosition}
                  onChange={(event) => setJobPosition(event.target.value)}
                  helperText={errors.job_position}
                />
                <TextField
                  fullWidth
                  size={'small'}
                  error={errors.password}
                  label="Password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  helperText={errors.password}
                />
                <TextField
                  fullWidth
                  size={'small'}
                  error={errors.password_confirmation}
                  label="Password confirmation"
                  value={passwordConfirmation}
                  onChange={(event) => setPasswordConfirmation(event.target.value)}
                  helperText={errors.password_confirmation}
                />
              </Stack>
              <Stack
                direction={'row'}
                spacing={2}
                justifyContent={'space-between'}
                sx={{ marginTop: '35px', marginBottom: '35px' }}>
                <Button variant={'fm-outlined'} sx={{ flexGrow: 1 }} onClick={() => handleSave()}>
                  Save
                </Button>
                <Button variant={'fm-outlined'} sx={{ flexGrow: 1 }} onClick={() => redirectUser()}>
                  Next
                </Button>
                <Button variant={'fm-outlined'} sx={{ flexGrow: 1 }} onClick={() => logout()}>
                  Cancel
                </Button>
              </Stack>
            </Box>
          </Box>
        </Container>
      </AuthLayout>
      <EditRegistrationDataModal
        open={openRegistrationDataModal}
        user={user}
        onClose={() => setOpenRegistrationDataModal(false)}
        onSuccess={() => getUserData()}
      />
    </>
  );
};
export default Profile;
