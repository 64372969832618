import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { ReactComponent as Letter } from '../../../assets/images/letter.svg';
const SignedModal = ({ handleClose, open }) => {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle>Signed!</DialogTitle>
      <DialogContent>
        <Typography variant={'body2'}>The Document was signed correctly!</Typography>
        <Box display={'flex'} marginY={'25px'} justifyContent={'center'}>
          <Letter />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant={'fm-light'} onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SignedModal;
