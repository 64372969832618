import { formatDateTime } from '../../../../services/date';
import React, { useEffect, useState } from 'react';
import BidderNotificationIcon from '../../BidderNotificationIcon';
import Table from '../../Table/Table';
import TableHeader from '../../Table/TableHeader';
import TableBody from '../../Table/TableBody';
import { cutString } from '../../../helpers/string-helper';
import TablePagination from '@mui/material/TablePagination';

export default function VendorStatusNotificationsPanel({ notifications = [] }) {
  const [items, setItems] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const visibleRows = React.useMemo(
    () => items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, items]
  );

  useEffect(() => {
    setItems(notifications);
  }, [notifications]);

  return (
    <>
      <Table>
        <TableHeader>
          <tr>
            <th></th>
            <th>Title</th>
            <th>Message</th>
            <th>Notifiable at</th>
            <th>Readed at</th>
          </tr>
        </TableHeader>
        <TableBody>
          {visibleRows.map((notification) => (
            <tr key={notification.id} className={notification.read ? 'success-row' : ''}>
              <td>
                <BidderNotificationIcon type={notification.type} />
              </td>
              <td>{notification.title}</td>
              <td>{cutString(notification.content, 33)}</td>
              <td>{formatDateTime(notification.date)}</td>
              <td>{notification.read ? formatDateTime(notification.read_at) : ''}</td>
            </tr>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[3, 10, 25]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
