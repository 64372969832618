import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { userService } from '../../../services/admin';
import { formatDateTime } from '../../../services/date';
import SearchInput from '../../../components/SearchInput/SearchInput';

import PageWrapper from '../../../bidder/components/Main/PageWrapper';
import Header from '../../../bidder/components/Main/Header';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import Content from '../../../bidder/components/Main/Content';
import TablePagination from '@mui/material/TablePagination';
import Table from '../../../bidder/components/Table/Table';
import TableHeader from '../../../bidder/components/Table/TableHeader';
import TableBody from '../../../bidder/components/Table/TableBody';
import { useParams } from 'react-router-dom';

const Activity = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getUsersData = () => {
    userService
      .activity(id, {
        params: {
          filter: {
            search
          },
          page: page + 1,
          per_page: rowsPerPage
        }
      })
      .then((res) => {
        setData(res.data);
      });
  };

  useEffect(() => {
    getUsersData();
  }, [search, page, rowsPerPage]);

  const renderHeaderContentCallback = () => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '45px',
            padding: '0 10px'
          }}>
          <Box sx={{ width: '520px' }}>
            <SearchInput
              value={search}
              onChange={(event) => {
                setPage(0);
                setSearch(event.target.value);
              }}
            />
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <PageWrapper>
        <Header
          title="User Activity Log"
          renderHeaderContentCallback={renderHeaderContentCallback}
        />
        <Content>
          <BidderPanel>
            <Table>
              <TableHeader>
                <tr>
                  <th className={'align-left-column'} style={{ width: '200px' }}>
                    id
                  </th>
                  <th>Bidding</th>
                  <th>Stage</th>
                  <th>Action</th>
                  <th>Result</th>
                  <th>Extra</th>
                  <th width={'150px'}>On date</th>
                </tr>
              </TableHeader>
              <TableBody>
                {data !== null &&
                  data.data.map((user) => {
                    return (
                      <tr key={user.id}>
                        <td className={'align-left-column'}>
                          <Typography variant="DarkGreyText14">{user.id}</Typography>
                        </td>
                        <td>
                          <Typography variant="DarkGreyText14">{user.bidding_id}</Typography>
                        </td>
                        <td>
                          <Typography variant="DarkGreyText14">{user.stage}</Typography>
                        </td>
                        <td>
                          <Typography variant="DarkGreyText14">{user.action}</Typography>
                        </td>
                        <td>
                          <Typography variant="DarkGreyText14">{user.result}</Typography>
                        </td>
                        <td>
                          <Typography variant="DarkGreyText14">
                            {JSON.stringify(user.extra_data)}
                          </Typography>
                        </td>
                        <td>
                          <Typography variant={'body2'}>
                            {formatDateTime(user.created_at)}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
              </TableBody>
            </Table>
            {data !== null && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.meta.total}
                rowsPerPage={data.meta.per_page}
                page={data.meta.current_page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </BidderPanel>
        </Content>
      </PageWrapper>
    </>
  );
};
export default Activity;
