import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { ReactComponent as Add } from '../../../../../assets/images/circled-plus.svg';
import React, { useState } from 'react';
import { Loading } from '../../../../Loading/Loading';
import { Delete, Edit } from '@mui/icons-material';
import StoreScoreStructureModal from './Modals/StoreScoreStructureModal';
import UpdateScoreStructureModal from './Modals/UpdateScoreStructureModal';
import DestroyScoreStructureModal from './Modals/DestroyScoreStructureModal';

const headCells = [
  {
    id: 'number',
    numeric: false,
    disablePadding: true,
    label: '#'
  },
  {
    id: 'section',
    numeric: false,
    disablePadding: false,
    label: 'SECTION'
  },
  {
    id: 'WEIGHT',
    numeric: false,
    disablePadding: false,
    label: 'WEIGHT'
  },
  {
    id: 'COMMENTS',
    numeric: false,
    disablePadding: false,
    label: 'COMMENTS'
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'ACTIONS'
  }
];

const ScoringStructureTable = ({
  readOnly = true,
  bidding = null,
  subBidding = null,
  stage = null,
  scores = null,
  onUpdateEvent
}) => {
  const [scoreToUpdate, setScoreToUpdate] = useState(null);
  const [scoreToDestroy, setScoreToDestroy] = useState(null);
  const [showScoreModal, setShowScoreModal] = useState(false);

  if (scores === null) {
    return <Loading />;
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                return (
                  <TableCell key={headCell.id} align="center" padding="none">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                      <Typography variant="DarkGreyText14">{headCell.label}</Typography>
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {scores.map((score, key) => {
              return (
                <TableRow hover tabIndex={-1} key={key}>
                  <TableCell align={'center'}>{++key}</TableCell>
                  <TableCell align="center" width={'120px'}>
                    <Typography variant="DarkGreyText14">{score.section}</Typography>
                  </TableCell>
                  <TableCell align="center" width={'70px'}>
                    <Typography variant="DarkGreyText14">{score.weight}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="DarkGreyText14">{score.comment}</Typography>
                  </TableCell>
                  <TableCell align="center" width={'120px'}>
                    <Typography variant="DarkGreyText14">
                      <IconButton disabled={readOnly} onClick={() => setScoreToUpdate(score)}>
                        <Edit />
                      </IconButton>
                      <IconButton disabled={readOnly} onClick={() => setScoreToDestroy(score)}>
                        <Delete />
                      </IconButton>
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          {readOnly ? (
            ''
          ) : (
            <TableBody>
              <TableRow key="new">
                <TableCell colSpan={4}></TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => setShowScoreModal(true)}>
                    <Add />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <StoreScoreStructureModal
        open={showScoreModal}
        bidding={bidding}
        subBidding={subBidding}
        stage={stage}
        onSuccess={() => {
          onUpdateEvent();
          setShowScoreModal(false);
        }}
        onClose={() => setShowScoreModal(false)}
      />
      <UpdateScoreStructureModal
        bidding={bidding}
        subBidding={subBidding}
        stage={stage}
        score={scoreToUpdate}
        onSuccess={() => {
          onUpdateEvent();
          setScoreToUpdate(null);
        }}
        onClose={() => setScoreToUpdate(null)}
      />
      <DestroyScoreStructureModal
        bidding={bidding}
        subBidding={subBidding}
        stage={stage}
        score={scoreToDestroy}
        onSuccess={() => {
          onUpdateEvent();
          setScoreToDestroy(null);
        }}
        onClose={() => setScoreToDestroy(null)}
      />
    </>
  );
};

export default ScoringStructureTable;
