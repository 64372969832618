import { formatDateTimeWithTimezone } from '../../../../services/date';

export default function ActivityLog({ items }) {
  return (
    <div className={'activity-log'}>
      <div className={'activity-log-content'}>
        {items.map((item) => (
          <div className={'activity-log-item'} key={item.id}>
            <strong>{item.label}</strong>&nbsp;
            <span>{item.description}</span>&nbsp;
            <span>{formatDateTimeWithTimezone(item.date, 'UTC')}</span>
          </div>
        ))}
        {items.length === 0 && <div className={'activity-log-item'}>No activity</div>}
      </div>
    </div>
  );
}
