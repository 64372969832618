import { Grid, Stack } from '@mui/material';
import React from 'react';
import ByScoreChart from './Blocks/ByScoreChart';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import GlobalResultByVendor from './Blocks/GlobalResultByVendor';
import ByScoresGlobalResult from './Blocks/ByScoresGlobalResult';

const CommonChartPanel = ({ data, stage, showDownload = true }) => {
  const byScores = data.byScores ?? [];

  let stacked_bar_chart = data.stacked_bar_chart ?? { labels: [], data: [] };

  const dataFirstChart = {
    labels: stacked_bar_chart.labels,
    datasets: stacked_bar_chart.data.map((item) => {
      return { ...item };
    })
  };

  const valorationByStage = data.valorationByStage ?? {
    labels: [],
    data: []
  };

  return (
    <BidderPanel title={`Scores Analytics`}>
      <Swiper
        pagination={{
          clickable: true
        }}
        modules={[Pagination]}>
        <SwiperSlide>
          <Stack spacing={1}>
            {byScores.map((score) => (
              <ByScoreChart
                score={score}
                stage={stage}
                key={score.id}
                showDownload={showDownload}
              />
            ))}
          </Stack>
        </SwiperSlide>
        <SwiperSlide>
          <Stack spacing={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <GlobalResultByVendor
                  data={valorationByStage}
                  stage={stage}
                  showDownload={showDownload}
                />
              </Grid>
              <Grid item xs={12}>
                <ByScoresGlobalResult
                  data={dataFirstChart}
                  stage={stage}
                  showDownload={showDownload}
                />
              </Grid>
            </Grid>
          </Stack>
        </SwiperSlide>
      </Swiper>
    </BidderPanel>
  );
};

export default CommonChartPanel;
