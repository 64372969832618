import * as React from 'react';
import { Stack, Typography } from '@mui/material';
import PublicLayout from '../../../bidder/layouts/PublicLayout';

const AboutUs = () => {
  return (
    <>
      <PublicLayout title="About Us">
        <Stack spacing={2}>
          <Typography variant={'body1'}>Sobre Bidder®</Typography>
          <Typography variant={'body1'}>
            Bienvenidos a Bidder®, el portal de licitaciones desarrollado por FMHOUSE que va a
            revolucionar como clientes y proveedores interactúan en la contratación de servicios de
            Facility Management. Bidder® es un sistema online basado en Blockchain, lo que aporta
            seguridad, transparencia y trazabilidad total sobre las actividades e intercambio de
            información. Es la primera aplicación de este tipo que permite certificar todo el
            proceso de la licitación. La plataforma digital permite conocer la situación en tiempo
            real y se ha diseñado para facilitar la interacción entre los diferentes stakeholders
            mediante una relación ágil y sencilla entre las partes.
          </Typography>
          <Typography variant={'body1'}>Sobre FMHOUSE</Typography>
          <Typography variant={'body1'}>
            FMHOUSE es una empresa independiente, especializada en consultoría estratégica,
            formación e investigación en Facility Management, con prestigio y reconocimiento
            nacional e internacional. FMHOUSE apuesta e invierte para hacer que esta disciplina y
            las personas que en ella trabajan, ocupen el lugar que se merecen en el mercado y en las
            organizaciones. Cuando usted contrata un proyecto a FMHOUSE, tiene de su lado un socio
            con experiencia y amplio conocimiento del sector. Un equipo propio bien formado y unas
            formas de trabajar procedimentadas, le aseguran unos resultados de la más alta calidad.
            Todo esto garantiza que las aportaciones y soluciones propuestas son de la máxima
            validez y actualidad.
          </Typography>
        </Stack>
      </PublicLayout>
    </>
  );
};
export default AboutUs;
