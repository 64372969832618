import { Grid, Stack, TextField, Typography } from '@mui/material';
import React from 'react';

const VendorForm = ({ vendor }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div style={{ justifyContent: 'center', display: 'flex', height: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '15px',
                alignSelf: 'center',
                alignContent: 'center'
              }}>
              {vendor.company.logo_url && (
                <img
                  style={{
                    maxWidth: '186px',
                    maxHeight: '186px',
                    borderRadius: '9px',
                    border: '1px solid 0, 0, 0, 0.20'
                  }}
                  src={vendor.company.logo_url}
                  alt={'logo'}
                />
              )}
              {!vendor.company.logo_url && (
                <img
                  style={{
                    maxWidth: '186px',
                    maxHeight: '186px',
                    borderRadius: '9px',
                    border: '1px solid 0, 0, 0, 0.20'
                  }}
                  src={`https://placehold.co/600x400/green/white?text=${vendor.company.name}`}
                  alt={'logo'}
                />
              )}
              <Typography variant={'body1'} sx={{ color: '#848484', fontSize: '16px' }}>
                Company Logo
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={9}>
          <Stack spacing={2}>
            <TextField
              fullWidth
              disabled
              value={vendor.name}
              variant="outlined"
              label="Name"
              size={'small'}
            />
            <TextField
              fullWidth
              disabled
              value={vendor.surname}
              variant="outlined"
              label="Surname"
              size={'small'}
            />
            <TextField
              fullWidth
              disabled
              value={vendor.phone}
              variant="outlined"
              label="Phone"
              size={'small'}
            />
            <TextField
              fullWidth
              disabled
              value={vendor.email}
              variant="outlined"
              label="Email"
              size={'small'}
            />
            <TextField
              fullWidth
              disabled
              value={vendor.company.name}
              variant="outlined"
              label="Company"
              size={'small'}
            />
            <TextField
              fullWidth
              disabled
              value={vendor.job_position}
              variant="outlined"
              label="Job position"
              size={'small'}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
export default VendorForm;
