import AuthLayout from '../../../bidder/layouts/AuthLayout';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import { Stack } from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import {
  deleteCompany,
  getCompany,
  updateCompany
} from '../../../bidder/services/dashboard/companies';
import { useParams } from 'react-router-dom';
import CompanyForm from '../../../bidder/components/Dashboard/Company/CompanyForm';
import { notify } from '../../../bidder/helpers/notify';
import { useDispatch } from 'react-redux';
import BiddingHistoryTable from '../../../bidder/components/Dashboard/Company/BiddingHistoryTable';
import CompanyUserTable from '../../../bidder/components/Dashboard/Company/CompanyUserTable';
import { Loading } from '../../../components/Loading/Loading';

export default function Show() {
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [company, setCompany] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getCompany(id).then((response) => {
      setCompany({ ...response.data.data, ...{ logo: undefined } });
    });
  }, [id]);

  const handleSubmitForm = (event) => {
    event.preventDefault();

    const payload = new FormData();
    if (company.logo !== undefined) {
      payload.append('logo', company.logo);
    }
    payload.append('name', company.name);
    payload.append('color', company.color);
    payload.append('nif', company.nif);
    payload.append('country', company.country);
    payload.append('product_category', company.product_category);
    payload.append('role', company.role);
    payload.append('status', company.status);

    updateCompany(id, payload)
      .then((response) => {
        setCompany({ ...response.data.data, ...{ logo: undefined } });
        setErrors({});
        notify(dispatch, 'Company updated successfully', 'success');
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
          notify(dispatch, response.message, 'warning');
        }
      });
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this company?')) {
      deleteCompany(id)
        .then(() => {
          notify(dispatch, 'Company deleted successfully', 'success');
          history.push('/dashboard/companies');
        })
        .catch((error) => {
          notify(dispatch, error.response.data.message, 'warning');
        });
    }
  };

  const handleChange = (e) => {
    const { name } = e.target;
    const value = name === 'logo' ? e.target.files[0] : e.target.value;

    setCompany((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <>
      <AuthLayout title={'Company profile'}>
        <Stack spacing={2}>
          {company === null ? (
            <Loading />
          ) : (
            <>
              <BidderPanel title={'Company Info'}>
                <CompanyForm
                  model={company}
                  errors={errors}
                  onChangeHandler={handleChange}
                  key={company.updated_at}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '5px 0',
                    gap: '15px'
                  }}>
                  <Button
                    variant={'fm-light'}
                    size={'large'}
                    color={'success'}
                    type="submit"
                    onClick={handleDelete}>
                    Delete
                  </Button>
                  <Button
                    variant={'fm-light'}
                    size={'large'}
                    color={'success'}
                    type="submit"
                    onClick={handleSubmitForm}>
                    Update
                  </Button>
                </div>
              </BidderPanel>
              <BidderPanel title={'Company Users'}>
                <CompanyUserTable company={company} />
              </BidderPanel>
              <BidderPanel title={'Bidding History'}>
                <BiddingHistoryTable companyId={id} />
              </BidderPanel>
            </>
          )}
        </Stack>
      </AuthLayout>
    </>
  );
}
