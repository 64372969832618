import Header from '../components/Main/Header';
import Content from '../components/Main/Content';

import '../assets/styles/bidder.scss';

export default function PublicLayout({ children, title }) {
  return (
    <>
      <div className={'bidder-wrapper'}>
        <Header title={title} hasUser={false} />
        <Content>{children}</Content>
      </div>
    </>
  );
}
