import * as React from 'react';
import {
  Box,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  DialogContent,
  DialogActions,
  Dialog,
  Paper,
  DialogTitle,
  Stack
} from '@mui/material';
import { ReactComponent as Add } from '../../../../assets/images/circled-plus.svg';
import { Edit as EditMui } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { invitationsService } from '../../../../services/admin';
import { Loading } from '../../../../components/Loading/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import DeleteIcon from '@mui/icons-material/Delete';
import Wrapper from '../../../../components/Wrapper/Wrapper';
import AssignVendorModal from '../../../../components/Dashboard/Biddings/Setup/VendorInvitations/AssignVendorModal';
import EditVendorModal from '../../../../components/Dashboard/Biddings/Setup/VendorInvitations/EditVendorModal';
import { useDispatch } from 'react-redux';
import OutlinedButtonsWrapper from '../../../../components/FMHouse/OutlinedButtonsWrapper';
import AuthLayout from '../../../../bidder/layouts/AuthLayout';

const VendorInvitations = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [activeId, setActiveId] = React.useState();
  const [showAssignVendorModal, setShowAssignVendorModal] = useState(false);
  const [vendorForEditAssignModal, setVendorForEditAssignModal] = useState(null);
  const [disableSendInvitationsButton, setDisableSendInvitationsButton] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [infoModalText, setInfoModalText] = useState('Invitations have been sent');
  const dispatch = useDispatch();

  const getData = () => {
    invitationsService(id)
      .index()
      .then((res) => {
        setData(res.data);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDeleteModal = (id) => {
    setActiveId(id);
    setOpenDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setActiveId('');
    setOpenDeleteModal(false);
  };

  const onDeleteHandle = () => {
    invitationsService(id)
      .removedVendorSubBiddings(activeId)
      .then(() => {
        getData();
        showSuccessDeleteInvitationToast();
      });
    setActiveId('');
    setOpenDeleteModal(false);
  };

  const showSuccessDeleteInvitationToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'invitation has been deleted',
      toast_type: 'success'
    });
  };

  const sendInvitationsHandle = (event) => {
    event.preventDefault();
    setDisableSendInvitationsButton(true);
    invitationsService(id)
      .sendInvitations()
      .then(() => {
        setOpenInfoModal(true);
        setDisableSendInvitationsButton(false);
      })
      .catch((err) => {
        setDisableSendInvitationsButton(false);
        setOpenInfoModal(true);
        setInfoModalText('Something went wrong. Try again later');
        console.log('error', err);
      });
  };

  useEffect(() => {
    if (data && data.vendors.length === 0) {
      setDisableSendInvitationsButton(true);
    }
  }, [data]);

  if (!data) {
    return <Loading />;
  }

  return (
    <>
      <AuthLayout title="Setting Invitations">
        <Container>
          <Wrapper maxWidth="1360px">
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <Typography variant="h2" align="center">
                {data.data.name}
              </Typography>
            </Box>
            <Stack spacing={1}>
              <Typography variant="h3" fontWeight={'bold'}>
                {data.data.company}
              </Typography>
              <Typography variant="body2">{data.data.file_code}</Typography>
              <Typography variant="body2">
                Start Date: {data.data.start_date} End Date: {data.data.end_date}
              </Typography>
            </Stack>
          </Wrapper>
        </Container>
        <Container sx={{ marginTop: '20px' }}>
          <Wrapper>
            <Paper variant={'elevation'} elevation={3} sx={{ padding: '30px 20px 30px' }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width={'200px'}>Company & Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell align={'center'}>Edit</TableCell>
                      <TableCell align={'center'}>Delete</TableCell>
                      <TableCell width={'150px'} align={'center'}>
                        Biddings
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.vendors.map((vendor) => {
                      return (
                        <TableRow hover tabIndex={-1} key={vendor.name}>
                          <TableCell align="left">
                            <Typography variant="body1" weight={'bold'}>
                              {vendor.company}
                            </Typography>
                            <Typography variant="body2">
                              {vendor.name + ' ' + vendor.surname}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="DarkGreyText14">{vendor.email}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              aria-label="edit"
                              onClick={() => {
                                setVendorForEditAssignModal(vendor.id);
                              }}>
                              <EditMui />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleDeleteModal(vendor.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            {vendor.sub_biddings.map((title) => (
                              <Typography variant={'body2'} key={title}>
                                {title}
                              </Typography>
                            ))}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableBody>
                    <TableRow key="new">
                      <TableCell colSpan={3}>
                        <Typography variant="NewBiddingText16">New Invitation</Typography>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => setShowAssignVendorModal(true)}>
                          <Add />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <OutlinedButtonsWrapper>
                <Button
                  disabled={disableSendInvitationsButton}
                  onClick={sendInvitationsHandle}
                  variant="fm-outlined"
                  size={'wide'}>
                  Send
                </Button>
              </OutlinedButtonsWrapper>
            </Paper>
          </Wrapper>
          <OutlinedButtonsWrapper>
            <Button variant="fm-outlined" onClick={() => navigate(-1)} size={'wide'}>
              Previous
            </Button>
            <Button
              disabled={disableSendInvitationsButton || data.status !== 'design'}
              onClick={() =>
                invitationsService(id)
                  .finish()
                  .then(() => navigate(`/dashboard/biddings/${id}`))
              }
              variant="fm-outlined"
              size={'wide'}>
              Finish
            </Button>
          </OutlinedButtonsWrapper>
        </Container>
        <DeleteModal
          closeDeleteModal={closeDeleteModal}
          onDeleteHandle={onDeleteHandle}
          open={openDeleteModal}
          text="Are you sure you want to delete the bidding?"
        />
        <AssignVendorModal
          open={showAssignVendorModal}
          bidding={data.data}
          excludedIds={data.vendors.map((vendor) => vendor.id)}
          onSuccess={() => {
            getData();
            setShowAssignVendorModal(false);
            setDisableSendInvitationsButton(false);
          }}
          onClose={() => setShowAssignVendorModal(false)}
        />
        <EditVendorModal
          open={vendorForEditAssignModal !== null}
          bidding={data.data}
          vendorId={vendorForEditAssignModal}
          onSuccess={() => {
            getData();
            setVendorForEditAssignModal(null);
          }}
          onClose={() => setVendorForEditAssignModal(null)}
        />
        <Dialog
          open={openInfoModal}
          keepMounted
          onClose={() => setOpenInfoModal(false)}
          aria-describedby="alert-dialog-slide-description">
          <Box component="form">
            <DialogTitle>Invitation</DialogTitle>
            <DialogContent>
              <Typography variant={'body1'}>{infoModalText}</Typography>
            </DialogContent>
            <DialogActions>
              <Button variant={'fm-light'} onClick={() => setOpenInfoModal(false)}>
                Close
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </AuthLayout>
    </>
  );
};
export default VendorInvitations;
