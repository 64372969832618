import userColors from '../../../../theme/colors/special/users';
import { Avatar } from '@mui/material';
import { getInitial } from '../../../../services/string';
import * as React from 'react';

export default function UserAvatar({ user }) {
  let color = userColors.inactive;
  if (user.status !== 'inactive') {
    switch (user.role) {
      case 'admin':
        color = userColors.admin;
        break;
      case 'vendor':
        color = userColors.vendor;
        break;
      case 'client':
        color = userColors.client;
        break;
    }
  }

  return (
    <Avatar sx={{ bgcolor: color, width: 34, height: 34, fontSize: '17px' }}>
      {getInitial(user.role)}
    </Avatar>
  );
}
