import { Button } from '@mui/material';
import { capitalizeFirstLetter } from '../../../../services/string';
import * as React from 'react';

export default function StageButton({ bidding }) {
  let title = bidding.status === 'active' ? bidding.stage : bidding.status;
  if (bidding.parent_id === null) {
    title = bidding.status;
  } else {
    if (!bidding.has_active_stage && bidding.status !== 'design') {
      title = 'active';
    }
  }
  if (bidding.status === 'ready') {
    title = 'ready';
  }

  return <Button variant={`bidding-${title}`}>{capitalizeFirstLetter(title)}</Button>;
}
