import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { subBiddingService } from '../../../../../services/admin';
import SubBiddingForm from './SubBiddingForm';
import dayjs from 'dayjs';

const EditSubBiddingModal = ({ bidding = null, onSuccess, onClose }) => {
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    name: '',
    file_code: '',
    start_date: '',
    end_date: ''
  });
  useEffect(() => {
    if (bidding !== null) {
      setValues(bidding);
    }
  }, [bidding]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeDate = (prop) => (date) => {
    setValues({ ...values, [prop]: dayjs(date).format('YYYY-MM-DD') });
  };
  const acceptHandle = (event) => {
    event.preventDefault();
    subBiddingService(bidding.parent_id)
      .update(bidding.id, {
        end_date: values.end_date
      })
      .then(() => {
        onSuccess();
        onClose();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  const handleOnClose = () => {
    setErrors({});
    onClose();
  };

  if (bidding === null) {
    return '';
  }

  return (
    <Dialog
      open={true}
      keepMounted
      onClose={handleOnClose}
      aria-describedby="alert-dialog-slide-description">
      <Box component="form" onSubmit={acceptHandle} sx={{ width: '535px' }}>
        <DialogTitle>Edit Sub Bidding</DialogTitle>
        <DialogContent>
          <SubBiddingForm
            errors={errors}
            disabledInputs={{ name: true, file_code: true, start_date: true }}
            values={values}
            onChangeName={handleChange('name')}
            onChangeFileCode={handleChange('file_code')}
            onChangeStartDate={handleChangeDate('start_date')}
            onChangeEndDate={handleChangeDate('end_date')}
          />
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} type="submit">
            Edit
          </Button>
          <Button variant={'fm-light'} onClick={handleOnClose}>
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditSubBiddingModal;
