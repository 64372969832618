import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';

export const Toast = () => {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const dispatch = useDispatch();
  const getShowSnack = useSelector((state) => state.toast);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({ type: 'HIDE' });
  };

  return (
    <Snackbar onClose={handleClose} open={getShowSnack.show} autoHideDuration={6000}>
      <Alert severity={getShowSnack.toast_type} sx={{ width: '100%' }}>
        {getShowSnack.text}
      </Alert>
    </Snackbar>
  );
};
