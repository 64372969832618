import React, { useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { requirements } from '../../../../services/dashboard/biddingService';
import { Loading } from '../../../../components/Loading/Loading';

import {
  StageTab,
  StageTabPanel,
  StageTabs,
  StageTabsList
} from '../../../../components/FMHouse/StageTabs';
import OutlinedButtonsWrapper from '../../../../components/FMHouse/OutlinedButtonsWrapper';
import PeriodSelectorBlock from '../../../../components/Dashboard/Biddings/Setup/RequirementSelection/PeriodSelectorBlock';
import DocumentsArea from '../../../../components/Dashboard/Biddings/Setup/RequirementSelection/DocumentsArea';
import ScoringStructureTable from '../../../../components/Dashboard/Biddings/Setup/RequirementSelection/ScoringStructureTable';
import { requirementSelectionService } from '../../../../services/admin';
import { useDispatch } from 'react-redux';
import FinishRequirementsModal from '../../../../components/Dashboard/Biddings/Setup/FinishRequirementsModal';
import AuthLayout from '../../../../bidder/layouts/AuthLayout';
import BidderPanel from '../../../../bidder/components/Panels/BidderPanel';

export default function Requirements() {
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [bidding, setBidding] = useState(null);
  const [stage, setStage] = useState(null);
  const [stageData, setStageData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [QAStartDate, setQAStartDate] = useState(null);
  const [QAEndDate, setQAEndDate] = useState(null);
  const [showFinishStageModal, setShowFinishStageModal] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (stageData !== null) {
      setStartDate(stageData.deadline.start_date);
      setEndDate(stageData.deadline.end_date);
      setQAStartDate(stageData.qa.start_date);
      setQAEndDate(stageData.qa.end_date);
    }
  }, [stageData]);

  const navigate = useNavigate();
  const fetchBidding = () => {
    requirements
      .show(id)
      .then((result) => {
        setBidding(result.data.data);
      })
      .catch((error) => {
        navigate('/error', {
          state: { status: error.status, message: error.message },
          replace: true
        });
      });
  };

  useEffect(() => {
    setBidding(null);
    setStage(null);
    setStageData(null);
    fetchBidding();
  }, [id]);

  useEffect(() => {
    if (bidding !== null) {
      setStage(bidding.stage);
      setReadOnly(false);
    }
  }, [bidding]);

  useEffect(() => {
    if (stage !== null) {
      fetchStageData();
    }
  }, [stage]);

  let apiService = requirementSelectionService(id).stage(stage);

  const fetchStageData = () => {
    apiService.show().then((result) => setStageData(result.data.data));
  };

  const dispatch = useDispatch();

  const renderNextButton = () => {
    let listOfStages = bidding.stages.map((s) => s.type);
    let indexOfCurrentStage = listOfStages.findIndex((type) => type === stage);
    console.log(bidding.stages);
    console.log(indexOfCurrentStage);
    console.log(bidding.stages[indexOfCurrentStage + 1]);
    console.log(bidding.stages[indexOfCurrentStage + 2]);

    let hasNextStage = listOfStages[indexOfCurrentStage + 1] !== undefined;

    if (hasNextStage) {
      return (
        <Button
          variant={'fm-outlined'}
          size={'wide'}
          disabled={nextStageDisabled()}
          onClick={() => setStage(listOfStages[indexOfCurrentStage + 1])}>
          Next
        </Button>
      );
    }

    if (bidding.status !== 'design') {
      return (
        <Button
          component={Link}
          variant={'fm-outlined'}
          size={'wide'}
          to={
            bidding.parent_id
              ? `/dashboard/biddings/${bidding.parent_id}/setup/bidding-structure`
              : '/dashboard/biddings'
          }>
          Close
        </Button>
      );
    }

    return (
      <Button
        variant={'fm-outlined'}
        size={'wide'}
        disabled={nextStageDisabled()}
        onClick={() => setShowFinishStageModal(true)}>
        Finish
      </Button>
    );
  };

  const tabIsDisabled = (stageType) => {
    if (bidding.status !== 'design') {
      return false;
    }

    let listOfStages = bidding.stages.map((s) => s.type);
    let indexOfCurrentStage = listOfStages.findIndex((type) => type === stage);
    let indexOfStage = listOfStages.findIndex((type) => type === stageType);

    if (indexOfCurrentStage < indexOfStage && nextStageDisabled()) {
      return true;
    }

    return false;
  };
  useEffect(() => {
    if (stageData !== null) {
      setStartDate(stageData.deadline.start_date);
      setEndDate(stageData.deadline.end_date);
      setQAStartDate(stageData.qa.start_date);
      setQAEndDate(stageData.qa.end_date);
    }
  }, [stageData]);

  const nextStageDisabled = () => {
    if (stageData === null) {
      return true;
    }

    if (stageData.documents.length === 0 || stageData.scores.length === 0) {
      return true;
    }

    return (
      stageData.deadline.start_date === null ||
      stageData.deadline.end_date === null ||
      stageData.qa.start_date === null ||
      stageData.qa.end_date === null
    );
  };

  const onChildUpdateEvent = () => {
    fetchStageData();
  };

  const updateStage = () => {
    apiService
      .update({
        start_date: startDate,
        end_date: endDate,
        qa_start_date: QAStartDate,
        qa_end_date: QAEndDate
      })
      .then((response) => {
        setErrors([]);
        setStageData(response.data.data);
        showSuccessRequirementSelectionToast();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  const showSuccessRequirementSelectionToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'Data was saved successfully',
      toast_type: 'success'
    });
  };

  return (
    <>
      <AuthLayout title={bidding?.name}>
        {bidding === null ? (
          <Loading />
        ) : (
          <>
            <StageTabs
              value={stage}
              defaultValue={bidding.stage}
              onChange={(event, value) => setStage(value)}>
              <StageTabsList>
                {bidding.stages.map((stage) => (
                  <StageTab
                    stage={stage.type}
                    key={stage.type}
                    disabled={tabIsDisabled(stage.type)}
                  />
                ))}
              </StageTabsList>
              {bidding.stages.map((item) => (
                <StageTabPanel value={item.type} key={item.type}>
                  {stageData !== null && (
                    <>
                      <Stack spacing={4} key={stage}>
                        <DocumentsArea
                          readOnly={readOnly}
                          subBidding={bidding}
                          stage={stage}
                          documents={stageData.documents}
                          onUpdateEvent={onChildUpdateEvent}
                        />

                        {stageData.documents.length > 0 && (
                          <BidderPanel title={'Scoring Structure'}>
                            <ScoringStructureTable
                              readOnly={false && (readOnly || bidding.status !== 'design')}
                              subBidding={bidding}
                              stage={stage}
                              scores={stageData.scores}
                              onUpdateEvent={onChildUpdateEvent}
                            />
                          </BidderPanel>
                        )}
                        {stageData.scores.length > 0 && (
                          <BidderPanel title={'Stage Deadlines'}>
                            <PeriodSelectorBlock
                              readOnly={readOnly}
                              startDate={startDate}
                              endDate={endDate}
                              errors={{
                                start_date: errors.start_date,
                                end_date: errors.end_date
                              }}
                              onChangeStartDate={(value) => setStartDate(value)}
                              onChangeEndDate={(value) => setEndDate(value)}
                            />
                          </BidderPanel>
                        )}
                        {startDate !== null && endDate !== null && (
                          <BidderPanel title={'Q&A Deadlines'}>
                            <PeriodSelectorBlock
                              readOnly={readOnly}
                              startDate={QAStartDate}
                              endDate={QAEndDate}
                              errors={{
                                start_date: errors.qa_start_date,
                                end_date: errors.qa_end_date
                              }}
                              onChangeStartDate={(value) => setQAStartDate(value)}
                              onChangeEndDate={(value) => setQAEndDate(value)}
                            />
                          </BidderPanel>
                        )}
                      </Stack>
                      <OutlinedButtonsWrapper>
                        <Button
                          variant={'fm-outlined'}
                          size={'wide'}
                          onClick={() => updateStage()}
                          disabled={readOnly || !startDate || !endDate}>
                          Save
                        </Button>
                        {renderNextButton()}
                      </OutlinedButtonsWrapper>
                    </>
                  )}
                </StageTabPanel>
              ))}
            </StageTabs>
          </>
        )}
        <FinishRequirementsModal
          biddingId={id}
          show={showFinishStageModal}
          stageData={stageData}
          onClose={() => setShowFinishStageModal(false)}
          onSuccess={() => {
            navigate(
              bidding.parent_id
                ? `/dashboard/biddings/${bidding.parent_id}/setup/bidding-structure`
                : '/dashboard/biddings'
            );
          }}></FinishRequirementsModal>
      </AuthLayout>
    </>
  );
}
