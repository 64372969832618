import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

const MenuLink = ({ label, href, onClick }) => {
  return (
    <Link
      onClick={onClick}
      component={RouterLink}
      to={href}
      variant="body2"
      mb={4.1}
      sx={{ fontWeight: '600', fontSize: '16px', textDecoration: 'none', cursor: 'pointer' }}>
      {label}
    </Link>
  );
};
export default MenuLink;
