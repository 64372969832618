export default [
  {
    props: { variant: 'vendor-download' },
    style: {
      padding: '8px 12px',
      wordBreak: 'normal',
      height: '28px',
      border: '3px solid #87DBDD',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#87DBDD',
      '&:disabled': {
        border: '2px solid rgba(0, 0, 0, 0.26) !important',
        color: 'rgba(0, 0, 0, 0.26)'
      }
    }
  },
  {
    props: { variant: 'vendor-sign' },
    style: {
      padding: '8px 12px',
      wordBreak: 'normal',
      height: '28px',
      border: '3px solid #B0A6E5',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#B0A6E5',
      '&:disabled': {
        border: '2px solid rgba(0, 0, 0, 0.26) !important',
        color: 'rgba(0, 0, 0, 0.26)'
      }
    }
  },
  {
    props: { variant: 'vendor-upload' },
    style: {
      padding: '8px 12px',
      wordBreak: 'normal',
      height: '28px',
      border: '3px solid #F4AFCA',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#F4AFCA',
      '&:disabled': {
        border: '2px solid rgba(0, 0, 0, 0.26) !important',
        color: 'rgba(0, 0, 0, 0.26)'
      }
    }
  }
];
