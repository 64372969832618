import axios from './../axios';

const prefix = '/api/dashboard';

export const notificationService = {
  updateNotification: (notificationId, data) => {
    return axios.post(`${prefix}/notifications/${notificationId}`, data);
  },
  destroy: (notificationId) => {
    return axios.delete(`${prefix}/notifications/${notificationId}`);
  }
};

export const dashboardService = {
  show: (id) => {
    return axios.get(`${prefix}/biddings/${id}/dashboard/`);
  },
  showStage: (id, bidding, stage) => {
    return axios.get(`${prefix}/biddings/${id}/dashboard/${bidding}/stage/${stage}`);
  },
  storeNotification: (id, bidding, stage, data) => {
    return axios.post(
      `${prefix}/biddings/${id}/dashboard/${bidding}/stage/${stage}/send-notification`,
      data
    );
  },
  answerQuestion: (id, bidding, stage, question, data) => {
    return axios.post(
      `${prefix}/biddings/${id}/dashboard/${bidding}/stage/${stage}/question/${question}`,
      data
    );
  },
  getWidgetData: (id, bidding, stage) => {
    return axios.get(`${prefix}/biddings/${id}/dashboard/${bidding}/stage/${stage}/widget`);
  }
};
