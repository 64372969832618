import React, { useState, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import Button from '@mui/material/Button';
import OutlinedButtonsWrapper from './OutlinedButtonsWrapper';

const PDFViewer = ({ src_view, src_download }) => {
  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);

  const { pdfDocument } = usePdf({
    file: src_view,
    page,
    canvasRef,
    withCredentials: true
  });

  return (
    <div>
      {!pdfDocument && <span>Loading...</span>}
      <canvas style={{ width: '800px', margin: 'auto', display: 'block' }} ref={canvasRef} />
      {Boolean(pdfDocument && pdfDocument.numPages) && (
        <OutlinedButtonsWrapper>
          <Button variant={'fm-mini'} disabled={page === 1} onClick={() => setPage(page - 1)}>
            Previous
          </Button>
          <Button
            variant={'fm-mini'}
            disabled={page === pdfDocument.numPages}
            onClick={() => setPage(page + 1)}>
            Next
          </Button>
          <Button target={'_blank'} href={src_download} variant={'fm-mini'}>
            Download
          </Button>
        </OutlinedButtonsWrapper>
      )}
    </div>
  );
};

export default PDFViewer;
