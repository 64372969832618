export const BIDING_STAGE_TYPE = [
  {
    type: 'rfi',
    color: '#BBE0D1'
  },
  {
    type: 'rfp',
    color: '#AFCED4'
  },
  {
    type: 'rfq',
    color: '#67B1C5'
  },
  {
    type: 'rfpq',
    color: '#67B1C5'
  }
];

export const findBidingStageColor = (type) => {
  return BIDING_STAGE_TYPE.find((el) => el.type === type)?.color ?? 'red';
};
