import moment from 'moment-timezone';

export const formatDateTimeWithTimezone = (date, timezone, format = 'DD/MM/Y HH:mm z') => {
  return moment(date).tz(timezone).format(format);
};

export const formatDateTime = (date, format = 'DD/MM/Y HH:mm') => {
  return moment(date).format(format);
};

export const formatDateWithTimezone = (date, timezone, format = 'DD/MM/Y z') => {
  return moment(date).tz(timezone).format(format);
};

export const formatDate = (date, format = 'DD/MM/Y') => {
  return moment(date).tz('UTC').format(format);
};

export const formatUTCDateToBrowser = (date, format = 'DD/MM/Y') => {
  return moment(date).format(format);
};

export const dateIsDue = (date) => {
  return moment(date).isBefore(moment());
};
