import biddingStages from './buttons/biddingStages';
import biddingStatuses from './buttons/biddingStatuses';
import outlined from './buttons/outlined';
import vendorActions from './buttons/vendorActions';
import requirement from './buttons/requirement';

export default {
  MuiDateRangePickerViewDesktop: {
    styleOverrides: {
      root: {
        margin: '15px',
        fontColor: '#fff !important',
        '.MuiDayPicker-header': {
          justifyContent: 'left'
        },
        '.MuiDayPicker-weekDayLabel': {
          color: '#5F5F5F !important',
          width: '20px !important',
          height: '20px !important',
          fontWeight: 'normal',
          fontSize: '10px',
          lineHeight: '15px',
          margin: '4px'
        },
        '.MuiPickersArrowSwitcher-root': {
          padding: '5px 0px'
        },
        '.PrivatePickersSlideTransition-root': {
          minWidth: 'auto !important',
          minHeight: '195px'
        },
        '.MuiPickersDay-root': {
          width: '20px',
          height: '20px',
          fontWeight: 'normal',
          fontSize: '10px',
          lineHeight: '15px'
        },
        '.MuiTypography-subtitle1': {
          color: '#464646',
          fontWeight: 'bold',
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontSize: '16px',
          lineHeight: '24px'
        },
        '.MuiDateRangePickerViewDesktop-container': {
          padding: '16px 16px 10px 16px',
          backgroundColor: '#F3F9F8 !important',
          border: 0
        },
        '.MuiDateRangePickerViewDesktop-container:nth-of-type(1)': {
          borderRadius: '32px 0 0 32px !important',
          borderRight: 0
        },
        '.MuiDateRangePickerViewDesktop-container:nth-of-type(2)': {
          borderLeft: '2px solid #fff',
          borderRight: '2px solid #fff'
        },
        '.MuiDateRangePickerViewDesktop-container:nth-of-type(3)': {
          borderRadius: '0 32px 32px 0 !important'
        },
        '.MuiPickersArrowSwitcher-button': {
          color: '#9A9A9A'
        },
        '.MuiDateRangePickerDay-root': {
          backgroundColor: 'transparent  !important',
          border: '0 !important',
          padding: '4px !important'
        },
        '.MuiDateRangePickerDay-rangeIntervalPreview': {
          border: 'none !important',
          background: 'none !important'
        },
        '.MuiPickersDay-hiddenDaySpacingFiller': {
          backgroundColor: 'none !important',
          border: 'none'
        }
      }
    }
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontFamily: 'Poppins',
        color: '#5F5F5F',
        fontSize: '18px',
        lineHeight: '27px',
        fontWeight: 600,
        padding: '24px 24px 16px '
      }
    }
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '6px 24px 10px !important',
        fontFamily: 'Poppins',
        color: '#848484',
        fontSize: '16px',
        lineHeight: '150%'
      }
    }
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        paddingLeft: '16px',
        paddingRight: '16px'
      }
    }
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '10px 24px 24px !important'
      }
    }
  },
  MuiContainer: {
    variants: [
      {
        props: { variant: 'fm-left-spacing' },
        style: {
          paddingTop: '15px !important',
          paddingBottom: '200px !important'
        }
      }
    ]
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '30px',
        borderColor: '#9A9A9A',
        textarea: {
          borderRadius: '5px !important'
        }
      }
    },
    variants: [
      {
        props: { type: 'textarea' },
        style: {
          borderRadius: '5px !important'
        }
      }
    ]
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: '1rem'
      }
    },
    variants: [
      {
        props: { variant: 'fm-light' },
        style: {
          color: 'rgba(112, 193, 179, 1)',
          borderBottom: '3px solid rgba(154, 194, 201, 1) !important',
          borderRadius: '10px !important',
          padding: '14px 16px !important',
          textTransform: 'none',
          lineHeight: '100%',
          fontWeight: 600,
          '&:disabled': {
            borderBottom: '3px solid rgba(0, 0, 0, 0.26) !important'
          }
        }
      },
      {
        props: { variant: 'fm-light-outlined' },
        style: {
          color: 'rgba(112, 193, 179, 1)',
          border: '3px solid rgba(154, 194, 201, 1) !important',
          borderRadius: '10px !important',
          padding: '14px 16px !important',
          lineHeight: '100%',
          fontWeight: 600,
          textTransform: 'none',
          '&[aria-disabled=true]': {
            border: '3px solid rgba(0, 0, 0, 0.26) !important'
          },
          '&:disabled': {
            border: '3px solid rgba(0, 0, 0, 0.26) !important'
          }
        }
      },
      {
        props: { variant: 'fm-action' },
        style: {
          color: '#345E64',
          border: '1px solid #345E64',
          borderRadius: '10px !important',
          padding: '8px 16px !important',
          lineHeight: '100%',
          fontSize: '12px',
          textTransform: 'none',
          fontWeight: 400,
          '&:disabled': {
            border: '1px solid rgba(0, 0, 0, 0.26) !important'
          }
        }
      },
      ...biddingStages,
      ...biddingStatuses,
      {
        props: { variant: 'fm-constrained' },
        style: {
          backgroundColor: '#6F94AB !important',
          borderRadius: '32px !important',
          padding: '3px 50px 3px !important',
          color: '#fff',
          textTransform: 'none',
          fontWeight: 600,
          '&:disabled': {
            backgroundColor: '#ccc !important'
          },
          '&[aria-disabled=true]': {
            backgroundColor: '#ccc !important'
          }
        }
      },
      {
        props: { variant: 'fm-mini' },
        style: {
          border: '1px solid #539994 !important',
          backgroundColor: '#539994 !important',
          borderRadius: '20px !important',
          padding: '3px 25px 3px !important',
          color: '#fff',
          height: '26px',
          fontSize: '12px',
          textTransform: 'none',
          '&:disabled': {
            color: '#6C6C6C',
            backgroundColor: '#E3E3E3 !important',
            border: '1px solid #E3E3E3 !important'
          },
          '&[aria-disabled=true]': {
            border: '1px solid #E3E3E3 !important'
          }
        }
      },
      {
        props: { variant: 'fm-constrained', size: 'wide' },
        style: {
          minWidth: '300px'
        }
      },
      ...outlined,
      ...vendorActions,
      ...requirement
    ]
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '10px'
      }
    },
    variants: [
      {
        props: { variant: 'fm-paper' },
        style: {
          borderWidth: '0px 0px 3px 3px',
          borderStyle: 'solid',
          borderColor: '#C5E8DC',
          borderRadius: '15px',
          padding: '10px 27px 15px'
        }
      }
    ]
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '12px 17px'
      }
    },
    variants: [
      {
        props: { variant: 'head' },
        style: {
          backgroundColor: '#F8F9FD',
          color: '#2E5855',
          fontSize: '17px',
          fontWeight: 600,
          padding: '12px 17px',
          lineHeight: '24px',
          borderBottom: '1px solid #2E5855'
        }
      },
      {
        props: { variant: 'head-light' },
        style: {
          color: '#2E5855',
          fontSize: '17px',
          fontWeight: 600,
          padding: '10px 20px',
          lineHeight: '24px',
          borderBottom: '1px solid rgba(83, 153, 148, 0.4)'
        }
      },
      {
        props: { variant: 'body' },
        style: {
          backgroundColor: '#FBFBFB',
          borderBottom: '1px solid #2E5855'
        }
      },
      {
        props: { variant: 'body-light' },
        style: {
          backgroundColor: '#FBFBFB',
          padding: '10px 5px',
          borderBottom: '1px solid rgba(83, 153, 148, 0.4)'
        }
      },
      {
        props: { variant: 'footer' },
        style: {
          backgroundColor: '#FBFBFB'
        }
      }
    ]
  }
};
