import axios from '../axios';

export const scopeAndDescription = {
  show: (bidding) => {
    return axios.get(`/api/dashboard/biddings/${bidding}/setup/scope-and-description`);
  },
  update: (bidding, payload) => {
    return axios.post(`/api/dashboard/biddings/${bidding}/setup/scope-and-description`, payload);
  }
};

export const requirements = {
  show: (bidding) => {
    return axios.get(`/api/dashboard/biddings/${bidding}/setup/requirements`);
  },
  showStage: (bidding, stage, payload) => {
    return axios.get(`/api/dashboard/biddings/${bidding}/setup/requirements/${stage}`, payload);
  },
  updateStage: (bidding, stage, payload) => {
    return axios.post(`/api/dashboard/biddings/${bidding}/setup/requirements/${stage}`, payload);
  },
  finish: (bidding) => {
    return axios.post(`/api/dashboard/biddings/${bidding}/setup/requirements/finish`);
  }
};
