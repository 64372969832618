import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import { biddingService } from '../../../services/client';
import Header from '../../../bidder/components/Main/Header';
import Content from '../../../bidder/components/Main/Content';
import PageWrapper from '../../../bidder/components/Main/PageWrapper';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import ClientBiddingSelector from '../../../bidder/components/Selectors/BiddingSelector';
import {
  StageTabs,
  StageTabsList,
  StageTabPanel,
  StageTab
} from '../../../components/FMHouse/StageTabs';
import VendorValorationTable from '../../../components/Client/VendorValorationTable';
import RFIChartPanel from '../../../components/Client/Charts/RFIChartPanel';
import CommonChartPanel from '../../../components/Client/Charts/CommonChartPanel';
import VendorManagementTable from '../../../components/Client/VendorManagementTable';
import NotificationsTable from '../../../bidder/components/Client/NotificationsTable';
import ClientQATable from '../../../bidder/components/Client/ClientQATable';
import ClientUpcomingDeadlines from '../../../bidder/components/Client/ClientUpcomingDeadlines';
import { CircleRequirementsProgress } from '../../../components/FMHouse/ProgressBars/CircleRequirementsProgress';
import StageAuthorizationModal from '../../../bidder/components/Client/Authorizations/StageAuthorizationModal';
import RotateAuthorizationModal from '../../../bidder/components/Client/Authorizations/RotateAuthorizationModal';

const BiddingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bidding, setBidding] = useState(null);
  const [stage, setStage] = useState(null);
  const [stageData, setStageData] = useState(null);
  const [showStageAuthorizationModal, setShowStageAuthorizationModal] = useState(false);
  const [showRequirementAuthorizationModal, setShowRequirementAuthorizationModal] = useState(false);

  const handleSwitchSubBidding = (item) => {
    navigate(`/client/biddings/${item.id}`);
  };

  const fetchBidding = () => {
    biddingService
      .getBidding(id)
      .then((result) => {
        setBidding(result.data.data);
      })
      .catch((error) => {
        navigate('/error', {
          state: { status: error.status, message: error.message },
          replace: true
        });
      });
  };

  const fetchStageData = () => {
    biddingService
      .getStage(id, stage)
      .then((result) => {
        setStageData(result.data);
        setShowStageAuthorizationModal(result.data.authorization_stage.need_authorization);
        setShowRequirementAuthorizationModal(result.data.authorization_requirements.length > 0);
      })
      .catch((error) => {
        navigate('/error', {
          state: { status: error.status, message: error.message },
          replace: true
        });
      });
  };

  useEffect(() => {
    setBidding(null);
    setStage(null);
    setStageData(null);
    fetchBidding();
  }, [id]);

  useEffect(() => {
    if (bidding !== null) {
      setStage(bidding.stage);
    }
  }, [bidding]);

  useEffect(() => {
    if (stage !== null) {
      fetchStageData();
    }
  }, [stage]);

  const renderChartBlock = (type) => {
    switch (type) {
      case 'rfi':
        return <RFIChartPanel data={stageData.charts ?? {}} />;
      case 'rfp':
      case 'rfq':
      case 'rfpq':
        return <CommonChartPanel data={stageData.charts ?? {}} stage={type} />;
    }

    return '';
  };

  return (
    <>
      <PageWrapper>
        <Header title={bidding?.name} />
        <Content>
          {bidding === null ? (
            <Loading />
          ) : (
            <>
              <StageTabs defaultValue={bidding.stage} onChange={(event, value) => setStage(value)}>
                <Stack
                  direction={'row'}
                  spacing={2}
                  sx={{ marginBottom: '20px', justifyContent: 'space-around' }}>
                  <ClientBiddingSelector
                    biddings={bidding.biddings}
                    onClick={handleSwitchSubBidding}
                    currentId={id}
                  />
                  <StageTabsList>
                    {bidding.stages.map((stage) => (
                      <StageTab stage={stage.type} key={stage.type} />
                    ))}
                  </StageTabsList>
                </Stack>
                {bidding.stages.map((item) => (
                  <StageTabPanel value={item.type} key={item.type}>
                    {stageData !== null && stage === item.type && (
                      <Stack spacing={2}>
                        <BidderPanel title={'Upcoming Deadlines:'}>
                          <ClientUpcomingDeadlines events={stageData.events} />
                        </BidderPanel>
                        <div className={'deadline-grid-container'}>
                          <BidderPanel title={'Notifications:'}>
                            <NotificationsTable notifications={stageData.notifications} />
                          </BidderPanel>
                          <BidderPanel title={'Progress:'}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '300px'
                              }}>
                              <div
                                style={{
                                  width: '100%',
                                  textAlign: 'center'
                                }}>
                                <CircleRequirementsProgress
                                  value={stageData.requirements_progress}
                                />
                              </div>
                            </div>
                          </BidderPanel>
                        </div>

                        <BidderPanel title={'Vendor Valoration'}>
                          <VendorValorationTable values={stageData.valoration} />
                        </BidderPanel>

                        <Box sx={{ marginBottom: '30px !important' }}>
                          {renderChartBlock(stage)}
                        </Box>

                        <BidderPanel title={'Requirements'}>
                          <VendorManagementTable documents={stageData.documents} />
                        </BidderPanel>

                        <BidderPanel title={'Q&A'}>
                          <ClientQATable questions={stageData.questions} />
                        </BidderPanel>
                      </Stack>
                    )}
                  </StageTabPanel>
                ))}
              </StageTabs>
            </>
          )}

          {showStageAuthorizationModal &&
            stageData !== null &&
            stageData.authorization_stage.need_authorization && (
              <StageAuthorizationModal
                stage={stageData}
                onApprove={() => window.location.reload()}
                onClose={() => setShowStageAuthorizationModal(false)}
              />
            )}
          {!showStageAuthorizationModal &&
            showRequirementAuthorizationModal &&
            stageData !== null &&
            stageData.authorization_requirements.length > 0 && (
              <RotateAuthorizationModal
                requirements={stageData.authorization_requirements}
                onApprove={() => window.location.reload()}
                onClose={() => setShowRequirementAuthorizationModal(false)}
              />
            )}
        </Content>
      </PageWrapper>
    </>
  );
};

export default BiddingDetails;
