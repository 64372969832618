import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { dashboardService } from '../../../services/dashboard/dashboardService';
import { Loading } from '../../../components/Loading/Loading';
import {
  StageTab,
  StageTabPanel,
  StageTabs,
  StageTabsList
} from '../../../components/FMHouse/StageTabs';
import NotificationsTable from '../../../bidder/components/Client/NotificationsTable';
import OutlinedButtonsWrapper from '../../../components/FMHouse/OutlinedButtonsWrapper';
import VendorManagementTable from '../../../components/Dashboard/Biddings/VendorManagementTable';
import StoreNotificationModal from '../../../components/Common/Modals/StoreNotificationModal';
import UpdateNotificationModal from '../../../components/Common/Modals/UpdateNotificationModal';
import AnswerQuestionModal from '../../../components/Dashboard/Vendors/Show/Modals/AnswerQuestionModal';
import DestroyNotificationModal from '../../../components/Common/Modals/DestroyNotificationModal';
import Header from '../../../bidder/components/Main/Header';
import Content from '../../../bidder/components/Main/Content';
import PageWrapper from '../../../bidder/components/Main/PageWrapper';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import ClientUpcomingDeadlines from '../../../bidder/components/Client/ClientUpcomingDeadlines';
import ClientQATable from '../../../bidder/components/Client/ClientQATable';
import ClientBiddingSelector from '../../../bidder/components/Selectors/BiddingSelector';
import DestroyQuestionModal from '../../../components/Dashboard/Vendors/Show/Modals/DestroyQuestionModal';
import { CircleRequirementsProgress } from '../../../components/FMHouse/ProgressBars/CircleRequirementsProgress';
import ActivityLog from '../../../bidder/components/Dashboard/Bidding/ActivityLog';
import NotificationWidget from '../../../bidder/components/Common/NotificationWidget';

export default function BiddingDashboard() {
  const { id, bidding = null, stage = null } = useParams();
  const navigate = useNavigate();
  const [mainBidding, setMainBidding] = useState(null);
  const [activeBidding, setActiveBidding] = useState(null);

  const [activeStage, setActiveStage] = useState(null);
  const [stageData, setStageData] = useState(null);
  const [createNotificationModalOpen, setCreateNotificationModalOpen] = useState(false);
  const [notificationToUpdate, setNotificationToUpdate] = useState(null);
  const [notificationToDestroy, setNotificationToDestroy] = useState(null);
  const [questionToAnswer, setQuestionToAnswer] = useState(null);
  const [questionToDestroy, setQuestionToDestroy] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [selectedQA, setSelectedQA] = useState(null);
  const [widgetNotificationData, setWidgetNotificationData] = useState([]);

  useEffect(() => {
    dashboardService.show(id).then((response) => setMainBidding(response.data.data));
  }, [id]);

  useEffect(() => {
    if (mainBidding !== null) {
      if (bidding === null) {
        setActiveBidding(mainBidding.biddings[0] ?? null);
      } else {
        setActiveBidding(
          mainBidding.biddings.find((item) => parseInt(item.id) === parseInt(bidding))
        );
      }
    }
  }, [mainBidding]);

  useEffect(() => {
    if (mainBidding !== null) {
      setActiveStage(null);
      setStageData(null);
      setActiveBidding(null);
      if (bidding === null) {
        setActiveBidding(mainBidding.biddings[0] ?? null);
      } else {
        setActiveBidding(
          mainBidding.biddings.find((item) => parseInt(item.id) === parseInt(bidding))
        );
      }
    }
  }, [bidding]);

  useEffect(() => {
    if (activeBidding !== null) {
      if (stage !== null) {
        setActiveStage(stage);
      } else {
        setActiveStage(activeBidding?.stage);
      }
    }
  }, [activeBidding]);

  const fetchStageData = () => {
    dashboardService
      .showStage(id, activeBidding.id, activeStage)
      .then((response) => setStageData(response.data.data));
  };

  useEffect(() => {
    if (activeBidding !== null && activeStage !== null) {
      fetchStageData();
    }
  }, [activeStage]);

  useEffect(() => {
    setWidgetNotificationData(stageData?.widgets?.notification ?? []);
  }, [stageData]);

  const onUpdateActivity = () => {
    dashboardService.getWidgetData(id, activeBidding.id, activeStage).then((response) => {
      setWidgetNotificationData(response.data.widgets.notification);
    });
  };

  return (
    <>
      <PageWrapper>
        <Header
          title={`Bidding: ${mainBidding?.name}`}
          widgets={
            <>
              <NotificationWidget notifications={widgetNotificationData} />
            </>
          }
        />
        <Content>
          {mainBidding === null ? (
            <Loading />
          ) : (
            <>
              {activeBidding !== null ? (
                <StageTabs
                  defaultValue={activeBidding.stage}
                  value={activeStage}
                  onChange={(event, value) => setActiveStage(value)}>
                  <Stack
                    direction={'row'}
                    spacing={2}
                    sx={{ marginBottom: '20px', justifyContent: 'space-around' }}>
                    <ClientBiddingSelector
                      biddings={mainBidding.biddings}
                      onClick={(item) => navigate(`/dashboard/biddings/${id}/dashboard/${item.id}`)}
                      currentId={activeBidding?.id ?? null}
                    />
                    <StageTabsList>
                      {activeBidding.stages.map((stage) => (
                        <StageTab stage={stage.type} key={stage.type} />
                      ))}
                    </StageTabsList>
                  </Stack>
                  {activeBidding.stages.map((item) => (
                    <StageTabPanel value={item.type} key={item.type}>
                      {stageData !== null && activeStage === item.type && (
                        <Stack spacing={2}>
                          <BidderPanel title={'Activity Manager'}>
                            <ActivityLog
                              items={stageData.activity_log}
                              onUpdate={onUpdateActivity}
                            />
                          </BidderPanel>
                          <BidderPanel title={'Upcoming deadlines:'}>
                            <ClientUpcomingDeadlines events={stageData.events} />
                          </BidderPanel>
                          <div className={'deadline-grid-container'}>
                            <BidderPanel title={'Notifications:'}>
                              <NotificationsTable
                                notifications={stageData.notifications}
                                onSelected={(notification) => {
                                  setSelectedNotification(notification);
                                }}
                              />
                              <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                spacing={3}>
                                <Button
                                  onClick={() => setCreateNotificationModalOpen(true)}
                                  variant={'fm-outlined'}>
                                  New Notification
                                </Button>
                                {selectedNotification !== null && (
                                  <>
                                    <Button
                                      variant={'fm-outlined'}
                                      onClick={() => setNotificationToUpdate(selectedNotification)}>
                                      Edit
                                    </Button>
                                    <Button
                                      variant={'fm-outlined'}
                                      onClick={() =>
                                        setNotificationToDestroy(selectedNotification)
                                      }>
                                      Delete
                                    </Button>
                                  </>
                                )}
                              </Stack>
                            </BidderPanel>
                            <BidderPanel title={'Progress:'}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '300px'
                                }}>
                                <div
                                  style={{
                                    width: '100%',
                                    textAlign: 'center'
                                  }}>
                                  <CircleRequirementsProgress
                                    value={stageData.requirements_progress}
                                  />
                                </div>
                              </div>
                            </BidderPanel>
                          </div>
                          <StoreNotificationModal
                            dialogOpen={createNotificationModalOpen}
                            biddingId={id}
                            subBidding={activeBidding}
                            currentStage={activeStage}
                            onSuccess={() => {
                              fetchStageData();
                              setCreateNotificationModalOpen(false);
                            }}
                            onClose={() => setCreateNotificationModalOpen(false)}
                          />
                          <UpdateNotificationModal
                            dialogOpen={notificationToUpdate !== null}
                            notification={notificationToUpdate}
                            onSuccess={() => {
                              fetchStageData();
                              setNotificationToUpdate(null);
                            }}
                            onClose={() => setNotificationToUpdate(null)}
                          />
                          <DestroyNotificationModal
                            dialogOpen={notificationToDestroy !== null}
                            notification={notificationToDestroy}
                            onSuccess={() => {
                              fetchStageData();
                              setNotificationToDestroy(null);
                            }}
                            onClose={() => setNotificationToDestroy(null)}
                          />
                          <BidderPanel title={'Requirement Status'}>
                            <VendorManagementTable
                              documents={stageData.documents}
                              biddingId={activeBidding?.id}
                              stage={item.type}
                              onUpdateEvent={() => {
                                fetchStageData();
                              }}
                            />
                            <OutlinedButtonsWrapper>
                              <Button
                                variant={'fm-outlined'}
                                size={'wide'}
                                onClick={() =>
                                  navigate(
                                    `/dashboard/biddings/${activeBidding.id}/access-section/${item.type}`
                                  )
                                }>
                                Assess Section
                              </Button>
                            </OutlinedButtonsWrapper>
                          </BidderPanel>
                          <BidderPanel title={'Q&A'}>
                            <ClientQATable
                              questions={stageData.questions}
                              onRowClick={(question) => setSelectedQA(question)}
                            />
                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              spacing={3}>
                              {selectedQA !== null && (
                                <>
                                  <Button
                                    variant={'fm-outlined'}
                                    onClick={() => setQuestionToAnswer(selectedQA)}>
                                    Answer & edit
                                  </Button>
                                  <Button
                                    variant={'fm-outlined'}
                                    onClick={() => setQuestionToDestroy(selectedQA)}>
                                    Delete
                                  </Button>
                                </>
                              )}
                              <Button
                                variant={'fm-outlined'}
                                target={'_blank'}
                                disabled={stageData.questions_download === null}
                                href={stageData.questions_download}>
                                Download
                              </Button>
                            </Stack>
                            <AnswerQuestionModal
                              question={questionToAnswer}
                              onClose={() => {
                                setQuestionToAnswer(null);
                              }}
                              onSuccess={() => {
                                fetchStageData();
                                setQuestionToAnswer(null);
                              }}
                              onError={() => {
                                console.log('error');
                              }}
                            />
                            <DestroyQuestionModal
                              question={questionToDestroy}
                              onClose={() => {
                                setQuestionToDestroy(null);
                              }}
                              onSuccess={() => {
                                fetchStageData();
                                setQuestionToDestroy(null);
                              }}
                              onError={() => {
                                console.log('error');
                              }}
                            />
                          </BidderPanel>
                        </Stack>
                      )}
                    </StageTabPanel>
                  ))}
                </StageTabs>
              ) : (
                ''
              )}
            </>
          )}
        </Content>
      </PageWrapper>
    </>
  );
}
