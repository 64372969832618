import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';

const DeleteModal = ({ open, closeDeleteModal, onDeleteHandle, title = 'Are you sure?', text }) => {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={closeDeleteModal}
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ width: '535px' }}>{text}</DialogContent>
      <DialogActions>
        <Button variant={'fm-light'} onClick={onDeleteHandle}>
          Delete
        </Button>
        <Button variant={'fm-light'} onClick={closeDeleteModal}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
