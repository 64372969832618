import Axios from 'axios';
import qs from 'qs';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL ?? 'http://localhost',
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  withCredentials: true,
  paramsSerializer: function (params) {
    return qs.stringify(params);
  }
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401 && error.response.config.url !== '/api/user') {
      window.location.href = '/login';
    } else {
      return Promise.reject(error);
    }
  }
);

export default axios;
