import { formatDateTimeWithTimezone } from '../../../../services/date';
import { Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { markAsRead, markAsUnread } from '../../../services/dashboard/activity';
import { useDispatch } from 'react-redux';

export default function ActivityLog({ items, onUpdate = null }) {
  const [activityLogItems, setActivityLogItems] = useState([]); // eslint-disable-line no-unused-vars
  const dispatch = useDispatch();

  useEffect(() => {
    setActivityLogItems(items);
  }, [items]);

  const handleReadActivity = (item) => {
    if (item.read) {
      markAsUnread(item.id).then(() => {
        setActivityLogItems((prev) => {
          const index = prev.findIndex((i) => i.id === item.id);
          const newItems = [...prev];
          newItems[index].read = false;

          return newItems;
        });
        dispatch({
          type: 'SHOW',
          text: 'Activity mark as unread',
          toast_type: 'success'
        });
        onUpdate();
      });
    } else {
      markAsRead(item.id).then(() => {
        setActivityLogItems((prev) => {
          const index = prev.findIndex((i) => i.id === item.id);
          const newItems = [...prev];
          newItems[index].read = true;

          return newItems;
        });

        dispatch({
          type: 'SHOW',
          text: 'Activity mark as read',
          toast_type: 'success'
        });
        onUpdate();
      });
    }
  };

  return (
    <div className={'activity-log'}>
      <div className={'activity-log-content'}>
        {activityLogItems.map((item) => (
          <div className={'activity-log-item'} key={item.id}>
            <Checkbox
              checked={item.read}
              color="success"
              onClick={() => handleReadActivity(item)}
            />
            <strong>[{item.vendor}]</strong>&nbsp;
            <strong>{item.label}</strong>&nbsp;
            <span>{item.description}</span>&nbsp;
            <span>{formatDateTimeWithTimezone(item.date, 'UTC')}</span>
          </div>
        ))}
        {items.length === 0 && <div className={'activity-log-item'}>No activity</div>}
      </div>
    </div>
  );
}
