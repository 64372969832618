export default [
  {
    props: { variant: 'requirement-conditioned' },
    style: {
      padding: '5px',
      wordBreak: 'normal',
      height: '40px',
      border: '2px solid #8AA1B0',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#8AA1B0',
      '.MuiButton-endIcon': {
        position: 'absolute',
        top: '-13px',
        right: '-6px'
      },
      '.MuiButton-endIcon svg path:last-child': {
        fill: '#8AA1B0'
      },
      '&:disabled, &[aria-disabled=true]': {
        border: '2px solid #C4C4C4',
        color: '#C4C4C4',
        svg: {
          fill: '#C4C4C4'
        },
        '.MuiButton-endIcon svg path:last-child': {
          fill: '#C4C4C4'
        }
      },
      svg: {
        fill: '#8AA1B0'
      }
    }
  },
  {
    props: { variant: 'requirement-accepted' },
    style: {
      padding: '5px',
      wordBreak: 'normal',
      height: '40px',
      border: '2px solid #9CE7CC',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#9CE7CC',
      '.MuiButton-endIcon': {
        position: 'absolute',
        top: '-13px',
        right: '-6px'
      },
      '.MuiButton-endIcon svg path:last-child': {
        fill: '#9CE7CC'
      },
      '&:disabled': {
        border: '2px solid #C4C4C4',
        color: '#C4C4C4',
        '& svg': {
          fill: '#8AA1B0'
        },
        '.MuiButton-endIcon svg path:last-child': {
          fill: '#C4C4C4'
        }
      },
      '& svg': {
        fill: '#9CE7CC'
      }
    }
  },
  {
    props: { variant: 'requirement-rejected' },
    style: {
      padding: '5px',
      wordBreak: 'normal',
      height: '40px',
      border: '2px solid #EB3800',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#EB3800',
      '.MuiButton-endIcon': {
        position: 'absolute',
        top: '-13px',
        right: '-6px'
      },
      '.MuiButton-endIcon svg path:last-child': {
        fill: '#EB3800'
      },
      '&:disabled': {
        border: '2px solid #C4C4C4',
        color: '#C4C4C4',
        '& svg': {
          fill: '#8AA1B0'
        },
        '.MuiButton-endIcon svg path:last-child': {
          fill: '#C4C4C4'
        }
      },
      '& svg': {
        fill: '#EB3800'
      }
    }
  }
];
