import axios from './../axios';

const prefix = '/api/dashboard';

export const vendorService = {
  index: (params = {}) => {
    return axios.get(`${prefix}/vendors`, params);
  },
  show: (id) => {
    return axios.get(`${prefix}/vendors/${id}`);
  },
  showStage: (id, bidding, stage) => {
    return axios.get(`${prefix}/vendors/${id}/bidding/${bidding}/stage/${stage}`);
  },
  changeStatusOfDocument: (id, bidding, stage, requirement, data) => {
    return axios.post(
      `${prefix}/vendors/${id}/bidding/${bidding}/stage/${stage}/requirement/${requirement}`,
      data
    );
  },
  changeStatusOfSignature: (id, bidding, signature, data) => {
    return axios.post(`${prefix}/vendors/${id}/bidding/${bidding}/signature/${signature}`, data);
  },
  answerQuestion: (id, data) => {
    return axios.post(`${prefix}/question/${id}`, data);
  },
  destroyQuestion: (id) => {
    return axios.delete(`${prefix}/question/${id}`);
  }
};
