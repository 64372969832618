import ValorationProgress from '../FMHouse/ProgressBars/ValorationProgress';
import React from 'react';

export default function VendorValorationTable({ values }) {
  return (
    <>
      <table className={'vendor-valoration-table'}>
        <thead className={'vendor-valoration-table__header'}>
          <tr>
            <th>Vendor</th>
            {values.stages.map((stage) => (
              <th key={stage.id}>{stage.label}</th>
            ))}
          </tr>
        </thead>
        <tbody className={'vendor-valoration-table__body'}>
          {values.vendors.map((vendor) => (
            <tr key={vendor.id}>
              <td>{vendor.name}</td>
              {values.stages.map((stage) => (
                <td key={stage.type}>
                  <ValorationProgress
                    color={'progress' + stage.type.toUpperCase()}
                    value={vendor.values[stage.type]}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
