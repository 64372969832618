import React, { useState } from 'react';
import { Badge, Grid, Stack, TextField } from '@mui/material';
import { CalendarPicker, DateTimePicker, PickersDay } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const PeriodSelectorBlock = ({
  readOnly = true,
  startDate = null,
  endDate = null,
  errors = [],
  onChangeStartDate,
  onChangeEndDate
}) => {
  const [date, setDate] = useState(dayjs());

  const handleSelectCalendar = (newDate) => {
    setDate(newDate);
    let formattedData = dayjs(newDate).format('YYYY-MM-DD');
    if (startDate === null) {
      onChangeStartDate(dayjs(newDate).format('YYYY-MM-DD HH:mm'));
    } else {
      if (endDate === null) {
        onChangeEndDate(dayjs(newDate).format('YYYY-MM-DD HH:mm'));
      } else {
        if (formattedData === dayjs(endDate).format('YYYY-MM-DD')) {
          onChangeEndDate(null);
        }
        if (formattedData === dayjs(startDate).format('YYYY-MM-DD')) {
          onChangeStartDate(null);
        }
      }
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Stack spacing={2} justifyContent={'center'} height={'100%'}>
          <DateTimePicker
            label="Start date"
            inputFormat="DD/MM/YYYY HH:mm"
            value={startDate}
            readOnly={readOnly}
            onChange={(value) => onChangeStartDate(dayjs(value).format('YYYY-MM-DD HH:mm'))}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={!!errors.start_date}
                helperText={errors.start_date}
                name="deadline"
                variant="outlined"
                size="small"
              />
            )}
          />
          <DateTimePicker
            label="End date"
            inputFormat="DD/MM/YYYY HH:mm"
            value={endDate}
            readOnly={readOnly}
            onChange={(value) => onChangeEndDate(dayjs(value).format('YYYY-MM-DD HH:mm'))}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={!!errors.end_date}
                helperText={errors.end_date}
                name="deadline"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <CalendarPicker
          disabled={readOnly}
          date={date}
          onChange={handleSelectCalendar}
          renderDay={(day, _value, DayComponentProps) => {
            let badgeContent = undefined;
            switch (DayComponentProps.day.format('YYYY-MM-DD')) {
              case dayjs(startDate).format('YYYY-MM-DD'):
                badgeContent = '🟢';
                break;
              case dayjs(endDate).format('YYYY-MM-DD'):
                badgeContent = '🔴';
                break;
            }

            return (
              <Badge key={day.toString()} overlap="circular" badgeContent={badgeContent}>
                <PickersDay {...DayComponentProps} />
              </Badge>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PeriodSelectorBlock;
