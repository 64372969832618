import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { vendorService } from '../../../../../services/dashboard/vendorsService';
import { useDispatch } from 'react-redux';
import BidderLogo from '../../../../../bidder/assets/images/bidder.svg';

const RequirementsReviewModal = ({ requirement, status, onSuccess, onClose }) => {
  if (requirement === null || status === null) {
    return '';
  }

  const [content, setContent] = useState('');
  const [subject, setSubject] = useState('');
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    switch (status) {
      case 'rejected':
        setSubject('Requirement rejected');
        break;
      case 'accepted':
        setSubject('Requirement accepted');
        break;
    }
  }, [status]);

  const handleChangeStatus = (status) => {
    vendorService
      .changeStatusOfDocument(
        requirement.vendor_id,
        requirement.bidding_id,
        requirement.stage,
        requirement.id,
        {
          status,
          content,
          subject
        }
      )
      .then(() => {
        onSuccess();
        onClose();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
          dispatch({
            type: 'SHOW',
            text: error.response.data.message,
            toast_type: 'error'
          });
        }
      });
  };

  return (
    <div className={'authorization-overlay'}>
      <div className={'authorization-modal authorization-modal-wider'}>
        <div className={'authorization-modal-content'}>
          <div className={'authorization-modal-logo'}>
            <img src={BidderLogo} alt={'Bidder Logo'} />
          </div>
          <div className={'authorization-modal-header'}>
            {status === 'accepted'
              ? '¿Do you accept the following requirement?'
              : '¿Do you reject the following requirement?'}
          </div>
          <div className={'authorization-modal-body'}>
            {status === 'rejected' ? (
              <>
                <div className={'stack'}>
                  <TextField
                    variant="outlined"
                    size={'small'}
                    label={'Subject'}
                    value={subject}
                    onChange={(el) => setSubject(el.target.value)}
                    error={!!errors.subject}
                    helperText={errors.subject}
                  />
                  <TextField
                    id="outlined-multiline-static"
                    size={'small'}
                    label={'Reason'}
                    multiline
                    rows={4}
                    variant="outlined"
                    type={'textarea'}
                    value={content}
                    onChange={(el) => setContent(el.target.value)}
                    error={!!errors.content}
                    helperText={errors.content}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={'stack stack-centered'}>
                  <div>{requirement.name}</div>
                  <div>{requirement.file_name}</div>
                </div>
              </>
            )}
          </div>
          <div className={'authorization-modal-footer'}>
            {status === 'conditioned' && (
              <Button variant="fm-outlined" onClick={() => handleChangeStatus('conditioned')}>
                Conditioned
              </Button>
            )}
            {status === 'rejected' && (
              <Button variant="fm-outlined" onClick={() => handleChangeStatus('rejected')}>
                Reject
              </Button>
            )}
            {status === 'accepted' && (
              <Button variant="fm-outlined" onClick={() => handleChangeStatus('accepted')}>
                Accept
              </Button>
            )}
            <Button variant={'fm-outlined'} onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequirementsReviewModal;
