export default [
  {
    props: { variant: 'bidding-rfi' },
    style: {
      padding: '8px 12px',
      wordBreak: 'normal',
      width: '65px',
      height: '28px',
      border: '3px solid #C4E8DB',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#38755F'
    }
  },
  {
    props: { variant: 'bidding-rfp' },
    style: {
      padding: '8px 12px',
      wordBreak: 'normal',
      width: '65px',
      height: '28px',
      border: '3px solid #A7CFD5',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#345E64'
    }
  },
  {
    props: { variant: 'bidding-rfq' },
    style: {
      padding: '8px 12px',
      wordBreak: 'normal',
      width: '65px',
      height: '28px',
      border: '3px solid #49B3C8',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#1A4952'
    }
  },
  {
    props: { variant: 'bidding-rfpq' },
    style: {
      padding: '8px 12px',
      wordBreak: 'normal',
      width: '65px',
      height: '28px',
      border: '3px solid #627BA2',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#1A4952'
    }
  },
  {
    props: { variant: 'bidding-pending' },
    style: {
      padding: '8px 12px',
      wordBreak: 'normal',
      width: '65px',
      height: '28px',
      border: '3px solid #B07D1D',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#B07D1D'
    }
  }
];
