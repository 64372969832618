import { Box, Typography } from '@mui/material';
import React from 'react';
import { formatDateTimeWithTimezone } from '../../services/date';
import Table from '../../bidder/components/Table/Table';
import TableHeader from '../../bidder/components/Table/TableHeader';
import TableBody from '../../bidder/components/Table/TableBody';
import { getColorForVendorModerationStatus } from '../../bidder/helpers/colors-helper';
import RequirementButton from '../../bidder/components/Buttons/RequirementButton';
import TableSubTitle from '../../bidder/components/Common/TableSubTitle';

const VendorManagementTable = ({ documents = [] }) => {
  const renderTitle = (title) => {
    return (
      <p
        style={{
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 600,
          color: '#464646',
          margin: 0
        }}>
        {title}
      </p>
    );
  };

  return (
    <>
      <div style={{ width: '100%', overflow: 'auto', margin: '16px auto' }}>
        <Table layout={'fixed'}>
          <TableHeader>
            <tr>
              <th
                style={{
                  position: 'sticky',
                  left: '0px',
                  zIndex: 3,
                  width: '200px',
                  backgroundColor: '#fff',
                  padding: '0 16px 0'
                }}>
                {renderTitle('Vendor')}
              </th>
              {documents.requirements.map((requirement, index) => {
                return (
                  <th
                    key={index}
                    style={{ width: '250px', textAlign: 'center', padding: '0 16px 0' }}>
                    {renderTitle(requirement.name)}
                  </th>
                );
              })}
            </tr>
            <tr>
              <th
                style={{
                  position: 'sticky',
                  left: '0px',
                  zIndex: 3,
                  width: '200px',
                  backgroundColor: '#fff',
                  padding: '0 16px 16px'
                }}>
                <TableSubTitle subTitle={'Deadline'} />
              </th>
              {documents.requirements.map((requirement, index) => {
                return (
                  <th
                    key={index}
                    style={{ width: '250px', textAlign: 'center', padding: '0 16px 16px' }}>
                    <TableSubTitle
                      subTitle={formatDateTimeWithTimezone(
                        requirement.deadline,
                        documents.common.timezone
                      )}
                    />
                  </th>
                );
              })}
            </tr>
          </TableHeader>
          <TableBody>
            {documents.by_vendors.map((row) => {
              return (
                <tr key={row.vendor.id} style={{ position: 'relative' }}>
                  <td
                    style={{
                      position: 'sticky',
                      left: '0px',
                      zIndex: 3,
                      width: '200px',
                      backgroundColor: '#fff'
                    }}>
                    <Typography
                      variant={'body1'}
                      fontWeight={'bold'}
                      sx={{ textDecoration: 'none' }}
                      color={getColorForVendorModerationStatus(row.vendor.moderation_status)}>
                      {row.vendor.name}
                    </Typography>
                  </td>
                  {row.documents.map((item) => {
                    return (
                      <td key={item.id} style={{ width: '250px', textAlign: 'center' }}>
                        <Box>
                          <RequirementButton item={item} />
                        </Box>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default VendorManagementTable;
