import * as React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button
} from '@mui/material';
import { ReactComponent as Add } from '../../../../assets/images/circled-plus.svg';
import { useEffect, useState } from 'react';
import { Loading } from '../../../../components/Loading/Loading';
import { Delete as DeleteMui, Edit as EditMui } from '@mui/icons-material';
import { useParams, Link } from 'react-router-dom';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { biddingService, subBiddingService } from '../../../../services/admin';
import EditSubBiddingModal from '../../../../components/Dashboard/Biddings/Setup/BiddingStructure/EditBiddingModal';
import CreateSubBiddingModal from '../../../../components/Dashboard/Biddings/Setup/BiddingStructure/CreateBiddingModal';
import OutlinedButtonsWrapper from '../../../../components/FMHouse/OutlinedButtonsWrapper';
import { useDispatch } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../services/string';
import { formatDateWithTimezone } from '../../../../services/date';
import AuthLayout from '../../../../bidder/layouts/AuthLayout';
import BidderPanel from '../../../../bidder/components/Panels/BidderPanel';

const BiddingStructure = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [activeId, setActiveId] = useState();
  const [subBiddingToEdit, setSubBiddingToEdit] = useState(null);
  const [biddingIdToCreate, setBiddingIdToCreate] = useState(null);
  const dispatch = useDispatch();
  const [bidding, setBidding] = useState(null);

  useEffect(() => {
    biddingService.show(id).then((result) => setBidding(result.data.data));
  }, [id]);

  const fetchSubBiddings = () => {
    subBiddingService(id)
      .index()
      .then((res) => {
        setData(res.data.data);
      });
  };

  useEffect(() => {
    fetchSubBiddings();
  }, []);

  const handleDeleteModal = (id) => {
    setActiveId(id);
    setOpenDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setActiveId('');
    fetchSubBiddings();
    setOpenDeleteModal(false);
  };
  const onDeleteHandle = () => {
    subBiddingService(id)
      .destroy(activeId)
      .then(() => {
        setActiveId('');
        setOpenDeleteModal(false);
        fetchSubBiddings();
        showSuccessfullyDeleteToast();
      });
  };

  const renderStatus = (bidding) => {
    let title = bidding.status === 'active' ? bidding.stage : bidding.status;

    return <Button variant={`bidding-${title}`}>{capitalizeFirstLetter(title)}</Button>;
  };

  if (!data || bidding === null) {
    return <Loading />;
  }

  const showSuccessfullyCreationToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'Subbidding was successfully created',
      toast_type: 'success'
    });
  };

  const showSuccessfullyUpdatedToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'Subbidding has been successfully edited',
      toast_type: 'success'
    });
  };

  const showSuccessfullyDeleteToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'Subbidding has been successfully removed',
      toast_type: 'success'
    });
  };

  return (
    <>
      <AuthLayout title="Bidding Structure">
        <BidderPanel>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Bidding name</TableCell>
                  <TableCell>File code</TableCell>
                  <TableCell align={'center'}>Status</TableCell>
                  <TableCell align={'center'}>Start date</TableCell>
                  <TableCell align={'center'}>End date</TableCell>
                  <TableCell align={'center'}>Edit</TableCell>
                  <TableCell align={'center'}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell align="left">
                        <Typography
                          component={Link}
                          sx={{ textDecoration: 'none' }}
                          to={`/dashboard/biddings/${row.id}/setup/scope-and-description`}
                          variant="body1"
                          fontWeight={'bold'}>
                          {row.name}
                        </Typography>
                        <Typography variant="body2">{row.company}</Typography>
                        <Typography variant="body2">
                          {row.stages
                            .map((name) => (name === 'rfpq' ? 'rfp/q' : name))
                            .map((name) => name.toUpperCase())
                            .join(', ')}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body1">{row.file_code}</Typography>
                      </TableCell>

                      <TableCell align="center">{renderStatus(row)}</TableCell>
                      <TableCell align="center">
                        {formatDateWithTimezone(row.start_date, row.timezone)}
                      </TableCell>
                      <TableCell align="center">
                        {formatDateWithTimezone(row.end_date, row.timezone)}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => setSubBiddingToEdit(row)}>
                          <EditMui />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton aria-label="delete" onClick={() => handleDeleteModal(row.id)}>
                          <DeleteMui />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {bidding.status === 'design' ? (
                <TableBody>
                  <TableRow key="new">
                    <TableCell colSpan={6}>
                      <Typography variant="NewBiddingText16">New Bidding</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => setBiddingIdToCreate(id)}>
                        <Add />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                ''
              )}
            </Table>
          </TableContainer>
          <OutlinedButtonsWrapper>
            <Button variant="fm-outlined" size={'wide'} component={Link} to={'/dashboard/biddings'}>
              List of biddings
            </Button>
            <Button
              variant="fm-outlined"
              size={'wide'}
              href={`/dashboard/biddings/${id}/setup/vendor-invitations`}
              disabled={data.length === 0 || data.some((bidding) => bidding.status === 'design')}>
              Vendor invitations
            </Button>
          </OutlinedButtonsWrapper>
        </BidderPanel>
      </AuthLayout>
      <DeleteModal
        closeDeleteModal={closeDeleteModal}
        onDeleteHandle={onDeleteHandle}
        open={openDeleteModal}
        text="Are you sure you want to delete the bidding?"
      />
      <EditSubBiddingModal
        bidding={subBiddingToEdit}
        onSuccess={() => {
          fetchSubBiddings();
          showSuccessfullyUpdatedToast();
        }}
        onClose={() => setSubBiddingToEdit(null)}
      />
      <CreateSubBiddingModal
        biddingId={biddingIdToCreate}
        onSuccess={() => {
          fetchSubBiddings();
          showSuccessfullyCreationToast();
        }}
        onClose={() => setBiddingIdToCreate(null)}
      />
    </>
  );
};
export default BiddingStructure;
