import { Button, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../Loading/Loading';
import { capitalizeFirstLetter } from '../../services/string';
import { formatDate, formatDateTimeWithTimezone } from '../../services/date';
import { useDispatch } from 'react-redux';
import TableBody from '../../bidder/components/Table/TableBody';
import TableHeader from '../../bidder/components/Table/TableHeader';
import Table from '../../bidder/components/Table/Table';
import { getStageLabel } from '../../bidder/helpers/stage-helper';
import TablePagination from '@mui/material/TablePagination';

const BiddingsTable = ({ biddings = null, handleChangePage, handleChangeRowsPerPage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rowHandler = (row) => {
    if (row.status === 'finished') {
      return null;
    }

    if (row.status === 'accept' || row.acceptance_check) {
      if (row.acceptance_check) {
        dispatch({
          type: 'SHOW',
          text: 'Please wait for the acceptance check',
          toast_type: 'success'
        });
      }
      navigate(`/vendor/biddings/${row.id}/accept/nda-signing`);
    } else {
      if (!row.has_active_stage) {
        dispatch({
          type: 'SHOW',
          text: 'Please wait start of stage',
          toast_type: 'warning'
        });
      } else {
        navigate(`/vendor/biddings/${row.id}`);
      }
    }

    return null;
  };

  const renderStageButton = (row) => {
    let stage = row.stage;

    if (!row.has_active_stage) {
      stage = 'pending';
    }

    return <Button variant={`bidding-${stage}`}>{getStageLabel(stage)}</Button>;
  };

  if (biddings === null) {
    return <Loading />;
  }

  return (
    <>
      <Table>
        <TableHeader>
          <tr>
            <th className={'align-left-column'}>Bidding name</th>
            <th>Company Client</th>
            <th>Status</th>
            <th>Current stage</th>
            <th>Next deadline</th>
            <th>Start date</th>
            <th>Finish date</th>
          </tr>
        </TableHeader>
        <TableBody>
          {biddings.data.map((row) => {
            return (
              <tr key={row.id} onClick={() => rowHandler(row)} className={'cursor-pointer'}>
                <td className={'align-left-column'}>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {row.name}
                  </Typography>
                  <Typography variant="body2">{row.file_code}</Typography>
                </td>
                <td>
                  <Typography variant="body2">{row.company}</Typography>
                </td>
                <td>
                  <Button variant={`bidding-${row.status}`}>
                    {row.acceptance_check && row.status !== 'finished'
                      ? 'Acceptance check'
                      : capitalizeFirstLetter(row.status)}
                  </Button>
                </td>
                <td>{renderStageButton(row)}</td>
                <td>
                  {row.next_deadline
                    ? formatDateTimeWithTimezone(row.next_deadline, row.timezone)
                    : ''}
                </td>
                <td>{formatDate(row.start_date)}</td>
                <td>{formatDate(row.end_date)}</td>
              </tr>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[1, 10, 25, 100]}
        component="div"
        count={biddings.meta.total}
        rowsPerPage={biddings.meta.per_page}
        page={biddings.meta.current_page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default BiddingsTable;
