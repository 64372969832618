import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';

export const StageTabs = styled(TabsUnstyled)``;
export const BaseStageTab = styled(TabUnstyled)`
  font-family: 'Poppins';
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;

  width: 130px;
  height: 50px;

  border-radius: 32px;
  border: none;
  margin: auto 15px;
  /* White */

  background: #ffffff;
  /* Main Shadow */

  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  &:focus {
    color: #fff;
  }
  &[aria-selected='true'] {
    background: #f3f9f8;
    /* Inner Shadow */

    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.08);
  }
  &.${buttonUnstyledClasses.disabled} {
    /* White */
    pointer-events: none;
    background: #ccc;
    color: #fff;
    /* Main Shadow */

    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
  }
`;

export const StageTabPanel = styled(TabPanelUnstyled)(() => ``);

export const StageTabsList = styled(TabsListUnstyled)(
  () => `
  min-width: 400px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: left;
  align-content: space-between;
  `
);

const sxByType = (type) => {
  if (type === 'rfpq') {
    return {
      backgroundColor: '#000'
    };
  }

  return {};
};

export const StageTab = ({ stage, disabled = false }) => {
  let styles = sxByType(stage);
  let label = stage;
  switch (stage) {
    case 'rfi':
      label = 'RFI';
      styles = {
        color: '#B1B8B9',
        '&:hover': {
          color: '#3E7A65'
        },
        '&[aria-selected=true]': {
          color: '#3E7A65'
        }
      };
      break;
    case 'rfp':
      label = 'RFP';
      styles = {
        color: '#6F94AB',
        '&:hover': {
          color: '#6F94AB'
        },
        '&[aria-selected=true]': {
          color: '#6F94AB'
        }
      };
      break;
    case 'rfpq':
      label = 'RFP/Q';
      styles = {
        color: '#B1B8B9',
        '&:hover': {
          color: '#2E5855'
        },
        '&[aria-selected=true]': {
          color: '#2E5855'
        }
      };
      break;
    case 'rfq':
      label = 'RFQ';
      styles = {
        color: '#67B1C5',
        '&:hover': {
          color: '#67B1C5'
        },
        '&[aria-selected=true]': {
          color: '#67B1C5'
        }
      };
      break;
  }

  return (
    <BaseStageTab value={stage} sx={styles} disabled={disabled}>
      {label}
    </BaseStageTab>
  );
};
