import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Table from '../../../bidder/components/Table/Table';
import TableHeader from '../../../bidder/components/Table/TableHeader';
import TableBody from '../../../bidder/components/Table/TableBody';
import { formatDateTimeWithTimezone } from '../../../services/date';

export default function ClientQATable({ questions, onRowClick = null }) {
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const onRowClickCallback = (question) => {
    setSelected(question);

    if (onRowClick) {
      onRowClick(question);
    }
  };

  useEffect(() => {
    setItems(questions);
    if (selected !== null) {
      setSelected(questions.find((question) => question.id === selected.id));
    }
  }, [questions]);

  useEffect(() => {
    console.log('clicked on question');
  }, [selected]);

  return (
    <>
      <div className={'questions-table-wrapper'}>
        <Table className={'questions-table'}>
          <TableHeader>
            <tr>
              <th></th>
              <th></th>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </TableHeader>
          <TableBody>
            {items.map((question) => (
              <tr
                key={question.id}
                className={
                  (onRowClick === null ? '' : 'cursor-pointer') +
                  (selected?.id === question.id ? ' selected' : '')
                }
                onClick={() => onRowClickCallback(question)}>
                <td className={'question-index'}>{question.number}</td>
                <td className={'question-vendor-name'}>{question.asked?.company}</td>
                <td className={'question-content'}>
                  <div className={'question-text-block question'}>
                    <div className={'question-text'}>{question.question}</div>
                    <div className={'question-date'}>
                      {formatDateTimeWithTimezone(question.created_at, question.timezone)}
                    </div>
                  </div>
                </td>
                <td className={'question-content'}>
                  {question.answer && (
                    <div className={'question-text-block answer'}>
                      <div className={'question-text'}>{question.answer}</div>
                      <div className={'question-date'}>
                        {formatDateTimeWithTimezone(question.updated_at, question.timezone)}
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
            {items.length === 0 && (
              <tr>
                <td colSpan={4} style={{ textAlign: 'center' }}>
                  <Typography variant="body1" fontWeight={'bold'}>
                    Q&A not found
                  </Typography>
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
}
