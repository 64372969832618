import { requirementSelectionService } from '../../../../../../services/admin';
import DeleteModal from '../../../../../DeleteModal/DeleteModal';
import { useDispatch } from 'react-redux';

const UpdateScoreStructureModal = ({
  bidding = null,
  subBidding = null,
  stage = null,
  score = null,
  onSuccess,
  onClose
}) => {
  const dispatch = useDispatch();
  const handleSubmit = () => {
    requirementSelectionService(subBidding.id)
      .stage(stage)
      .scoreStructure.destroy(score.id)
      .then((result) => {
        onSuccess(result);
        showSuccessScoreDeleteToast();
      });
  };

  const showSuccessScoreDeleteToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'Scoring structure has been deleted',
      toast_type: 'success'
    });
  };

  const handleClose = () => {
    onClose();
  };

  if (score === null) {
    return '';
  }

  return (
    <DeleteModal
      closeDeleteModal={handleClose}
      onDeleteHandle={() => handleSubmit(bidding)}
      open={true}
      text={`Are you sure you want to delete the [${score.id}]${score.section}?`}
    />
  );
};

export default UpdateScoreStructureModal;
