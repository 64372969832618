import Table from '../../Table/Table';
import TableHeader from '../../Table/TableHeader';
import TableHeaderCell from '../../Table/TableHeaderCell';
import TableBody from '../../Table/TableBody';
import TableRow from '../../Table/TableRow';
import { useEffect, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import * as React from 'react';
import { getCompanyBiddings } from '../../../services/dashboard/companies';
import { getSortParamFromFilter } from '../../../services/table';
import { formatDateTimeWithTimezone } from '../../../../services/date';
import StageButton from '../../Buttons/Bidding/StageButton';
import { Link, Typography } from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';
import { linkOfBidding } from '../../../services/links';

export default function BiddingHistoryTable({ companyId }) {
  const [filter, setFilter] = useState({
    search: '',
    columns: [
      {
        key: 'id',
        name: '#',
        sortable: true,
        filterable: false,
        sort: 'desc',
        filter: null
      },
      {
        key: 'name',
        name: 'Bidding Name',
        class: 'align-left-column',
        sortable: true,
        filterable: false,
        sort: null,
        filter: null
      },
      {
        key: 'start_date',
        name: 'Date Started',
        sortable: true,
        filterable: false,
        sort: null
      },
      {
        key: 'end_date',
        name: 'Date Finish',
        sortable: true,
        filterable: false,
        sort: null
      },
      {
        key: 'bidding_status',
        name: 'Status',
        sortable: false,
        filterable: false,
        sort: null
      },
      {
        key: 'completion',
        name: 'Completion',
        sortable: false,
        filterable: false,
        sort: null
      }
    ]
  });
  const [data, setData] = useState({
    data: [],
    meta: {
      total: 0,
      per_page: 5,
      current_page: 1
    }
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const onSort = (column, value) => {
    let newFilter = filter;
    newFilter['columns'].map((item) => {
      item.sort = null;

      return item;
    });
    let index = newFilter['columns'].findIndex((item) => item.key === column);
    newFilter['columns'][index].sort = value;
    setFilter({ ...newFilter });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  useEffect(() => {
    getCompanyBiddings(companyId, {
      params: {
        filter: {},
        page: page + 1,
        per_page: rowsPerPage,
        sort: getSortParamFromFilter(filter)
      }
    }).then((result) => {
      setData(result.data);
    });
  }, [page, rowsPerPage, filter]);

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            {filter.columns.map((column, index) => (
              <TableHeaderCell key={index} filter={filter} column={column} onSort={onSort} />
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.data.map((row) => (
            <TableRow key={row.id}>
              <td>{row.id}</td>
              <td className={'align-left-column'}>
                <Link to={linkOfBidding(row)} component={ReactLink}>
                  {row.name}
                </Link>
              </td>
              <td>{formatDateTimeWithTimezone(row.start_date, row.timezone)}</td>
              <td>{formatDateTimeWithTimezone(row.end_date, row.timezone)}</td>
              <td>
                <StageButton bidding={row} />
              </td>
              <td>
                <Typography variant={'body1'}>{row.completion}%</Typography>
              </td>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[1, 5, 25, 100]}
        component="div"
        count={data.meta.total}
        rowsPerPage={data.meta.per_page}
        page={data.meta.current_page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
