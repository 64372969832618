const defaultState = {
  user: {}
};
const CurrentUser = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.current_user };
    default:
      return state;
  }
};
export default CurrentUser;
