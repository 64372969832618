import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import ByScores from './Blocks/ByScores';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import TotalByVendor from './Blocks/TotalByVendor';

const RFIChartPanel = ({ data, showDownload = true }) => {
  let stacked_bar_chart = data.stacked_bar_chart ?? { labels: [], data: [] };
  let bar_chart = data.bar_chart ?? { labels: [], data: [] };

  const dataLastChart = {
    labels: bar_chart.labels,
    datasets: bar_chart.data
  };

  const dataFirstChart = {
    labels: stacked_bar_chart.labels,
    datasets: stacked_bar_chart.data.map((item) => {
      return { ...item };
    })
  };

  return (
    <BidderPanel title={'Scores Analytics'}>
      <Swiper
        pagination={{
          clickable: true
        }}
        autoHeight={true}
        modules={[Pagination]}>
        <SwiperSlide>
          <ByScores data={dataFirstChart} stage={'rfi'} showDownload={showDownload} />
        </SwiperSlide>
        <SwiperSlide>
          <TotalByVendor data={dataLastChart} stage={'rfi'} showDownload={showDownload} />
        </SwiperSlide>
      </Swiper>
    </BidderPanel>
  );
};

export default RFIChartPanel;
