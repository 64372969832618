import AuthLayout from '../../../bidder/layouts/AuthLayout';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import { Stack } from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { storeCompany } from '../../../bidder/services/dashboard/companies';
import { notify } from '../../../bidder/helpers/notify';
import CompanyForm from '../../../bidder/components/Dashboard/Company/CompanyForm';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function Create() {
  const [errors, setErrors] = useState({});
  const [company, setCompany] = useState({
    name: '',
    color: '#ffffff',
    nif: '',
    country: '',
    product_category: '',
    role: '',
    status: '',
    logo: undefined,
    logo_url: '',
    updated_at: 'now'
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name } = e.target;
    const value = name === 'logo' ? e.target.files[0] : e.target.value;

    setCompany((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();

    const payload = new FormData();
    if (company.logo !== undefined) {
      payload.append('logo', company.logo);
    }
    payload.append('name', company.name);
    payload.append('color', company.color);
    payload.append('nif', company.nif);
    payload.append('country', company.country);
    payload.append('product_category', company.product_category);
    payload.append('role', company.role);
    payload.append('status', company.status);

    storeCompany(payload)
      .then((response) => {
        setCompany({ ...response.data.data, ...{ logo: undefined } });
        setErrors({});
        navigate('/dashboard/companies/' + response.data.data.id);
        notify(dispatch, 'Company updated successfully', 'success');
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
          notify(dispatch, response.message, 'warning');
        }
      });
  };

  return (
    <>
      <AuthLayout title={'Create company'}>
        <Stack spacing={2}>
          <BidderPanel title={'Company Info'}>
            <CompanyForm
              model={company}
              errors={errors}
              onChangeHandler={handleChange}
              key={company.updated_at}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '5px 0' }}>
              <Button
                variant={'fm-light'}
                size={'large'}
                color={'success'}
                type="submit"
                onClick={handleSubmitForm}>
                Save
              </Button>
            </div>
          </BidderPanel>
        </Stack>
      </AuthLayout>
    </>
  );
}
