import React from 'react';

export default function TableSubTitle({ subTitle }) {
  return (
    <p
      style={{
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 300,
        color: '##40464C',
        margin: 0
      }}>
      {subTitle}
    </p>
  );
}
