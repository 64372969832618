import { Bar } from 'react-chartjs-2';
import React from 'react';
import ChartWrapper from '../../../../bidder/components/Charts/ChartWrapper';
import {
  downloadPNGByElementId,
  downloadCSVByDataset
} from '../../../../bidder/helpers/chart-helper';

import charts from '../../../../bidder/constants/colors/charts';
import { Typography } from '@mui/material';
import { getStageLabel } from '../../../../bidder/helpers/stage-helper';

export default function ByScoresGlobalResult({ data, stage, showDownload = true }) {
  const stageLabel = getStageLabel(stage);
  const id = `${stage.toUpperCase()}-valoration-per-score`;

  const dataset = {
    labels: data.labels,
    datasets: data.datasets.map((dataset, index) => {
      return {
        ...dataset,
        backgroundColor: [...charts[stage]][index],
        labelColor: [...charts[stage]][index]
      };
    })
  };

  const downloadCallbackAsImage = () => {
    downloadPNGByElementId(id, id);
  };

  const downloadCallbackAsCSV = () => {
    downloadCSVByDataset(id, dataset, 'Scores');
  };

  const getOptions = () => {
    return {
      plugins: {
        title: {
          display: false
        },
        legend: {
          position: 'bottom',
          display: true
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false
          },
          stacked: true
        },
        y: {
          stacked: true
        }
      },

      maintainAspectRatio: false,
      responsive: true,
      maxHeight: 300
    };
  };

  return (
    <>
      <ChartWrapper
        downloadCallbackAsImage={showDownload ? downloadCallbackAsImage : null}
        downloadCallbackAsCSV={showDownload ? downloadCallbackAsCSV : null}
        headerCallback={() => {
          return (
            <>
              <Typography variant={'body1'}>{stageLabel} Global Results by section</Typography>
            </>
          );
        }}>
        <Bar id={id} height={300 + 'px'} options={getOptions()} data={dataset} />
      </ChartWrapper>
    </>
  );
}
