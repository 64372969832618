import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { profileService } from '../../services/profiles';

const EditRegistrationDataModal = ({ open, user = [], onClose, onSuccess }) => {
  const [userName, setUserName] = useState('');
  const [userSurname, setUserSurname] = useState('');
  const [errors, setErrors] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();

    profileService
      .update({
        name: userName,
        surname: userSurname
      })
      .then(function () {
        handleOnClose();
        onSuccess();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };
  const handleOnClose = () => {
    setErrors({});
    onClose();
  };

  useEffect(() => {
    if (user) {
      setUserName(user.name);
      setUserSurname(user.surname);
    }
  }, [user]);

  if (open === false) {
    return '';
  }

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleOnClose}
      aria-describedby="alert-dialog-slide-description">
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle>Edit data</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              variant={'outlined'}
              size={'small'}
              label="Name"
              value={userName}
              onChange={(event) => setUserName(event.target.value)}
              error={errors.name}
              helperText={errors.name}
            />
            <TextField
              fullWidth
              variant={'outlined'}
              size={'small'}
              label="Surname"
              value={userSurname}
              onChange={(event) => setUserSurname(event.target.value)}
              error={errors.surname}
              helperText={errors.surname}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box sx={{ alignSelf: 'middle', flexGrow: 2 }}>
            <Button
              color={'success'}
              style={{ paddingRight: '39px' }}
              href={`mailto:${process.env.REACT_APP_ADMIN_EMAIL}?subject=Change email`}
              type="submit">
              Change email
            </Button>
          </Box>

          <Button variant={'fm-light'} type="submit">
            Accept
          </Button>
          <Button variant={'fm-light'} onClick={handleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditRegistrationDataModal;
