import { documentManagementService } from '../../../services/vendor';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Stack,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Wrapper from '../../Wrapper/Wrapper';
import SignedModal from './SignedModal';
import PDFViewer from '../../FMHouse/PDFViewer';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const SignModal = ({ biddingId, document = null, onSuccess, onError, onClose }) => {
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [model, setModel] = useState({
    file: null
  });
  const [errors, setErrors] = useState({});

  const handleChangeFile = (e) => {
    const { name, files } = e.target;
    setModel((prevState) => ({
      ...prevState,
      [name]: files[0]
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('file', model.file);
    documentManagementService.sign
      .signDocument(biddingId, document.id, formData)
      .then((result) => {
        setErrors({});
        setModel({ file: null });
        setDocumentUploaded(true);
        onSuccess(result);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
        onError(error);
      });
  };
  useEffect(() => {
    if (document === null) {
      setDocumentUploaded(null);
    }
  }, [document]);

  if (documentUploaded === true) {
    return <SignedModal open={documentUploaded} handleClose={onClose} />;
  }
  if (document === null) {
    return '';
  }

  return (
    <Dialog
      open={!documentUploaded && document !== null}
      keepMounted
      onClose={onClose}
      fullScreen
      aria-describedby="alert-dialog-slide-description">
      <Box component="form" onSubmit={onSubmit}>
        <DialogContent>
          <Wrapper>
            <PDFViewer
              src_download={document.data.download_file_url}
              src_view={document.data.file_url}
            />
            <Box>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                spacing={2}
                sx={{ maxWidth: '80%', margin: 'auto' }}>
                <Typography variant={'body1'}>Upload signed document</Typography>
                <FormControl>
                  <Button variant="fm-outlined" component="label">
                    <FileUploadIcon />
                    <input onChange={handleChangeFile} name="file" type="file" hidden />
                    {model.file ? model.file.name : document?.data.file_uploaded}
                  </Button>
                  <FormHelperText error>{errors.file}</FormHelperText>
                </FormControl>
              </Stack>
            </Box>
          </Wrapper>
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} type="submit">
            Sign
          </Button>
          <Button variant={'fm-light'} onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default SignModal;
