export const linkOfBidding = (bidding) => {
  if (bidding.parent_id) {
    if (bidding.status === 'active') {
      return `/dashboard/biddings/${bidding.parent_id}/dashboard/${bidding.id}`;
    }

    return `/dashboard/biddings/${bidding.id}/setup/scope-and-description`;
  }

  if (bidding.status === 'design') {
    return `/dashboard/biddings/${bidding.id}/setup/bidding-structure`;
  }

  return `/dashboard/biddings/${bidding.id}`;
};
