import * as React from 'react';
import { useEffect, useState } from 'react';
import AuthLayout from '../../../../bidder/layouts/AuthLayout';
import BidderPanel from '../../../../bidder/components/Panels/BidderPanel';
import TableHeader from '../../../../bidder/components/Table/TableHeader';
import { Box, Typography } from '@mui/material';
import Table from '../../../../bidder/components/Table/Table';
import TableBody from '../../../../bidder/components/Table/TableBody';
import TablePagination from '@mui/material/TablePagination';
import { formatDateTime } from '../../../../services/date';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import TableRow from '../../../../bidder/components/Table/TableRow';
import TableHeaderCell from '../../../../bidder/components/Table/TableHeaderCell';
import { getSortParamFromFilter } from '../../../../bidder/services/table';
import { getActivityLog } from '../../../../bidder/services/dashboard/additional/activity-log';

export default function List() {
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [filter, setFilter] = useState({
    search: '',
    columns: [
      {
        key: 'id',
        name: '#',
        class: 'align-left-column',
        sort: 'asc',
        sortable: true,
        filterable: false
      },
      {
        key: 'user_id',
        name: 'User ID',
        sortable: true,
        filterable: false
      },
      {
        key: 'bidding_id',
        name: 'Bidding ID',
        sortable: true,
        filterable: false
      },
      {
        key: 'stage',
        name: 'Stage',
        sortable: true,
        filterable: false
      },
      {
        key: 'action',
        name: 'Action',
        sortable: true,
        filterable: false
      },
      {
        key: 'result',
        name: 'Result',
        sortable: true,
        filterable: false
      },
      {
        key: 'created_at',
        name: 'Created at',
        sortable: true,
        filterable: false
      }
    ]
  });

  useEffect(() => {
    getActivityLog({
      params: {
        filter: {
          search
        },
        page: page + 1,
        per_page: rowsPerPage,
        sort: getSortParamFromFilter(filter)
      }
    }).then((response) => {
      setData(response.data);
    });
  }, [search, page, rowsPerPage, filter]);

  const onSort = (column, value) => {
    let newFilter = filter;
    newFilter['columns'].map((item) => {
      item.sort = null;

      return item;
    });
    let index = newFilter['columns'].findIndex((item) => item.key === column);
    newFilter['columns'][index].sort = value;
    setFilter({ ...newFilter });
  };

  const renderHeaderContentCallback = () => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '45px',
            padding: '0 10px'
          }}>
          <Box sx={{ width: '520px' }}>
            <SearchInput
              value={search}
              onChange={(event) => {
                setPage(0);
                setSearch(event.target.value);
              }}
            />
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <AuthLayout title="Activity Logs" renderHeaderContentCallback={renderHeaderContentCallback}>
        <BidderPanel>
          <Table>
            <TableHeader>
              <TableRow>
                {filter.columns.map((column, index) => (
                  <TableHeaderCell key={index} filter={filter} column={column} onSort={onSort} />
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {data !== null &&
                data.data.map((item) => {
                  return (
                    <tr key={item.id} className={'cursor-pointer'}>
                      <td className={'align-left-column'}>
                        <Typography variant="body1" fontWeight={'bold'}>
                          {item.id}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body1" fontWeight={'bold'}>
                          {item.user_id}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body1" fontWeight={'bold'}>
                          {item.bidding_id}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body1" fontWeight={'bold'}>
                          {item.stage}
                        </Typography>
                      </td>
                      <td>{item.action}</td>
                      <td>{item.result}</td>
                      <td>{formatDateTime(item.created_at)}</td>
                    </tr>
                  );
                })}
            </TableBody>
          </Table>
          {data !== null && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.meta.total}
              rowsPerPage={data.meta.per_page}
              page={data.meta.current_page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </BidderPanel>
      </AuthLayout>
    </>
  );
}
