import { saveAs } from 'file-saver';
import Papaparse from 'papaparse';

export function downloadPNGByElementId(id, name) {
  const canvasSave = document.getElementById(id);
  canvasSave.toBlob(function (blob) {
    saveAs(blob, `${name}.png`);
  });
}

export function downloadCSVByDataset(name, dataset, firstCell = 'Vendors') {
  let data = new Blob(
    [
      Papaparse.unparse({
        fields: [firstCell, ...dataset.labels],
        data: dataset.datasets.map((dataset) => {
          return [dataset.label, ...dataset.data];
        })
      })
    ],
    { type: 'text/csv' }
  );
  let csvURL = window.URL.createObjectURL(data);
  let tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', `${name}.csv`);
  tempLink.click();
}
