import * as React from 'react';
import { useEffect, useState } from 'react';
import AuthLayout from '../../../bidder/layouts/AuthLayout';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import {
  Box,
  Button,
  Link,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import TableHeader from '../../../bidder/components/Table/TableHeader';
import Table from '../../../bidder/components/Table/Table';
import TableBody from '../../../bidder/components/Table/TableBody';
import TableRow from '../../../bidder/components/Table/TableRow';
import TableHeaderCell from '../../../bidder/components/Table/TableHeaderCell';
import TablePagination from '@mui/material/TablePagination';
import { formatDateTime } from '../../../services/date';
import SearchInput from '../../../components/SearchInput/SearchInput';
import { Link as ReactLink } from 'react-router-dom';
import { getCompanies } from '../../../bidder/services/dashboard/companies';
import { getSortParamFromFilter } from '../../../bidder/services/table';

export default function List() {
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('active');
  const [role, setRole] = useState('vendor');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [filter, setFilter] = useState({
    search: '',
    columns: [
      {
        key: 'name',
        name: 'Name',
        class: 'align-left-column',
        sortable: true,
        filterable: false
      },
      {
        key: 'status',
        name: 'Status',
        sortable: true,
        filterable: false
      },
      {
        key: 'role',
        name: 'Role',
        sortable: true,
        filterable: false
      },
      {
        key: 'created_at',
        name: 'Created at',
        sortable: true,
        filterable: false
      },
      {
        key: 'updated_at',
        name: 'Updated at',
        sortable: true,
        filterable: false,
        sort: 'desc'
      }
    ]
  });

  useEffect(() => {
    getCompanies({
      params: {
        filter: {
          search,
          status,
          role
        },
        page: page + 1,
        per_page: rowsPerPage,
        sort: getSortParamFromFilter(filter)
      }
    }).then((response) => {
      setData(response.data);
    });
  }, [search, page, rowsPerPage, status, role, filter]);

  const onSort = (column, value) => {
    let newFilter = filter;
    newFilter['columns'].map((item) => {
      item.sort = null;

      return item;
    });
    let index = newFilter['columns'].findIndex((item) => item.key === column);
    newFilter['columns'][index].sort = value;
    setFilter({ ...newFilter });
  };

  const renderHeaderContentCallback = () => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '45px',
            padding: '0 10px'
          }}>
          <Box sx={{ width: '520px' }}>
            <SearchInput
              value={search}
              onChange={(event) => {
                setPage(0);
                setSearch(event.target.value);
              }}
            />
          </Box>
          <Stack direction="row" spacing={1}>
            <Button variant={'fm-action'} to={`/dashboard/companies/create`} component={ReactLink}>
              Create company
            </Button>
          </Stack>
        </Box>
      </>
    );
  };

  return (
    <>
      <AuthLayout
        title="Companies Library"
        renderHeaderContentCallback={renderHeaderContentCallback}>
        <BidderPanel>
          <div>
            <Stack direction="row" spacing={1}>
              <ToggleButtonGroup
                color="primary"
                value={status}
                exclusive
                onChange={(event, newFilterStatus) => {
                  setPage(0);
                  setStatus(newFilterStatus);
                }}
                size="small"
                aria-label="Filter by status">
                <ToggleButton value="active">Active</ToggleButton>
                <ToggleButton value="inactive">Inactive</ToggleButton>
              </ToggleButtonGroup>
              <ToggleButtonGroup
                color="primary"
                value={role}
                exclusive
                onChange={(event, newFilterStatus) => {
                  setPage(0);
                  setRole(newFilterStatus);
                }}
                size="small"
                aria-label="Filter by role">
                <ToggleButton value="vendor">Vendor</ToggleButton>
                <ToggleButton value="client">Client</ToggleButton>
                <ToggleButton value="admin">Admin</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                {filter.columns.map((column, index) => (
                  <TableHeaderCell key={index} filter={filter} column={column} onSort={onSort} />
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {data !== null &&
                data.data.map((company) => {
                  return (
                    <tr key={company.id} className={'cursor-pointer'}>
                      <td className={'align-left-column'}>
                        <Link
                          to={`/dashboard/companies/${company.id}`}
                          component={ReactLink}
                          onClick={(event) => event.stopPropagation()}
                          sx={{ textDecoration: 'none !important' }}>
                          <Typography variant="body1" fontWeight={'bold'}>
                            {company.name}
                          </Typography>
                        </Link>
                      </td>
                      <td>{company.status}</td>
                      <td>{company.role}</td>
                      <td>{formatDateTime(company.created_at)}</td>
                      <td>{formatDateTime(company.updated_at)}</td>
                    </tr>
                  );
                })}
            </TableBody>
          </Table>
          {data !== null && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.meta.total}
              rowsPerPage={data.meta.per_page}
              page={data.meta.current_page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </BidderPanel>
      </AuthLayout>
    </>
  );
}
