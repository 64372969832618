import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { authorize } from '../../services/dashboard/authorize';

export default function AuthorizeModal({ onApprove, onClose }) {
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState('');
  const handleApproveAuthorization = (event) => {
    event.stopPropagation();

    authorize({
      password
    })
      .then((result) => {
        onApprove();
        console.log(result);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  const handleCloseAuthorization = (event) => {
    if (event.target.classList.contains('authorization-overlay')) {
      onClose();
    }
  };

  return (
    <>
      <div className={'authorization-overlay'} onClick={handleCloseAuthorization}>
        <div className={'authorization-modal'}>
          <div className={'authorization-modal-header authorization-modal-header--marked'}>
            Access to Failed Jobs
          </div>
          <div className={'authorization-modal-body'}>
            <Stack spacing={1}>
              <Typography variant={'body3'}>Access the latest jobs</Typography>
              <FormControl fullWidth size={'small'} error={!!errors.password} variant="outlined">
                <InputLabel htmlFor="login-password">Password</InputLabel>
                <OutlinedInput
                  id="login-password"
                  type={'password'}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  label="Password"
                />
                {typeof errors.password !== 'undefined' && (
                  <FormHelperText>{errors.password}</FormHelperText>
                )}
              </FormControl>
            </Stack>
          </div>
          <div className={'authorization-modal-footer'}>
            <Button variant="fm-outlined-tune" onClick={handleApproveAuthorization}>
              Access to jobs
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
