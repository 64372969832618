import { Button, IconButton } from '@mui/material';
import Link from '@mui/material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import React from 'react';
import { ReactComponent as Locked } from '../../../bidder/assets/icons/locked.svg';
import { ReactComponent as Open } from '../../../bidder/assets/icons/open.svg';
import { ReactComponent as LockedTime } from '../../../bidder/assets/icons/locked_time.svg';
import dayjs from 'dayjs';

export default function RequirementButton({ item, onLockedClick = () => {} }) {
  const renderControlledOpenIcon = () => {
    if (item.controlled_open && item.aperture_at !== null) {
      if (dayjs(item.aperture_at).isAfter(dayjs())) {
        return <LockedTime />;
      }

      return item.access === 'authorized' ? (
        <Open />
      ) : (
        <Locked onClick={() => onLockedClick(item)} />
      );
    }

    return null;
  };
  if (!item.document.actions.show || !item.document.actions.download) {
    return (
      <>
        <Button
          component={'div'}
          variant={`requirement-${item.document.status}`}
          disabled
          endIcon={renderControlledOpenIcon()}>
          <Link>
            <IconButton>
              <VisibilityIcon />
            </IconButton>
          </Link>
          <Link>
            <IconButton>
              <ArrowCircleDownIcon />
            </IconButton>
          </Link>
        </Button>
      </>
    );
  }

  return (
    <>
      <Button
        component={'div'}
        variant={`requirement-${item.document.status}`}
        endIcon={renderControlledOpenIcon()}>
        <Link
          target={'_blank'}
          href={item.access === 'authorized' ? item.document.actions.show ?? '' : null}>
          <IconButton color="primary">
            <VisibilityIcon />
          </IconButton>
        </Link>
        <Link
          target={'_blank'}
          href={item.access === 'authorized' ? item.document.actions.download ?? '' : null}>
          <IconButton color="primary">
            <ArrowCircleDownIcon />
          </IconButton>
        </Link>
      </Button>
    </>
  );
}
