import axios from './axios';

const prefix = '/api/vendor';

export const biddingService = {
  getBiddings: (params = {}) => {
    return axios.get(`${prefix}/biddings`, { params });
  },
  getBidding: (id) => {
    return axios.get(`${prefix}/biddings/${id}`);
  },
  getStage: (id, stage) => {
    return axios.get(`${prefix}/biddings/${id}/${stage}`);
  },
  newQuestion: (biddingId, stage, formData) => {
    return axios.post(`${prefix}/biddings/${biddingId}/${stage}/new-question`, formData);
  }
};

export const acceptService = {
  getAcceptBiddingData: (biddingId, type) => {
    return axios.get(`${prefix}/biddings/${biddingId}/${type}/to-sign`);
  },
  postAcceptBidding: (biddingId, type, formData) => {
    return axios.post(`${prefix}/biddings/${biddingId}/${type}/sign`, formData);
  },
  acceptTermAndConditions: (biddingId) => {
    return axios.post(`${prefix}/biddings/${biddingId}/accept-term-and-conditions`);
  }
};

export const documentManagementService = {
  sign: {
    signDocument: (biddingId, biddingDocumentId, formData) => {
      return axios.post(
        `${prefix}/biddings/${biddingId}/document/${biddingDocumentId}/sign`,
        formData
      );
    }
  },
  upload: {
    uploadDocument: (biddingId, biddingDocumentId, formData) => {
      return axios.post(
        `${prefix}/biddings/${biddingId}/document/${biddingDocumentId}/upload`,
        formData
      );
    }
  }
};
