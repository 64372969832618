import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { invitationsService } from '../../../../../services/admin';
import { useDispatch } from 'react-redux';

const EditVendorModal = ({ open, bidding = [], vendorId, onSuccess, onClose }) => {
  const [selectedSubBiddings, setSelectedSubBiddings] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (vendorId !== null) {
      invitationsService(bidding.id)
        .assignedVendorSubBiddings(vendorId)
        .then((response) => {
          setSelectedSubBiddings(response.data.map((item) => item.id));
        });
    }
  }, [open, vendorId]);

  const handleSubmit = (event) => {
    event.preventDefault();

    invitationsService(bidding.id)
      .changeVendorSubBiddings({
        vendor_id: vendorId,
        sub_bidding_ids: selectedSubBiddings
      })
      .then(() => {
        onSuccess();
        handleOnClose();
        showSuccessEditInvitationToast();
      });
  };

  const showSuccessEditInvitationToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'invitation has been edited',
      toast_type: 'success'
    });
  };
  const handleOnClose = () => {
    onClose();
    setSelectedSubBiddings([]);
  };

  if (vendorId === null) {
    return '';
  }

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleOnClose}
      aria-describedby="alert-dialog-slide-description">
      <Box component="form" onSubmit={handleSubmit} sx={{ width: '535px' }}>
        <DialogTitle>Edit assigned sub biddings</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <FormControl>
              <InputLabel id="sub-biddings-input">Biddings</InputLabel>
              <Select
                labelId="sub-biddings-input"
                multiple
                value={selectedSubBiddings}
                onChange={(event) => {
                  const {
                    target: { value }
                  } = event;
                  setSelectedSubBiddings(typeof value === 'string' ? value.split(',') : value);
                }}
                input={<OutlinedInput label="Biddings" />}>
                {bidding.sub_biddings.map((bidding) => (
                  <MenuItem key={bidding.id} value={bidding.id}>
                    {bidding.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} disabled={selectedSubBiddings.length === 0} type="submit">
            Assign
          </Button>
          <Button variant={'fm-light'} onClick={handleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditVendorModal;
