import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme
} from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { subBiddingService } from '../../../../../services/admin';
import SubBiddingForm from './SubBiddingForm';
import dayjs from 'dayjs';
import { capitalizeFirstLetter } from '../../../../../services/string';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const names = ['rfi', 'rfp', 'rfq', 'rfpq'];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}

const CreateSubBiddingModal = ({ biddingId = null, onSuccess, onClose }) => {
  const [errors, setErrors] = useState({});
  const theme = useTheme();
  const [stages, setStages] = React.useState([]);

  const handleStagesChange = (event) => {
    const {
      target: { value }
    } = event;
    setStages(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const [values, setValues] = useState({
    name: '',
    file_code: '',
    start_date: '',
    end_date: '',
    stages: []
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeDate = (prop) => (date) => {
    setValues({ ...values, [prop]: dayjs(date).format('YYYY-MM-DD') });
  };

  useEffect(() => {
    setValues({ ...values, ['stages']: stages });
  }, [stages]);

  const handleOnClose = () => {
    setErrors({});
    setValues({
      name: '',
      file_code: '',
      start_date: '',
      end_date: '',
      stages: []
    });
    setStages([]);
    onClose();
  };
  const acceptHandle = (event) => {
    event.preventDefault();
    subBiddingService(biddingId)
      .store(values)
      .then(() => {
        onSuccess();
        handleOnClose();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  if (biddingId === null) {
    return '';
  }

  return (
    <Dialog
      open={true}
      keepMounted
      onClose={handleOnClose}
      aria-describedby="alert-dialog-slide-description">
      <Box component="form" onSubmit={acceptHandle} sx={{ width: '535px' }}>
        <DialogTitle>New Sub Bidding</DialogTitle>
        <DialogContent>
          <SubBiddingForm
            errors={errors}
            disabledInputs={{}}
            values={values}
            onChangeName={handleChange('name')}
            onChangeFileCode={handleChange('file_code')}
            onChangeStartDate={handleChangeDate('start_date')}
            onChangeEndDate={handleChangeDate('end_date')}
          />
          <div>
            <FormControl fullWidth size={'small'} sx={{ marginTop: '10px' }} error={errors.stages}>
              <InputLabel id="demo-multiple-chip-label">Stages</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={stages}
                onChange={handleStagesChange}
                input={<OutlinedInput id="select-multiple-chip" label="Stages" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Button key={value} variant={`bidding-${value.toLowerCase()}`}>
                        {capitalizeFirstLetter(
                          value.toLowerCase() === 'rfpq' ? 'RFP/Q' : value.toUpperCase()
                        )}
                      </Button>
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}>
                {names.map((name) => (
                  <MenuItem key={name} value={name} style={getStyles(name, stages, theme)}>
                    {name.toLowerCase() === 'rfpq' ? 'RFP/Q' : name.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
              {typeof errors.stages !== 'undefined' && (
                <FormHelperText>{errors.stages}</FormHelperText>
              )}
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} type="submit">
            Accept
          </Button>
          <Button variant={'fm-light'} onClick={handleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreateSubBiddingModal;
