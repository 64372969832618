import React from 'react';
import './style.css';
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import { Box } from '@mui/material';

const SearchInput = ({ value, onChange }) => {
  return (
    <Box className="search-input">
      <Box className="search-icon">
        <SearchIcon />
      </Box>
      <input value={value} type="search" onChange={onChange} />
    </Box>
  );
};
export default SearchInput;
