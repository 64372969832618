export default [
  {
    props: { variant: 'fm-outlined-tune' },
    style: {
      color: '#2E5855',
      border: '2px solid #2E5855;',
      borderRadius: '30px !important',
      padding: '3px 120px 3px !important',
      backgroundColor: '#fff',
      textTransform: 'none',
      fontWeight: 600,
      '&:disabled': {
        border: '2px solid rgba(0, 0, 0, 0.26) !important'
      },
      '&[aria-disabled=true]': {
        border: '2px solid rgba(0, 0, 0, 0.26) !important'
      }
    }
  },
  {
    props: { variant: 'fm-outlined' },
    style: {
      color: '#6F94AB',
      border: '3px solid rgba(83, 153, 148, 0.4);',
      borderRadius: '32px !important',
      padding: '3px 50px 3px !important',
      backgroundColor: '#fff',
      textTransform: 'none',
      fontWeight: 600,
      '&:disabled': {
        border: '2px solid rgba(0, 0, 0, 0.26) !important'
      },
      '&[aria-disabled=true]': {
        border: '2px solid rgba(0, 0, 0, 0.26) !important'
      }
    }
  },
  {
    props: { variant: 'fm-outlined', size: 'small' },
    style: {
      fontSize: '14px',
      lineHeight: '100%'
    }
  },
  {
    props: { variant: 'fm-outlined', size: 'wide' },
    style: {
      minWidth: '300px'
    }
  },
  {
    props: { variant: 'fm-outlined', size: 'large' },
    style: {
      fontSize: '24px',
      lineHeight: '100%'
    }
  }
];
