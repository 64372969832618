import DeleteModal from '../../DeleteModal/DeleteModal';
import { useDispatch } from 'react-redux';
import { notificationService } from '../../../services/dashboard/dashboardService';

const DestroyNotificationModal = ({ notification = null, onSuccess, onClose }) => {
  const dispatch = useDispatch();
  const handleSubmit = () => {
    notificationService.destroy(notification.id).then((result) => {
      onSuccess(result);
      showSuccessScoreDeleteToast();
    });
  };

  const showSuccessScoreDeleteToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'Notification has been deleted',
      toast_type: 'success'
    });
  };

  const handleClose = () => {
    onClose();
  };

  if (notification === null) {
    return '';
  }

  return (
    <DeleteModal
      closeDeleteModal={handleClose}
      onDeleteHandle={() => handleSubmit()}
      open={true}
      text={`Are you sure you want to delete the notification?`}
    />
  );
};

export default DestroyNotificationModal;
