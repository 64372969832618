import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const DownloadModal = ({ document = null, onClose }) => {
  if (document === null) {
    return '';
  }

  return (
    <Dialog
      open={true}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description">
      <Box component="form" sx={{ width: '535px' }}>
        <DialogTitle>Download document</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '30px 24px',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Button
              href={document.data.file_url}
              variant={'fm-constrained'}
              onClick={onClose.bind(this)}
              target={'_blank'}>
              Click here to download
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DownloadModal;
