export function getSortParamFromFilter(filter) {
  const columns = filter.columns
    .filter((column) => column.sort !== null && column.sort !== undefined)
    .filter((column) => column.sortable)
    .map((column) => {
      return column.sort === 'asc' ? column.key : '-' + column.key;
    });

  return columns.join(',');
}
