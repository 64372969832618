import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { requirements } from '../../../../services/dashboard/biddingService';
import { useDispatch } from 'react-redux';

export default function FinishRequirementsModal({
  biddingId,
  show,
  stageData,
  onSuccess,
  onClose
}) {
  const dispatch = useDispatch();
  if (show === false) {
    return '';
  }
  const stageNotFilled = () => {
    if (stageData.documents.length === 0 || stageData.scores.length === 0) {
      return true;
    }

    return (
      stageData.deadline.start_date === null ||
      stageData.deadline.end_date === null ||
      stageData.qa.start_date === null ||
      stageData.qa.end_date === null
    );
  };

  const handleOnFinish = () => {
    requirements.finish(biddingId).then((response) => {
      dispatch({
        type: 'SHOW',
        text: response.data.message,
        toast_type: 'success'
      });
      onSuccess();
    });
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        open={show}
        keepMounted
        onClose={handleOnClose}
        aria-describedby="alert-dialog-slide-description">
        <Box sx={{ width: '535px' }}>
          <DialogTitle>Finish requirements</DialogTitle>
          <DialogContent>
            {stageNotFilled()
              ? 'Finish requirements? RFQ status is empty, RFP will be converted to RFP/Q.'
              : 'Stage finished.'}
          </DialogContent>
          <DialogActions sx={{ flexWrap: 'wrap' }}>
            <Button variant={'fm-light'} onClick={handleOnFinish}>
              Finish
            </Button>
            <Button variant={'fm-light'} onClick={handleOnClose}>
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
