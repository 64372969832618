import React from 'react';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/poppins';
import '@fontsource/open-sans';
import './services/chart';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { rootReducer } from './store/rootReducer';
import { createRoot } from 'react-dom/client';

const store = configureStore({
  reducer: rootReducer
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
