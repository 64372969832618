import { Box, Button, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import UpdateBiddingDocumentModal from './Setup/RequirementSelection/Modals/UpdateBiddingDocumentModal';
import StoreBiddingDocumentModal from './Setup/RequirementSelection/Modals/StoreBiddingDocumentModal';
import { ModeEdit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import DestroyBiddingDocumentModal from './Setup/RequirementSelection/Modals/DestroyBiddingDocumentModal';
import { formatDateTimeWithTimezone } from '../../../services/date';
import Table from '../../../bidder/components/Table/Table';
import TableHeader from '../../../bidder/components/Table/TableHeader';
import TableBody from '../../../bidder/components/Table/TableBody';
import { getColorForVendorModerationStatus } from '../../../bidder/helpers/colors-helper';
import RequirementButton from '../../../bidder/components/Buttons/RequirementButton';
import TableSubTitle from '../../../bidder/components/Common/TableSubTitle';
import AcceptModal from '../../../bidder/components/Modals/AcceptModal';
import { requirementSelectionService } from '../../../services/admin';
import { useDispatch } from 'react-redux';

const VendorManagementTable = ({ documents = [], biddingId, stage, onUpdateEvent }) => {
  const [documentToUpdate, setDocumentToUpdate] = useState(null);
  const [showStoreModal, setShowStoreModal] = useState(false);
  const [subBidding, setSubBidding] = useState({ id: 0 });
  const [documentsToDestroy, setDocumentsToDestroy] = useState([]);
  const [requirementToUnlock, setRequirementToUnlock] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setSubBidding({
      id: biddingId
    });
  }, [biddingId]);

  const renderTitle = (title) => {
    return (
      <p
        style={{
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 600,
          color: '#464646',
          margin: 0
        }}>
        {title}
      </p>
    );
  };

  const handleUnlockRequirement = (requirement) => {
    setRequirementToUnlock(requirement);
  };

  const onAcceptRequirementToUnlock = () => {
    setRequirementToUnlock(null);
    requirementSelectionService(biddingId)
      .stage(stage)
      .biddingDocument.unlock(requirementToUnlock.id)
      .then(() => {
        onUpdateEvent();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          dispatch({
            type: 'SHOW',
            text: error.response.data.message,
            toast_type: 'error'
          });
        }
      });
    onUpdateEvent();
  };

  return (
    <>
      <div style={{ width: '100%', overflow: 'auto', margin: '16px auto' }}>
        <Table layout={'fixed'}>
          <TableHeader>
            <tr>
              <th
                style={{
                  position: 'sticky',
                  left: '0px',
                  zIndex: 3,
                  width: '200px',
                  backgroundColor: '#fff',
                  padding: '0 16px 0'
                }}>
                {renderTitle('Vendor')}
              </th>
              {documents.requirements.map((requirement, index) => {
                return (
                  <th
                    key={index}
                    style={{ width: '250px', textAlign: 'center', padding: '0 16px 0' }}>
                    {renderTitle(requirement.name)}
                  </th>
                );
              })}
            </tr>
            <tr>
              <th
                style={{
                  position: 'sticky',
                  left: '0px',
                  zIndex: 3,
                  width: '200px',
                  backgroundColor: '#fff',
                  padding: '0 16px 16px'
                }}>
                <br />
                <br />
                <Button variant={'fm-mini'} onClick={() => setShowStoreModal(true)}>
                  New requirement
                </Button>
              </th>
              {documents.requirements.map((requirement, index) => {
                return (
                  <th
                    key={index}
                    style={{ width: '250px', textAlign: 'center', padding: '0 16px 16px' }}>
                    <TableSubTitle
                      subTitle={formatDateTimeWithTimezone(
                        requirement.display_deadline,
                        documents.common.timezone
                      )}
                    />
                    <br />
                    <Button component={'div'}>
                      <Link>
                        <IconButton
                          size={'small'}
                          sx={{ marginLeft: '5px' }}
                          onClick={() => setDocumentToUpdate(requirement)}>
                          <ModeEdit />
                        </IconButton>
                      </Link>
                      <Link>
                        <IconButton onClick={() => setDocumentsToDestroy([requirement])}>
                          <DeleteIcon />
                        </IconButton>
                      </Link>
                    </Button>
                  </th>
                );
              })}
            </tr>
          </TableHeader>
          <TableBody>
            {documents.by_vendors.map((row) => {
              return (
                <tr key={row.vendor.id} style={{ position: 'relative' }}>
                  <td
                    style={{
                      position: 'sticky',
                      left: '0px',
                      zIndex: 3,
                      width: '200px',
                      backgroundColor: '#fff'
                    }}>
                    <Typography
                      variant={'body1'}
                      fontWeight={'bold'}
                      component={RouterLink}
                      color={getColorForVendorModerationStatus(row.vendor.moderation_status)}
                      sx={{ textDecoration: 'none' }}
                      to={`/dashboard/vendors/${row.vendor.id}/${row.common.sub_bidding}/${row.common.stage}`}>
                      {row.vendor.name}
                    </Typography>
                  </td>
                  {row.documents.map((item) => {
                    return (
                      <td key={item.id} style={{ width: '250px', textAlign: 'center' }}>
                        <Box>
                          <RequirementButton item={item} onLockedClick={handleUnlockRequirement} />
                        </Box>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <AcceptModal
        open={requirementToUnlock !== null}
        text={'¿Do you wish to open the document?'}
        onAccept={onAcceptRequirementToUnlock}
        onCloseAcceptModal={() => {
          setRequirementToUnlock(null);
        }}
      />
      <UpdateBiddingDocumentModal
        subBidding={subBidding}
        stage={stage}
        document={documentToUpdate}
        onSuccess={() => {
          onUpdateEvent();
          setDocumentToUpdate(null);
        }}
        onClose={() => setDocumentToUpdate(null)}
      />
      <StoreBiddingDocumentModal
        open={showStoreModal}
        subBidding={subBidding}
        stage={stage}
        onSuccess={() => {
          onUpdateEvent();
          setShowStoreModal(false);
        }}
        onClose={() => setShowStoreModal(false)}
      />
      <DestroyBiddingDocumentModal
        subBidding={subBidding}
        stage={stage}
        documents={documentsToDestroy}
        onSuccess={() => {
          onUpdateEvent();
          setDocumentsToDestroy([]);
        }}
        onClose={() => setDocumentsToDestroy([])}
      />
    </>
  );
};

export default VendorManagementTable;
