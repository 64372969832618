import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Stack
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { authService } from '../../services/authService';

const ForgotPasswordModal = ({ open = false, onSuccess, onError, onClose }) => {
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState('');
  const acceptHandle = (event) => {
    event.preventDefault();
    authService
      .resetPassword({
        email: email
      })
      .then((response) => {
        onSuccess(response.data.data);
        handleOnClose();
      })
      .catch((error) => {
        onError();
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  const handleOnClose = () => {
    setErrors({});
    setEmail('');
    onClose();
  };
  const onChangeEmail = (event) => setEmail(event.target.value);

  if (open === false) {
    return '';
  }

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleOnClose}
      aria-describedby="alert-dialog-slide-description">
      <Box
        component={'form'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          width: '535px'
        }}
        onSubmit={acceptHandle}>
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <Stack marginBottom={'20px'} spacing={2}>
            <TextField
              fullWidth
              variant="outlined"
              error={errors.email}
              label="Email"
              size={'small'}
              value={email}
              onChange={onChangeEmail}
              helperText={errors.email}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} type="submit">
            Send
          </Button>
          <Button variant={'fm-light'} onClick={handleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ForgotPasswordModal;
