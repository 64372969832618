import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import * as React from 'react';
import { vendorService } from '../../../../../services/dashboard/vendorsService';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

export default function AnswerQuestionModal({ question, onSuccess, onClose }) {
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const handleOnClose = () => {
    setAnswer('');
    setCurrentQuestion('');
    setErrors({});
    onClose();
  };

  useEffect(() => {
    if (question !== null) {
      setAnswer(question.answer);
      setCurrentQuestion(question.question);
    }
  }, [question]);

  const handleSubmit = () => {
    vendorService
      .answerQuestion(question.id, {
        answer,
        question: currentQuestion
      })
      .then(() => {
        dispatch({
          type: 'SHOW',
          text: 'Question has been answered',
          toast_type: 'success'
        });
        onSuccess();
        handleOnClose();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  return (
    <>
      <Dialog
        open={question !== null}
        keepMounted
        onClose={handleOnClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Answer question</DialogTitle>
        <DialogContent sx={{ width: '535px' }}>
          <Stack spacing={2}>
            <TextField
              rows={10}
              multiline
              id="question-question"
              type={'textarea'}
              size={'small'}
              error={!!errors.question}
              helperText={errors.question}
              variant={'outlined'}
              value={currentQuestion}
              onChange={(event) => setCurrentQuestion(event.target.value)}
              label="Question"
            />
            <TextField
              rows={10}
              multiline
              id="question-answer"
              type={'textarea'}
              size={'small'}
              error={!!errors.answer}
              helperText={errors.answer}
              variant={'outlined'}
              value={answer}
              onChange={(event) => setAnswer(event.target.value)}
              label="Answer"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} onClick={() => handleSubmit()}>
            Send
          </Button>
          <Button variant={'fm-light'} onClick={handleOnClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
