import { Typography } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import React from 'react';
import ChartWrapper from '../../../../bidder/components/Charts/ChartWrapper';
import {
  downloadPNGByElementId,
  downloadCSVByDataset
} from '../../../../bidder/helpers/chart-helper';

import charts from '../../../../bidder/constants/colors/charts';

const getOptions = () => {
  return {
    plugins: {
      title: {
        display: true
      },
      legend: {
        display: false,
        position: 'bottom'
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        ticks: {
          display: true
        },
        grid: {
          drawBorder: false
        },
        stacked: true
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    maxHeight: 300
  };
};

export default function ByScoreChart({ score, stage, showDownload = true }) {
  const id = `${stage.toUpperCase()}-by-score-${score.id}`;
  const dataset = {
    labels: score.dataset.labels,
    datasets: [
      {
        label: 'Score',
        data: score.dataset.data,
        backgroundColor: charts[stage]
      }
    ]
  };

  const downloadCallbackAsImage = () => {
    downloadPNGByElementId(id, id);
  };

  const downloadCallbackAsCSV = () => {
    downloadCSVByDataset(id, dataset);
  };

  return (
    <>
      <ChartWrapper
        headerCallback={() => {
          return (
            <>
              <Typography variant={'body2'}>[{score.section}]</Typography>
              <Typography variant={'body2'}>{score.comment}</Typography>
            </>
          );
        }}
        downloadCallbackAsImage={showDownload ? downloadCallbackAsImage : null}
        downloadCallbackAsCSV={showDownload ? downloadCallbackAsCSV : null}>
        <Bar id={id} height={300 + 'px'} options={getOptions()} data={dataset} />
      </ChartWrapper>
    </>
  );
}
