import { requirementSelectionService } from '../../../../../../services/admin';
import DeleteModal from '../../../../../DeleteModal/DeleteModal';
import { useDispatch } from 'react-redux';

const DestroyBiddingDocumentModal = ({
  subBidding = null,
  stage = null,
  documents = [],
  onSuccess,
  onClose
}) => {
  const dispatch = useDispatch();
  const handleSubmit = () => {
    requirementSelectionService(subBidding.id)
      .stage(stage)
      .biddingDocument.massDestroy(documents.map((item) => item.id))
      .then((result) => {
        onSuccess(result);
        showSuccessDocumentDeleteToast();
      });
  };

  const showSuccessDocumentDeleteToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'Document was successfully deleted',
      toast_type: 'success'
    });
  };

  const handleClose = () => {
    onClose();
  };

  if (documents.length === 0) {
    return '';
  }

  return (
    <DeleteModal
      closeDeleteModal={handleClose}
      onDeleteHandle={() => handleSubmit()}
      open={true}
      text={`Are you sure you want to delete the ${documents
        .map((document) => document.name)
        .join(', ')}?`}
    />
  );
};

export default DestroyBiddingDocumentModal;
