import { TextField } from '@mui/material';
import * as PropTypes from 'prop-types';
import React from 'react';

function ScoreExtractedFields(props) {
  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        error={!!props.errors.section}
        label="Section"
        size={'small'}
        value={props.value}
        onChange={props.onChange}
        helperText={props.errors.section}
      />
      <TextField
        fullWidth
        variant="outlined"
        error={!!props.errors.weight}
        label="Weight"
        size={'small'}
        type={'number'}
        value={props.value1}
        onChange={props.onChange1}
        helperText={props.errors.weight}
      />
      <TextField
        fullWidth
        variant="outlined"
        error={!!props.errors.comment}
        label="Comment"
        size={'small'}
        value={props.value2}
        onChange={props.onChange2}
        helperText={props.errors.comment}
      />
    </>
  );
}

ScoreExtractedFields.propTypes = {
  errors: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  value1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange1: PropTypes.func,
  value2: PropTypes.string,
  onChange2: PropTypes.func
};

export default ScoreExtractedFields;
