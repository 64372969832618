export default [
  {
    props: { variant: 'bidding-design' },
    style: {
      padding: '5px 12px',
      background: '#fff',
      wordBreak: 'normal',
      width: '65px',
      height: '28px',
      border: '1px dashed #FFC956',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#B07D1D'
    }
  },
  {
    props: { variant: 'bidding-closed' },
    style: {
      padding: '5px 12px',
      background: '#fff',
      wordBreak: 'normal',
      width: '65px',
      height: '28px',
      border: '1px solid #5E707C',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#5E707C'
    }
  },
  {
    props: { variant: 'bidding-active' },
    style: {
      padding: '5px 12px',
      background: '#fff',
      wordBreak: 'normal',
      minHeight: '28px',
      border: '1px solid #62C554',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#62C554'
    }
  },
  {
    props: { variant: 'bidding-inactive' },
    style: {
      padding: '5px 12px',
      background: '#fff',
      wordBreak: 'normal',
      width: '65px',
      height: '28px',
      border: '1px solid #5E707C',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#5E707C'
    }
  },
  {
    props: { variant: 'bidding-finished' },
    style: {
      padding: '5px 12px',
      background: '#fff',
      wordBreak: 'normal',
      minWidth: 'max-content',
      height: '28px',
      border: '1px solid #FF5050',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#FF5050'
    }
  },
  {
    props: { variant: 'bidding-accept' },
    style: {
      padding: '5px 12px',
      background: '#fff',
      wordBreak: 'normal',
      minWidth: 'max-content',
      height: '28px',
      border: '1px solid #7B61FF',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#7B61FF'
    }
  },
  {
    props: { variant: 'bidding-won' },
    style: {
      padding: '5px 12px',
      background: '#fff',
      wordBreak: 'normal',
      width: '65px',
      height: '28px',
      border: '1px solid #7B61FF',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#7B61FF'
    }
  },
  {
    props: { variant: 'bidding-ready' },
    style: {
      padding: '5px 12px',
      background: '#fff',
      wordBreak: 'normal',
      width: '65px',
      height: '28px',
      border: '1px solid #62C554',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#62C554'
    }
  },
  {
    props: { variant: 'bidding-complete' },
    style: {
      padding: '5px 12px',
      background: '#fff',
      wordBreak: 'normal',
      minWidth: 'max-content',
      height: '28px',
      border: '1px solid #62C554',
      borderRadius: '5px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#62C554'
    }
  }
];
