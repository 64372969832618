export default {
  rfi: [
    '#F0FAF5',
    '#E7F6EF',
    '#DEF3EA',
    '#D5EFE5',
    '#CDECE0',
    '#C4E8DB',
    '#AECEC3',
    '#98B4AB',
    '#839A93',
    '#6D817B',
    '#576763',
    '#414D4A',
    '#2B3331'
  ],
  rfp: [
    '#E4F9F9',
    '#D6F3F4',
    '#C9ECEE',
    '#BCE4E7',
    '#B1DADE',
    '#A7CFD5',
    '#94B7BD',
    '#819EA4',
    '#6E868C',
    '#5B6F74',
    '#48575B',
    '#353F43',
    '#21282B'
  ],
  rfq: [
    '#C5FCFE',
    '#A6F4F9',
    '#8AE9F2',
    '#71DAE7',
    '#5CC8D9',
    '#49B3C8',
    '#419CB1',
    '#38869A',
    '#307183',
    '#285C6D',
    '#1F4856',
    '#17343F',
    '#0F2028'
  ],
  rfpq: [
    '#D1E0EE',
    '#B8CDE2',
    '#A0B9D4',
    '#8AA4C5',
    '#7590B4',
    '#627BA2',
    '#576C90',
    '#4C5D7D',
    '#404E6B',
    '#354058',
    '#2A3245',
    '#1F2433',
    '#141720'
  ]
};
