import * as React from 'react';
import { Box, Container, Typography, Button, Stack, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Loading } from '../../../../components/Loading/Loading';
import Wrapper from '../../../../components/Wrapper/Wrapper';
import OutlinedButtonsWrapper from '../../../../components/FMHouse/OutlinedButtonsWrapper';
import { useDispatch } from 'react-redux';
import { scopeAndDescription } from '../../../../services/dashboard/biddingService';
import { formatDateWithTimezone } from '../../../../services/date';
import AuthLayout from '../../../../bidder/layouts/AuthLayout';

const ScopeAndDescription = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [bidding, setBidding] = useState(null);
  const [scope, setScope] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    scopeAndDescription.show(id).then((result) => setBidding(result.data.data));
  }, [id]);

  useEffect(() => {
    if (bidding !== null) {
      setScope(bidding.scope);
      setDescription(bidding.description);
    }
  }, [bidding]);

  const handleSubmit = (event) => {
    event.preventDefault();

    scopeAndDescription
      .update(id, {
        scope,
        description
      })
      .then((response) => {
        setBidding(response.data.data);
        setErrors({});
        dispatch({
          type: 'SHOW',
          text: 'Data was saved successfully',
          toast_type: 'success'
        });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
          dispatch({
            type: 'SHOW',
            text: error.response.data.message,
            toast_type: 'error'
          });
        }
      });
  };

  if (bidding === null) {
    return <Loading />;
  }

  return (
    <>
      <AuthLayout title="Scope and description">
        <Container
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          component="form"
          onSubmit={handleSubmit}>
          <Wrapper maxWidth="750px">
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h2" align="center">
                {bidding.name}
              </Typography>
            </Box>
            <Stack spacing={1}>
              <Typography variant="h3">{bidding.company}</Typography>
              <Typography variant="body1">{bidding.file_code}</Typography>
              <Typography variant="body1">
                Start Date: {formatDateWithTimezone(bidding.start_date, bidding.timezone, 'L')}
              </Typography>
              <Typography variant="body1">
                End Date: {formatDateWithTimezone(bidding.end_date, bidding.timezone, 'L')}
              </Typography>
            </Stack>
            <Box marginY={'30px'}>
              <Stack spacing={2}>
                <TextField
                  rows={10}
                  multiline
                  type={'textarea'}
                  error={!!errors.scope}
                  helperText={errors.scope}
                  variant={'outlined'}
                  value={scope}
                  onChange={(event) => setScope(event.target.value)}
                  label="Scope"
                />
                <TextField
                  rows={10}
                  multiline
                  type={'textarea'}
                  error={!!errors.description}
                  helperText={errors.description}
                  value={description}
                  variant={'outlined'}
                  onChange={(event) => setDescription(event.target.value)}
                  label="Description"
                />
              </Stack>
            </Box>
          </Wrapper>
          <OutlinedButtonsWrapper>
            <Button
              variant="fm-outlined"
              disabled={bidding.status !== 'design'}
              type="submit"
              size={'wide'}
              onClick={() => handleSubmit}>
              Save
            </Button>
            <Button
              variant="fm-outlined"
              size={'wide'}
              disabled={bidding.description.length === 0 || bidding.scope.length === 0}
              href={`/dashboard/biddings/${id}/setup/requirements`}>
              Next
            </Button>
          </OutlinedButtonsWrapper>
        </Container>
      </AuthLayout>
    </>
  );
};

export default ScopeAndDescription;
