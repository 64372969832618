import * as React from 'react';
import { Box, Container, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import PageWrapper from '../../bidder/components/Main/PageWrapper';
import Header from '../../bidder/components/Main/Header';
import Content from '../../bidder/components/Main/Content';
import { ReactComponent as BriefcaseButton } from '../../bidder/assets/icons/briefcase_button.svg';
import { useState } from 'react';
import AuthorizeModal from '../../bidder/components/Dashboard/AuthorizeModal';

const Dashboard = () => {
  const [showAuthorizeModal, setShowAuthorizeModal] = useState(false);
  const navigate = useNavigate();
  const handleAuthorize = () => {
    setShowAuthorizeModal(true);
  };

  const onApprove = () => {
    setShowAuthorizeModal(false);
    navigate('/dashboard/additional/failed-jobs');
  };

  const onClose = () => {
    setShowAuthorizeModal(false);
  };

  return (
    <>
      <PageWrapper>
        <Header />
        <Content>
          <Container
            component="main"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Box
              sx={{
                boxSizing: 'border-box',
                width: 667,
                padding: '90px 128px',
                boxShadow:
                  '0px 100px 80px rgba(0, 0, 0, 0.1), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0718854), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0596107), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.05), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0403893), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0281146)'
              }}>
              <Stack spacing={5}>
                <Button variant="fm-outlined" component={Link} to="/dashboard/biddings">
                  Bidding Management
                </Button>
                <Button variant="fm-outlined" component={Link} to={'/dashboard/vendors'}>
                  Info Vendor
                </Button>
                <Button variant="fm-outlined" component={Link} to={'/dashboard/users'}>
                  User management
                </Button>
                <Button variant="fm-outlined" component={Link} to={'/dashboard/companies'}>
                  Companies
                </Button>
                <Stack direction={'row'} sx={{ justifyContent: 'end' }}>
                  <BriefcaseButton className={'icon-pointer'} onClick={handleAuthorize} />
                </Stack>
              </Stack>
            </Box>
          </Container>
        </Content>
        {showAuthorizeModal && <AuthorizeModal onApprove={onApprove} onClose={onClose} />}
      </PageWrapper>
    </>
  );
};
export default Dashboard;
