import { Link, Typography } from '@mui/material';
import { formatDateTime } from '../../../services/date';
import React, { useEffect, useState } from 'react';
import BidderNotificationIcon from '../BidderNotificationIcon';
import { notificationUnread, notificationRead } from '../../../services/notifications';
import { useDispatch } from 'react-redux';

function NotificationStatus({ value, notificationId }) {
  const [currentValue, setCurrentValue] = useState(value);
  const dispatch = useDispatch();

  const changeStatusOfNotification = (event, newValue) => {
    event.stopPropagation();

    if (newValue) {
      notificationRead(notificationId).then(() => {
        dispatch({
          type: 'SHOW',
          text: 'Notification mark as read',
          toast_type: 'success'
        });
      });
    } else {
      notificationUnread(notificationId).then(() => {
        dispatch({
          type: 'SHOW',
          text: 'Notification mark as unread',
          toast_type: 'success'
        });
      });
    }
    setCurrentValue(newValue);
  };

  return (
    <div
      className={'notifications__list-item-read' + (currentValue ? ' readed' : ' unreaded')}
      onClick={(event) => changeStatusOfNotification(event, !currentValue)}
    />
  );
}

export default function NotificationsTable({ notifications = [], onSelected = null }) {
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();
  const [selectedNotification, setSelectedNotification] = useState(null);

  useEffect(() => {
    setItems(notifications);
    if (selectedNotification !== null) {
      setSelectedNotification(
        notifications.find((notification) => notification.id === selectedNotification.id)
      );
    }
  }, [notifications]);

  const cutString = (str, length) => {
    if (str.length > length) {
      return str.substring(0, length) + '...';
    }

    return str;
  };

  const handleClickOnNotification = async (event, notification) => {
    event.stopPropagation();

    if (onSelected) {
      onSelected(notification);
    }

    setSelectedNotification(notification);
    setItems((items) =>
      items.map((item) => {
        if (item.id === notification.id) {
          return { ...item, read: true };
        }

        return item;
      })
    );

    if (!notification.read) {
      await notificationRead(notification.id).then(() => {
        dispatch({
          type: 'SHOW',
          text: 'Notification mark as read',
          toast_type: 'success'
        });
      });
    }
  };

  return (
    <div className={'notifications-wrapper'}>
      <div className={'notifications__list'}>
        {items.map((notification) => (
          <div
            key={notification.id}
            className={
              'cursor-pointer notifications__list-item' + (notification.read ? ' selected' : '')
            }
            onClick={(event) => handleClickOnNotification(event, notification)}>
            <div className={'notifications__list-item-icon'}>
              <BidderNotificationIcon type={notification.type} />
            </div>
            <div className={'notifications__list-item-content'}>
              <div className={'notifications__list-item-content__title'}>
                {cutString(notification.title, 26)}
              </div>
              <div className={'notifications__list-item-content__short-content'}>
                {cutString(notification.content, 30)}
              </div>
            </div>
            <div className={'notifications__list-item-status'}>
              <NotificationStatus value={notification.read} notificationId={notification.id} />
            </div>
          </div>
        ))}
        {notifications.length === 0 && (
          <div className={'notifications__list-item no-exist'}>
            <div className={'notifications__list-item-content'}>
              <div className={'notifications__list-item-content__title'}>
                There are no notifications
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={'notifications__content'}>
        {selectedNotification && (
          <>
            <div className={'notifications__content-header'}>
              <div className={'notifications__content-header__title'}>
                {selectedNotification.title}
              </div>
              <div className={'notifications__content-header__date'}>
                {formatDateTime(selectedNotification.created_at)}
              </div>
            </div>
            <div className={'notifications__content-body'}>
              <Typography variant={'body2'}>{selectedNotification.content}</Typography>
            </div>
            {selectedNotification.type === 'new_meeting' && (
              <>
                <div className={'notifications__content-body'}>
                  <Link href={selectedNotification.url} target={'_blank'}>
                    Join Here
                  </Link>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
