import React, { useEffect, useState } from 'react';
import { requirementSelectionService } from '../../../../../../services/admin';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  Switch,
  TextField
} from '@mui/material';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useDispatch } from 'react-redux';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const UpdateBiddingDocumentModal = ({
  subBidding = null,
  stage = null,
  document = null,
  onSuccess,
  onClose
}) => {
  const defaultDocument = {
    name: '',
    description: '',
    start_date: null,
    deadline: null,
    aperture_at: null,
    controlled_open: false,
    file: null,
    action: 'upload'
  };

  const [form, setForm] = useState(defaultDocument);
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (document !== null) {
      setForm({
        name: document.name,
        description: document.description,
        start_date: dayjs(document.start_date).format('YYYY-MM-DD HH:mm'),
        deadline: dayjs(document.deadline).format('YYYY-MM-DD HH:mm'),
        aperture_at: dayjs(document.aperture_at).isValid()
          ? dayjs(document.aperture_at).format('YYYY-MM-DD HH:mm')
          : null,
        controlled_open: document.controlled_open,
        action: document.action,
        file: null
      });
    } else {
      setForm(defaultDocument);
    }
  }, [document]);
  const handleClose = () => {
    setErrors([]);
    setForm(defaultDocument);
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangeDeadline = (value) => {
    setForm((prevState) => ({
      ...prevState,
      deadline: dayjs(value).format('YYYY-MM-DD HH:mm')
    }));
  };

  const handleChangeStartDate = (value) => {
    setForm((prevState) => ({
      ...prevState,
      start_date: dayjs(value).format('YYYY-MM-DD HH:mm')
    }));
  };

  const handleChangeAperture = (value) => {
    setForm((prevState) => ({
      ...prevState,
      aperture_at: dayjs(value).format('YYYY-MM-DD HH:mm')
    }));
  };

  const handleChangeSwitcher = (value) => {
    setForm((prevState) => ({
      ...prevState,
      controlled_open: value
    }));
  };

  const updateDocument = (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (form.action !== 'upload' && form.file !== null) {
      formData.append('file', form.file);
    }
    formData.append('name', form.name);
    formData.append('description', form.description);
    formData.append('start_date', form.start_date);
    formData.append('deadline', form.deadline);
    if (form.controlled_open) {
      formData.append('aperture_at', form.aperture_at);
    }
    formData.append('controlled_open', form.controlled_open ? 1 : 0);
    formData.append('action', form.action);
    requirementSelectionService(subBidding.id)
      .stage(stage)
      .biddingDocument.update(document.id, formData)
      .then((response) => {
        onSuccess(response);
        handleClose();
        showSuccessDocumentEditToast();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };
  const handleChangeFile = (e) => {
    const { name, files } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: files[0]
    }));
  };
  const showSuccessDocumentEditToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'Document has been successfully edited',
      toast_type: 'success'
    });
  };

  const renderFileInput = () => {
    if (document.action === 'upload') {
      return '';
    }

    return (
      <>
        <FormControl>
          <Button variant="fm-outlined" component="label">
            <FileUploadIcon />
            <input onChange={handleChangeFile} name="file" type="file" hidden />
            {document.file ? document.file.name : document.file_name}
          </Button>
          <FormHelperText error>{errors.file}</FormHelperText>
        </FormControl>
      </>
    );
  };

  if (document === null) {
    return '';
  }

  return (
    <Dialog open={true} keepMounted aria-describedby="alert-dialog-slide-description">
      <Box component="form" onSubmit={updateDocument} sx={{ width: '535px' }}>
        <DialogTitle>Update document</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              variant="outlined"
              error={!!errors.name}
              label="Name"
              name="name"
              size={'small'}
              value={form.name}
              onChange={handleChange}
              helperText={errors.name}
            />
            <TextField
              fullWidth
              variant="outlined"
              error={!!errors.description}
              label="Description"
              name="description"
              size={'small'}
              value={form.description}
              onChange={handleChange}
              helperText={errors.description}
            />

            <DateTimePicker
              label="Start date"
              inputFormat="DD/MM/YYYY HH:mm"
              value={form.start_date}
              onChange={handleChangeStartDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!errors.start_date}
                  helperText={errors.start_date}
                  name="deadline"
                  variant="outlined"
                  size="small"
                />
              )}
            />

            <DateTimePicker
              label="Deadline"
              inputFormat="DD/MM/YYYY HH:mm"
              value={form.deadline}
              onChange={handleChangeDeadline}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!errors.deadline}
                  helperText={errors.deadline}
                  name="deadline"
                  variant="outlined"
                  size="small"
                />
              )}
            />

            <FormControlLabel
              control={<Switch name="controlled" color={'success'} />}
              label="Controlled Open"
              checked={form.controlled_open ? true : false}
              labelPlacement="start"
              onChange={(event) => handleChangeSwitcher(event.target.checked)}
            />
            {!!form.controlled_open && (
              <DateTimePicker
                label="Aperture at"
                inputFormat="DD/MM/YYYY HH:mm"
                value={form.aperture_at}
                onChange={handleChangeAperture}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!errors.aperture_at}
                    helperText={errors.aperture_at}
                    name="aperture_at"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            )}
            {renderFileInput()}
            <Stack spacing={2} direction={'row'} justifyContent={'center'}>
              <Button variant={'fm-mini'} disabled={document.action === 'upload'}>
                Upload
              </Button>
              <Button variant={'fm-mini'} disabled={document.action === 'download'}>
                Download
              </Button>
              <Button variant={'fm-mini'} disabled={document.action === 'sign'}>
                Signature
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} type="submit">
            Accept
          </Button>
          <Button variant={'fm-light'} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UpdateBiddingDocumentModal;
