import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { autoCompleteService, userService } from '../../../../services/admin';
import { UserForm } from './UserForm';

const CreateUserModal = ({ open = false, onSuccess, onError, onClose, preset = {} }) => {
  const [errors, setErrors] = useState({});
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [role, setRole] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [jobPosition, setJobPosition] = useState('');
  const [phone, setPhone] = useState('');
  const [companies, setCompanies] = useState(null);

  useEffect(() => {
    autoCompleteService
      .companies({
        params: {
          filter: {
            status: 'active'
          }
        }
      })
      .then((res) => {
        setCompanies(res.data);
      });
    setRole(preset?.role ?? '');
    setCompanyId(preset?.company_id ?? '');
  }, [open]);
  const acceptHandle = (event) => {
    event.preventDefault();
    userService
      .store({
        name,
        surname,
        email,
        status,
        role,
        company_id: companyId,
        job_position: jobPosition,
        phone
      })
      .then((response) => {
        onSuccess(response.data.data);
        handleOnClose();
      })
      .catch((error) => {
        onError();
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  const handleOnClose = () => {
    setErrors({});
    setName('');
    setSurname('');
    setEmail('');
    setStatus('');
    setRole('');
    setCompanyId('');
    setJobPosition('');
    setPhone('');
    onClose();
  };

  if (open === false || companies === null) {
    return '';
  }

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleOnClose}
      aria-describedby="alert-dialog-slide-description">
      <Box component="form" onSubmit={acceptHandle} sx={{ width: '535px' }}>
        <DialogTitle>
          <Typography variant="modal-title" marginBottom={'10px'} gutterBottom>
            New User
          </Typography>
        </DialogTitle>
        <DialogContent>
          <UserForm
            errors={errors}
            name={name}
            onChangeName={(event) => setName(event.target.value)}
            surname={surname}
            onChangeSurname={(event) => setSurname(event.target.value)}
            email={email}
            onChangeEmail={(event) => setEmail(event.target.value)}
            jobPosition={jobPosition}
            onChangeJobPosition={(event) => setJobPosition(event.target.value)}
            companies={companies}
            company_id={companyId}
            onChangeCompanyId={(event) => setCompanyId(event.target.value)}
            phone={phone}
            onChangePhone={(event) => setPhone(event.target.value)}
            status={status}
            onChangeStatus={(event) => setStatus(event.target.value)}
            role={role}
            onChangeRole={(event) => setRole(event.target.value)}
            roleReadOnly={preset?.role !== undefined}
            companyIdReadOnly={preset?.company_id !== undefined}
          />
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} type="submit">
            Accept
          </Button>
          <Button variant={'fm-light'} onClick={handleOnClose}>
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreateUserModal;
