import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as CalendarInput } from '../../../assets/icons/event_upcoming.svg';
import { ReactComponent as NewRequirementIcon } from '../../../assets/icons/note.svg';
import { ReactComponent as RequirementChangeIcon } from '../../../assets/icons/file_open.svg';
import { ReactComponent as GeneralInformationIcon } from '../../../assets/icons/info.svg';
import { ReactComponent as NewMeeting } from '../../../assets/icons/new_meeting.svg';
import { notificationService } from '../../../services/dashboard/dashboardService';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers';

const NotificationType = [
  {
    type: 'event_upcoming',
    label: 'New Date',
    icon: <CalendarInput fill="#BD5018" />
  },
  {
    type: 'note',
    label: 'New Requirement',
    icon: <NewRequirementIcon />
  },
  {
    type: 'file_open',
    label: 'Requirement Change',
    icon: <RequirementChangeIcon />
  },
  {
    type: 'info',
    label: 'General Information',
    icon: <GeneralInformationIcon />
  },
  {
    type: 'new_meeting',
    label: 'New Meeting',
    icon: <NewMeeting />
  }
];

const DefaultSelectStyle = {
  borderRadius: '10px',
  color: '#345e64',
  '.MuiSelect-select': {
    paddingTop: '5px',
    paddingBottom: '5px',
    fontWeight: 600
  }
};

const TypeSelect = styled(Select)(() => ({
  ...DefaultSelectStyle,
  ...{
    paddingTop: 0,
    width: '100%',
    '.MuiOutlinedInput-input': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  }
}));

const StoreNotificationModal = ({ dialogOpen, onSuccess, onClose, notification }) => {
  const [message, setMessage] = useState('');
  const [url, setUrl] = useState('');
  const [onDate, setOnDate] = useState(new Date());
  const [type, setType] = useState('info');
  const [title, setTitle] = useState('');
  const [isSending, setIsSending] = useState(false);

  const [errors, setErrors] = useState({});
  const handleAccept = () => {
    setIsSending(true);
    notificationService
      .updateNotification(notification.id, {
        title,
        type,
        content: message,
        url,
        on_at: onDate
      })
      .then(() => {
        setIsSending(false);
        onSuccess();
      })
      .catch((error) => {
        setIsSending(false);
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };
  const handleChangeAperture = (value) => {
    setOnDate(dayjs(value).format('YYYY-MM-DD HH:mm'));
  };
  const handleOnClose = () => {
    setTitle('');
    setMessage('');
    setErrors({});
    onClose();
  };

  useEffect(() => {
    if (notification !== null) {
      setType(notification.type);
      setTitle(notification.title);
      setMessage(notification.content);
      setOnDate(notification.on_at);
      setUrl(notification.url);
    }
  }, [dialogOpen, notification]);

  useEffect(() => {
    setTitle(NotificationType.find((notification) => notification.type === type)?.label);
  }, [type]);

  if (dialogOpen === false) {
    return '';
  }

  return (
    <Dialog
      open={dialogOpen}
      keepMounted
      onClose={handleOnClose}
      aria-describedby="alert-dialog-slide-description">
      <Box component="form">
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
              width: '535px'
            }}>
            <DialogTitle sx={{ paddingLeft: '0px' }}>Update Notification</DialogTitle>
            <Box sx={{ display: 'flex', alignItems: 'center' }} mb={2}>
              <Box>
                <Typography variant="modal-title">Notification Type:</Typography>
              </Box>
              <Box ml={2}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TypeSelect
                    displayEmpty
                    value={type}
                    onChange={(el) => setType(el.target.value)}
                    autoWidth>
                    {NotificationType.map((value) => (
                      <MenuItem value={value.type} key={value.type}>
                        <Box width={20}>{value.icon}</Box>
                        <Box ml={2}>
                          <Typography variant="body2" align="center">
                            {value.label}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </TypeSelect>
                </Box>
              </Box>
            </Box>
            <Stack spacing={2}>
              <TextField
                label={'Title'}
                variant="outlined"
                size={'small'}
                value={title}
                onChange={(el) => setTitle(el.target.value)}
                error={!!errors.title}
                helperText={errors.title}
              />
              {type === 'new_meeting' && (
                <>
                  <TextField
                    label={'Url'}
                    variant="outlined"
                    size={'small'}
                    value={url}
                    onChange={(el) => setUrl(el.target.value)}
                    error={!!errors.url}
                    helperText={errors.url}
                  />

                  <DateTimePicker
                    label="On at (UTC)"
                    inputFormat="DD/MM/YYYY HH:mm"
                    value={onDate}
                    onChange={handleChangeAperture}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={!!errors.on_at}
                        helperText={errors.on_at}
                        name="on_at"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </>
              )}

              <TextField
                label={'Message'}
                size={'small'}
                multiline
                rows={4}
                type={'textarea'}
                variant="outlined"
                value={message}
                onChange={(el) => setMessage(el.target.value)}
                error={!!errors.content}
                helperText={errors.content}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} onClick={handleAccept} disabled={isSending}>
            Send
          </Button>
          <Button onClick={handleOnClose} variant={'fm-light'}>
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default StoreNotificationModal;
