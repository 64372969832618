import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';

const AcceptModal = ({ open, onCloseAcceptModal, onAccept, text }) => {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onCloseAcceptModal}
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle></DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        <Button variant={'fm-light'} onClick={onAccept}>
          Accept
        </Button>
        <Button variant={'fm-light'} onClick={onCloseAcceptModal}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptModal;
