import React from 'react';

import { useTheme } from '@mui/material/styles';

function getColorByName(colorName) {
  const theme = useTheme();
  const colorValue = theme.palette[colorName]?.main;

  return colorValue;
}

const ValorationProgress = ({ value, color }) => {
  const processedColor = getColorByName(color);

  return (
    <>
      <div className="bidder-linear-progress">
        {value > 0 && (
          <div
            className="bidder-linear-progress__progress"
            style={{ width: `${value}%`, backgroundColor: processedColor }}>
            {value}%
          </div>
        )}
      </div>
    </>
  );
};

export default ValorationProgress;
