import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import * as React from 'react';
import { autoCompleteService, userService } from '../../../../../services/admin';
import { useEffect, useState } from 'react';
import UserForm from './UserForm';
import { useDispatch } from 'react-redux';

const EditUserModal = ({ user = null, onSuccess, onClose, preset = {} }) => {
  const [errors, setErrors] = useState({});
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [jobPosition, setJobPosition] = useState('');
  const [phone, setPhone] = useState('');
  const [companies, setCompanies] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user !== null) {
      setErrors({});
      setName(user.name);
      setSurname(user.surname);
      setEmail(user.email);
      setStatus(user.status);
      setCompanyId(user.company_id);
      setJobPosition(user.job_position);
      setPhone(user.phone);
    }
  }, [user]);

  useEffect(() => {
    autoCompleteService.companies().then((res) => {
      setCompanies(res.data);
    });
  }, [open]);

  const handleSendInvitation = () => {
    if (confirm('Are you sure you want to send invitation?')) {
      userService.sendVendorInvite(user.id).then(() => {
        dispatch({
          type: 'SHOW',
          text: 'Invite sent successfully',
          toast_type: 'success'
        });
      });
    }
  };

  const handleResetPassword = () => {
    if (confirm('Are you sure you want to reset password?')) {
      userService.resetPassword(user.id).then(() => {
        dispatch({
          type: 'SHOW',
          text: 'Password reset successfully',
          toast_type: 'success'
        });
      });
    }
  };

  const acceptHandleEdit = (event) => {
    event.preventDefault();
    userService
      .update(user.id, {
        name,
        surname,
        email,
        status,
        company_id: companyId,
        job_position: jobPosition,
        phone
      })
      .then((response) => {
        onSuccess(response.data.data);
        handleOnClose();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  const handleOnClose = () => {
    setErrors({});
    setName('');
    setSurname('');
    setEmail('');
    setStatus('');
    setCompanyId('');
    setJobPosition('');
    setPhone('');
    onClose();
  };

  if (user === null || companies === null) {
    return '';
  }

  return (
    <Dialog
      open={true}
      keepMounted
      onClose={handleOnClose}
      aria-describedby="alert-dialog-slide-description">
      <Box component="form" onSubmit={acceptHandleEdit} sx={{ width: '535px' }}>
        <DialogTitle>
          <Typography variant="modal-title" gutterBottom>
            Edit User
          </Typography>
        </DialogTitle>
        <DialogContent>
          <UserForm
            errors={errors}
            name={name}
            onChangeName={(event) => setName(event.target.value)}
            surname={surname}
            onChangeSurname={(event) => setSurname(event.target.value)}
            email={email}
            onChangeEmail={(event) => setEmail(event.target.value)}
            jobPosition={jobPosition}
            onChangeJobPosition={(event) => setJobPosition(event.target.value)}
            companies={companies}
            company_id={companyId}
            onChangeCompanyId={(event) => setCompanyId(event.target.value)}
            phone={phone}
            onChangePhone={(event) => setPhone(event.target.value)}
            status={status}
            onChangeStatus={(event) => setStatus(event.target.value)}
            companyIdReadOnly={preset?.company_id !== undefined}
          />
        </DialogContent>
        <DialogActions>
          {user.role === 'client' ? (
            <Box sx={{ alignSelf: 'middle', flexGrow: 2 }}>
              <Button variant={'fm-light'} onClick={handleSendInvitation}>
                Send invite
              </Button>
            </Box>
          ) : (
            ''
          )}
          <Button variant={'fm-light'} onClick={handleResetPassword}>
            Reset password
          </Button>
          <Button variant={'fm-light'} type="submit">
            Edit
          </Button>
          <Button variant={'fm-light'} onClick={handleOnClose}>
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditUserModal;
