import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../components/Loading/Loading';
import { acceptService } from '../../../services/vendor';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Stack,
  Typography
} from '@mui/material';
import Wrapper from '../../../components/Wrapper/Wrapper';
import OutlinedButtonsWrapper from '../../../components/FMHouse/OutlinedButtonsWrapper';
import PDFViewer from '../../../components/FMHouse/PDFViewer';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AuthLayout from '../../../bidder/layouts/AuthLayout';

const BiddingAccept = () => {
  const { id, document = null } = useParams();
  const navigate = useNavigate();
  const [model, setModel] = useState({
    file: null
  });
  const [errors, setErrors] = useState({});
  const [acceptData, setAcceptData] = useState(null);

  const handleChangeFile = (e) => {
    const { name, files } = e.target;
    console.log(e.target);

    setModel((prevState) => ({
      ...prevState,
      [name]: files[0]
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('file', model.file);
    acceptService
      .postAcceptBidding(id, document, formData)
      .then(() => {
        setErrors({});
        setModel({ file: null });
        navigate(
          acceptData.next.length === 0
            ? `/vendor/biddings/${id}`
            : `/vendor/biddings/${id}/accept/${acceptData.next}`
        );
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  const fetchDataToAccept = () => {
    acceptService
      .getAcceptBiddingData(id, document)
      .then((result) => {
        setAcceptData(result.data);
      })
      .catch((error) => {
        navigate('/error', {
          state: { status: error.status, message: error.message },
          replace: true
        });
      });
  };

  useEffect(() => {
    fetchDataToAccept();
  }, [id, document]);

  const signDocument = async () => {
    if (document === 'accept-terms-and-conditions') {
      acceptService.acceptTermAndConditions(id).then(() => navigate(`/vendor/biddings/`));
    }
  };

  if (acceptData === null || document === null) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <AuthLayout title={`Sign in`}>
        <Container>
          <Wrapper>
            <Typography variant={'h3'} fontWeight={'normal'} gutterBottom>
              {acceptData.title}
            </Typography>
            <PDFViewer src_view={acceptData.path} src_download={acceptData.path_download} />
            {document !== 'accept-terms-and-conditions' && (
              <Box component="form" onSubmit={onSubmit}>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  spacing={2}
                  sx={{ maxWidth: '80%', margin: 'auto' }}>
                  <Typography variant={'body1'}>Upload signed document</Typography>
                  <FormControl>
                    <Button variant="fm-outlined" component="label">
                      <FileUploadIcon />
                      <input
                        onChange={handleChangeFile}
                        name="file"
                        type="file"
                        hidden
                        key={document}
                      />
                      {model.file ? model.file.name : acceptData.uploaded_file}
                    </Button>
                    <FormHelperText error>{errors.file}</FormHelperText>
                  </FormControl>
                  <Button variant="fm-outlined" type="submit">
                    Upload
                  </Button>
                </Stack>
              </Box>
            )}
          </Wrapper>
          <OutlinedButtonsWrapper>
            <Button
              variant="fm-constrained"
              href={
                acceptData.prev.length === 0
                  ? `/vendor/biddings`
                  : `/vendor/biddings/${id}/accept/${acceptData.prev}`
              }
              sx={{ width: '360px' }}>
              Previous
            </Button>
            {acceptData.signed ? (
              <Button
                variant="fm-constrained"
                href={
                  acceptData.next.length === 0
                    ? `/vendor/biddings/${id}`
                    : `/vendor/biddings/${id}/accept/${acceptData.next}`
                }
                sx={{ width: '360px' }}>
                Next
              </Button>
            ) : (
              <Button
                disabled={!acceptData.signed && document !== 'accept-terms-and-conditions'}
                variant="fm-constrained"
                onClick={() => signDocument()}
                sx={{ width: '360px' }}>
                {document === 'accept-terms-and-conditions' ? 'Accept' : 'Save'}
              </Button>
            )}
          </OutlinedButtonsWrapper>
        </Container>
      </AuthLayout>
    </>
  );
};

export default BiddingAccept;
