import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import * as React from 'react';
import { biddingService } from '../../services/vendor';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
export const NewQuestionModal = ({ show, biddingId, stage, onClose }) => {
  const [question, setQuestion] = useState('');
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const handleOnClose = () => {
    setQuestion('');
    setErrors({});
    onClose();
  };
  const handleSubmit = () => {
    biddingService
      .newQuestion(biddingId, stage, {
        question
      })
      .then(() => {
        dispatch({
          type: 'SHOW',
          text: 'Question has been added',
          toast_type: 'success'
        });
        handleOnClose();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  return (
    <>
      <Dialog
        open={show}
        keepMounted
        onClose={handleOnClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>New question</DialogTitle>
        <DialogContent sx={{ width: '535px' }}>
          <Stack spacing={2}>
            <TextField
              rows={10}
              multiline
              id="new-question"
              type={'textarea'}
              error={!!errors.question}
              helperText={errors.question}
              variant={'outlined'}
              inputProps={{ maxLength: 800 }}
              value={question}
              onChange={(event) => setQuestion(event.target.value)}
              label="Question"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} onClick={() => handleSubmit()}>
            Send
          </Button>
          <Button variant={'fm-light'} onClick={handleOnClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
