import Table from '../../Table/Table';
import TableHeader from '../../Table/TableHeader';
import TableHeaderCell from '../../Table/TableHeaderCell';
import TableBody from '../../Table/TableBody';
import TableRow from '../../Table/TableRow';
import { useEffect, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import * as React from 'react';
import { getCompanyUsers } from '../../../services/dashboard/companies';
import { getSortParamFromFilter } from '../../../services/table';
import { formatDateTime } from '../../../../services/date';
import { capitalizeFirstLetter } from '../../../../services/string';
import { Button, Stack } from '@mui/material';
import CreateUserModal from '../../../../components/Dashboard/Users/Modals/CreateUserModal';
import { notify } from '../../../helpers/notify';
import EditUserModal from '../../../../components/Dashboard/Users/Modals/EditUserModal';
import { useDispatch } from 'react-redux';

export default function CompanyUserTable({ company }) {
  const [filter, setFilter] = useState({
    search: '',
    columns: [
      {
        key: 'id',
        name: '#',
        sortable: true,
        filterable: false,
        sort: 'desc',
        filter: null
      },
      {
        key: 'name',
        name: 'User',
        class: 'align-left-column',
        sortable: true,
        filterable: false,
        sort: null,
        filter: null
      },
      {
        key: 'email',
        name: 'Email',
        sortable: true,
        filterable: false,
        sort: null
      },
      {
        key: 'job_position',
        name: 'Job Position',
        sortable: true,
        filterable: false,
        sort: null
      },
      {
        key: 'status',
        name: 'Status',
        sortable: true,
        filterable: false,
        sort: null
      },
      {
        key: 'last_in',
        name: 'Last log in',
        sortable: true,
        filterable: false,
        sort: null
      }
    ]
  });
  const [data, setData] = useState({
    data: [],
    meta: {
      total: 0,
      per_page: 5,
      current_page: 1
    }
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [userForEdit, setUserForEdit] = useState(null);

  const onSort = (column, value) => {
    let newFilter = filter;
    newFilter['columns'].map((item) => {
      item.sort = null;

      return item;
    });
    let index = newFilter['columns'].findIndex((item) => item.key === column);
    newFilter['columns'][index].sort = value;
    setFilter({ ...newFilter });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const fetchUsers = () => {
    getCompanyUsers(company.id, {
      params: {
        filter: {},
        page: page + 1,
        per_page: rowsPerPage,
        sort: getSortParamFromFilter(filter)
      }
    }).then((result) => {
      setData(result.data);
    });
  };
  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage, filter]);

  const handleSelectRow = (row) => {
    if (row === selectedUser) {
      setSelectedUser(null);
    } else {
      setSelectedUser(row);
    }
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            {filter.columns.map((column, index) => (
              <TableHeaderCell key={index} filter={filter} column={column} onSort={onSort} />
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.data.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => handleSelectRow(row)}
              className={
                row === selectedUser
                  ? 'bidder-table-row--selected bidder-table-row--pointer'
                  : 'bidder-table-row--pointer'
              }>
              <td>{row.id}</td>
              <td className={'align-left-column'}>{row.name}</td>
              <td>{row.email}</td>
              <td>{row.job_position}</td>
              <td>{capitalizeFirstLetter(row.status)}</td>
              <td> {row.last_in ? formatDateTime(row.last_in) : "didn't visit"}</td>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[1, 5, 25, 100]}
        component="div"
        count={data.meta.total}
        rowsPerPage={data.meta.per_page}
        page={data.meta.current_page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={3}>
        {selectedUser !== null ? (
          <Button variant={'fm-outlined'} onClick={() => setUserForEdit(selectedUser.model)}>
            Edit User
          </Button>
        ) : null}
        <Button variant={'fm-outlined'} onClick={() => setShowCreateUserModal(true)}>
          New User
        </Button>
      </Stack>
      <CreateUserModal
        open={showCreateUserModal}
        onClose={() => setShowCreateUserModal(false)}
        onSuccess={() => {
          setShowCreateUserModal(false);
          fetchUsers();
          notify(dispatch, 'User successfully created', 'success');
        }}
        onError={() => notify(dispatch, 'Error while creating user', 'error')}
        preset={{
          company_id: company.id,
          role: company.role
        }}
      />
      <EditUserModal
        user={userForEdit}
        onClose={() => setUserForEdit(null)}
        onSuccess={() => {
          setUserForEdit(null);
          fetchUsers();
          notify(dispatch, 'User successfully updated', 'success');
        }}
        preset={{
          company_id: company.id,
          role: company.role
        }}
      />
    </>
  );
}
