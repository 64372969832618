import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import { biddingService } from '../../../services/vendor';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import {
  StageTabs,
  StageTabsList,
  StageTabPanel,
  StageTab
} from '../../../components/FMHouse/StageTabs';
import OutlinedButtonsWrapper from '../../../components/FMHouse/OutlinedButtonsWrapper';
import moment from 'moment';
import { formatDateTimeWithTimezone } from '../../../services/date';
import { capitalizeFirstLetter } from '../../../services/string';
import { NewQuestionModal } from '../../../components/Vendor/NewQuestionModal';
import UploadModal from '../../../components/Vendor/Documents/UploadModal';
import SignModal from '../../../components/Vendor/Documents/SignModal';
import DownloadModal from '../../../components/Vendor/Documents/DownloadModal';
import Header from '../../../bidder/components/Main/Header';
import Content from '../../../bidder/components/Main/Content';
import PageWrapper from '../../../bidder/components/Main/PageWrapper';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import ClientUpcomingDeadlines from '../../../bidder/components/Client/ClientUpcomingDeadlines';
import ClientQATable from '../../../bidder/components/Client/ClientQATable';
import NotificationsTable from '../../../bidder/components/Client/NotificationsTable';
import TableHeader from '../../../bidder/components/Table/TableHeader';
import Table from '../../../bidder/components/Table/Table';
import TableBody from '../../../bidder/components/Table/TableBody';
import ClientBiddingSelector from '../../../bidder/components/Selectors/BiddingSelector';
import { CircleRequirementsProgress } from '../../../components/FMHouse/ProgressBars/CircleRequirementsProgress';

const BiddingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bidding, setBidding] = useState(null);
  const [stage, setStage] = useState(null);
  const [stageData, setStageData] = useState(null);
  const [showNewQuestionModal, setShowNewQuestionModal] = useState(false);
  const [documentToUpload, setDocumentToUpload] = useState(null);
  const [documentToDownload, setDocumentToDownload] = useState(null);
  const [documentToSign, setDocumentToSign] = useState(null);

  const handleClickOpen = (document) => {
    switch (document.action) {
      case 'sign':
        setDocumentToSign(document);
        break;
      case 'download':
        setDocumentToDownload(document);
        break;
      case 'upload':
        setDocumentToUpload(document);
        break;
    }
  };

  const handleSwitchSubBidding = (item) => {
    if (item.accepted) {
      navigate(`/vendor/biddings/${item.id}`);
    } else {
      navigate(`/vendor/biddings/${item.id}/accept/nda-signing`);
    }
  };

  const fetchBidding = () => {
    biddingService
      .getBidding(id)
      .then((result) => {
        setBidding(result.data.data);
      })
      .catch((error) => {
        navigate('/error', {
          state: { status: error?.response?.status ?? 404, message: error.message },
          replace: true
        });
      });
  };

  const fetchStageData = () => {
    biddingService
      .getStage(id, stage)
      .then((result) => {
        setStageData(result.data);
      })
      .catch((error) => {
        navigate('/error', {
          state: { status: error?.response?.status ?? 404, message: error.message },
          replace: true
        });
      });
  };

  const onUpdateHandler = () => {
    fetchStageData();
  };

  useEffect(() => {
    setBidding(null);
    setStage(null);
    setStageData(null);
    fetchBidding();
  }, [id]);

  useEffect(() => {
    if (bidding !== null) {
      setStage(bidding.stage);
    }
  }, [bidding]);

  useEffect(() => {
    if (stage !== null) {
      fetchStageData();
    }
  }, [stage]);

  return (
    <>
      <PageWrapper>
        <Header title={bidding?.name} />
        <Content>
          {bidding === null ? (
            <Loading />
          ) : (
            <>
              <StageTabs defaultValue={bidding.stage} onChange={(event, value) => setStage(value)}>
                <Stack
                  direction={'row'}
                  spacing={2}
                  sx={{ marginBottom: '20px', justifyContent: 'space-around' }}>
                  <ClientBiddingSelector
                    biddings={bidding.biddings}
                    onClick={handleSwitchSubBidding}
                    currentId={id}
                  />
                  <StageTabsList>
                    {bidding.stages.map((stage) => (
                      <StageTab
                        stage={stage.type}
                        key={stage.type}
                        disabled={bidding.stage !== stage.type}
                      />
                    ))}
                  </StageTabsList>
                </Stack>
                {bidding.stages.map((item) => (
                  <StageTabPanel value={item.type} key={item.type}>
                    {stageData !== null && stage === item.type && (
                      <Stack spacing={2}>
                        <BidderPanel title={'Upcoming Deadlines:'}>
                          <ClientUpcomingDeadlines events={stageData.events} />
                        </BidderPanel>
                        <div className={'deadline-grid-container'}>
                          <BidderPanel title={'Notifications:'}>
                            <NotificationsTable notifications={stageData.notifications} />
                          </BidderPanel>
                          <BidderPanel title={'Progress:'}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '300px'
                              }}>
                              <div
                                style={{
                                  width: '100%',
                                  textAlign: 'center'
                                }}>
                                <CircleRequirementsProgress
                                  value={stageData.requirements_progress}
                                />
                              </div>
                            </div>
                          </BidderPanel>
                        </div>
                        <BidderPanel title={'Requirements'}>
                          <Table className={'vendor-requirements-table'}>
                            <TableHeader>
                              <tr>
                                <th style={{ width: '200px' }}>Requirement</th>
                                <th style={{ width: '170px' }}>Deadline</th>
                                <th style={{ width: '170px' }}>Time Stamp</th>
                                <th style={{ width: '150px' }}>CTA</th>
                                <th>Status</th>
                              </tr>
                            </TableHeader>
                            <TableBody>
                              {stageData.requirements.map((requirement) => (
                                <tr key={requirement.id}>
                                  <td>
                                    <Typography
                                      variant={'body1'}
                                      fontWeight={'bold'}
                                      color={'#40464C'}>
                                      {requirement.name}
                                    </Typography>
                                    <Typography variant={'body2'} color={'#40464C'}>
                                      {requirement.file_code} {requirement.id}
                                    </Typography>
                                    <Typography variant={'body2'} color={'#40464C'}>
                                      {requirement.description}
                                    </Typography>
                                  </td>
                                  <td>
                                    {formatDateTimeWithTimezone(
                                      requirement.deadline,
                                      requirement.timezone
                                    )}
                                  </td>
                                  <td>
                                    {requirement.timestamp !== null &&
                                      formatDateTimeWithTimezone(
                                        requirement.timestamp,
                                        requirement.timezone
                                      )}
                                  </td>
                                  <td>
                                    <Button
                                      fullWidth
                                      onClick={() => handleClickOpen(requirement)}
                                      disabled={
                                        requirement.blocked ||
                                        (requirement.action !== 'download' &&
                                          requirement.status === 'accepted')
                                      }
                                      variant={`vendor-${requirement.action}`}>
                                      {capitalizeFirstLetter(requirement.action)}
                                    </Button>
                                  </td>
                                  <td>
                                    {requirement.hasNotice
                                      ? `${requirement.title}, ${requirement.message}`
                                      : ''}
                                  </td>
                                </tr>
                              ))}
                            </TableBody>
                          </Table>
                        </BidderPanel>
                        <UploadModal
                          biddingId={bidding.id}
                          document={documentToUpload}
                          onSuccess={() => onUpdateHandler()}
                          onError={(error) => console.log(error)}
                          onClose={() => setDocumentToUpload(null)}
                        />
                        <SignModal
                          biddingId={bidding.id}
                          document={documentToSign}
                          onSuccess={() => onUpdateHandler()}
                          onError={(error) => console.log(error)}
                          onClose={() => setDocumentToSign(null)}
                        />
                        <DownloadModal
                          document={documentToDownload}
                          onClose={() => {
                            onUpdateHandler();
                            setDocumentToDownload(null);
                          }}
                        />
                        <BidderPanel title={'Q&A'}>
                          <ClientQATable questions={stageData.questions} />
                          <OutlinedButtonsWrapper>
                            <Button
                              variant={'fm-outlined'}
                              size={'wide'}
                              onClick={() => setShowNewQuestionModal(true)}
                              disabled={
                                !moment().isBetween(
                                  moment(stageData.qa.start_date),
                                  moment(stageData.qa.end_date)
                                )
                              }>
                              Ask a Question
                            </Button>
                            <NewQuestionModal
                              show={showNewQuestionModal}
                              biddingId={bidding.id}
                              stage={stage}
                              onClose={() => {
                                setShowNewQuestionModal(false);
                                fetchStageData();
                              }}
                            />
                          </OutlinedButtonsWrapper>
                        </BidderPanel>
                      </Stack>
                    )}
                  </StageTabPanel>
                ))}
              </StageTabs>
            </>
          )}
        </Content>
      </PageWrapper>
    </>
  );
};

export default BiddingDetails;
