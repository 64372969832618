import { Button, Typography } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Loading } from '../Loading/Loading';
import { capitalizeFirstLetter } from '../../services/string';
import { formatDate } from '../../services/date';
import TablePagination from '@mui/material/TablePagination';
import TableHeaderCell from '../../bidder/components/Table/TableHeaderCell';
import TableHeader from '../../bidder/components/Table/TableHeader';
import TableBody from '../../bidder/components/Table/TableBody';
import { ReactComponent as Down } from '../../bidder/assets/icons/down.svg';
import { useState } from 'react';

const renderStageButton = (row) => {
  let stage = row.stage;

  if (!row.has_active_stage) {
    stage = 'pending';
  }

  return (
    <Button variant={`bidding-${stage}`}>
      {capitalizeFirstLetter(stage === 'rfpq' ? 'RFP/Q' : stage)}
    </Button>
  );
};

function BiddingTableRow({ bidding }) {
  const [open, setOpen] = useState(true);

  return (
    <>
      <tr className={'biddings-table__row-macro'}>
        <td className={'cursor-pointer'} onClick={() => setOpen(!open)}>
          <Down style={open ? { transform: `rotate(180deg)` } : {}} />
        </td>
        <td className={'align-left-column'}>
          <Typography variant="body1" fontWeight={'bold'}>
            {bidding.name}
          </Typography>
          <Typography variant="body2">{bidding.file_code}</Typography>
        </td>
        <td>
          <Button variant={`bidding-${bidding.status}`}>
            {capitalizeFirstLetter(bidding.status)}
          </Button>
        </td>
        <td />
        <td>{formatDate(bidding.start_date)}</td>
        <td>{formatDate(bidding.end_date)}</td>
      </tr>
      {open &&
        bidding.biddings.map((row) => {
          return (
            <tr key={row.id} className={'pointer'}>
              <td />
              <td className={'align-left-column'}>
                <Link to={`/client/biddings/${row.id}`}>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {row.name}
                  </Typography>
                  <Typography variant="body2">{row.file_code}</Typography>
                </Link>
              </td>
              <td>
                <Button variant={`bidding-${row.status}`}>
                  {capitalizeFirstLetter(row.status)}
                </Button>
              </td>
              <td>{renderStageButton(row)}</td>
              <td>{formatDate(row.start_date)}</td>
              <td>{formatDate(row.end_date)}</td>
            </tr>
          );
        })}
    </>
  );
}

const BiddingsTable = ({ biddings = null, handleChangePage, handleChangeRowsPerPage }) => {
  if (biddings === null) {
    return <Loading />;
  }

  const headerDefinitions = [
    {
      name: '',
      key: 'none'
    },
    {
      name: 'Bidding name',
      key: 'name',
      sortable: false,
      class: 'align-left-column'
    },
    {
      name: 'Status',
      key: 'status'
    },
    {
      name: 'Current stage',
      key: 'stage'
    },
    {
      name: 'Start date',
      key: 'start_date',
      sortable: false
    },
    {
      name: 'Finish date',
      key: 'end_date',
      sortable: false
    }
  ];

  return (
    <>
      <table className={'biddings-table'}>
        <TableHeader>
          <tr>
            {headerDefinitions.map((column) => (
              <TableHeaderCell key={column.key} column={column} />
            ))}
          </tr>
        </TableHeader>
        <TableBody>
          {biddings.data.map((row) => (
            <BiddingTableRow bidding={row} key={row.id} />
          ))}
          {biddings.data.length === 0 && (
            <tr>
              <td colSpan={headerDefinitions.length} style={{ textAlign: 'center' }}>
                <Typography variant="body1" fontWeight={'bold'}>
                  No biddings found
                </Typography>
              </td>
            </tr>
          )}
        </TableBody>
      </table>
      <TablePagination
        rowsPerPageOptions={[1, 2, 25, 100]}
        component="div"
        count={biddings.meta.total}
        rowsPerPage={biddings.meta.per_page}
        page={biddings.meta.current_page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default BiddingsTable;
