import { documentManagementService } from '../../../services/vendor';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Stack
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import UploadedModal from './UploadedModal';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const UploadModal = ({ biddingId, document = null, onSuccess, onClose }) => {
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [errors, setErrors] = useState({});
  const defaultDocument = {
    name: '',
    file: null
  };
  const [model, setModel] = useState(defaultDocument);

  const handleOnClose = () => {
    setModel(defaultDocument);
    onClose();
  };

  const handleChangeFile = (e) => {
    const { name, files } = e.target;
    setModel((prevState) => ({
      ...prevState,
      [name]: files[0]
    }));
  };
  useEffect(() => {
    if (document === null) {
      setDocumentUploaded(null);
    }
  }, [document]);

  const onSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('file', model.file);
    formData.append('name', model.name);
    documentManagementService.upload
      .uploadDocument(biddingId, document.id, formData)
      .then((result) => {
        setDocumentUploaded(true);
        onSuccess(result);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  if (document === null) {
    return '';
  }
  console.log(document);
  if (documentUploaded === true) {
    return <UploadedModal open={documentUploaded} handleClose={handleOnClose} />;
  }

  return (
    <Dialog
      open={!documentUploaded}
      keepMounted
      onClose={handleOnClose}
      aria-describedby="alert-dialog-slide-description">
      <Box component="form" onSubmit={onSubmit} sx={{ width: '535px' }}>
        <DialogTitle>Upload document</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ my: '25px' }}>
            <FormControl>
              <Button variant="fm-outlined" component="label">
                <FileUploadIcon />
                <input onChange={handleChangeFile} name="file" type="file" hidden />
                {model.file ? model.file.name : document.data.file_uploaded}
              </Button>
              <FormHelperText error>{errors.file}</FormHelperText>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} type="submit">
            Upload
          </Button>
          <Button variant={'fm-light'} onClick={handleOnClose}>
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UploadModal;
