import * as React from 'react';
import PublicLayout from '../../../bidder/layouts/PublicLayout';
import { Button } from '@mui/material';

const Instructions = () => {
  const downloadLink = process.env.REACT_APP_BACKEND_URL + '/download-manual';

  return (
    <>
      <PublicLayout title="Instructions">
        <Button variant={'fm-outlined'} target={'_blank'} href={downloadLink}>
          Download manual
        </Button>
      </PublicLayout>
    </>
  );
};
export default Instructions;
