import useSWR from 'swr';
import { useEffect } from 'react';
import axios from '../services/axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { capitalizeFirstLetter } from '../services/string';

export const useAuth = ({ middleware, redirectIfAuthenticated } = {}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: user,
    error,
    mutate
  } = useSWR('/api/user', () =>
    axios
      .get('/api/user')
      .then((res) => {
        if (res.data.data) {
          setCurrentUser(res.data.data);
        }

        return res.data.data;
      })
      .catch((error) => {
        if (error.response.status !== 409) throw error;

        // router.push('/verify-email');
      })
  );

  const csrf = () => axios.get('/sanctum/csrf-cookie');

  const showSuccessRegisterToast = (role) => {
    dispatch({
      type: 'SHOW',
      text: `${role} successfully created`,
      toast_type: 'success'
    });
  };

  const setCurrentUser = (user) => {
    dispatch({
      type: 'SET_USER',
      current_user: user
    });
  };

  const register = async ({ setErrors, ...props }) => {
    await csrf();

    setErrors([]);

    axios
      .post('/register', props)
      .then(() => {
        mutate();
        showSuccessRegisterToast(capitalizeFirstLetter(props.role));
      })
      .catch((error) => {
        if (error.response.status !== 422) throw error;

        setErrors(error.response.data.errors);
      });
  };

  const login = async ({ setErrors, ...props }) => {
    await csrf();

    setErrors([]);

    axios
      .post('/login', props)
      .then(() => mutate())
      .catch((error) => {
        if (error.response.status !== 422) throw error;

        setErrors(error.response.data.errors);
      });
  };

  const forgotPassword = async ({ setErrors, setStatus, email }) => {
    await csrf();

    setErrors([]);
    setStatus(null);

    axios
      .post('/forgot-password', { email })
      .then((response) => setStatus(response.data.status))
      .catch((error) => {
        if (error.response.status !== 422) throw error;

        setErrors(error.response.data.errors);
      });
  };

  // const resetPassword = async ({ setErrors, setStatus, ...props }) => {
  //   await csrf();
  //
  //   setErrors([]);
  //   setStatus(null);
  //
  //   axios
  //     .post('/reset-password', { token: router.query.token, ...props })
  //     .then((response) => router.push('/login?reset=' + btoa(response.data.status)))
  //     .catch((error) => {
  //       if (error.response.status !== 422) throw error;
  //
  //       setErrors(error.response.data.errors);
  //     });
  // };

  const resendEmailVerification = ({ setStatus }) => {
    axios
      .post('/email/verification-notification')
      .then((response) => setStatus(response.data.status));
  };

  const logout = async () => {
    if (!error) {
      await axios.post('/logout').then(() => mutate());
    }

    window.location.pathname = '/login';
  };
  useEffect(() => {
    if (middleware === 'guest' && redirectIfAuthenticated && user) {
      switch (user.role) {
        case 'admin':
          navigate('/dashboard');
          break;
        case 'vendor':
          navigate('/vendor/biddings');
          break;
        case 'client':
          navigate('/client/biddings');
          break;
      }
    }
    if (window.location.pathname === '/verify-email' && user?.email_verified_at) {
      history.push(redirectIfAuthenticated);
    }
    if (middleware === 'auth' && error) logout();
  }, [user, error]);

  return {
    user,
    register,
    login,
    forgotPassword,
    // resetPassword,
    resendEmailVerification,
    logout
  };
};
