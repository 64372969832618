import React, { useEffect, useState } from 'react';
import Header from '../../../bidder/components/Main/Header';
import { Button, Checkbox, Stack, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import OutlinedButtonsWrapper from '../../../components/FMHouse/OutlinedButtonsWrapper';
import { nextParticipants, review } from '../../../services/dashboard/scoreReview';
import Papaparse from 'papaparse';
import { useDispatch } from 'react-redux';
import RFIChartPanel from '../../../components/Client/Charts/RFIChartPanel';
import CommonChartPanel from '../../../components/Client/Charts/CommonChartPanel';
import VendorValorationTable from '../../../components/Client/VendorValorationTable';
import PageWrapper from '../../../bidder/components/Main/PageWrapper';
import Content from '../../../bidder/components/Main/Content';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import BidderTableHeader from '../../../bidder/components/Table/TableHeader';
import BidderTable from '../../../bidder/components/Table/Table';
import BidderTableBody from '../../../bidder/components/Table/TableBody';
import BidderLogo from '../../../bidder/assets/images/bidder.svg';

const BiddingReview = () => {
  const { id, stage } = useParams();
  const navigate = useNavigate();
  const [contactOpen, setContactOpen] = useState(false);
  const [contactOpen2, setContactOpen2] = useState(false);
  const [participantsData, setParticipantsData] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [charts, setCharts] = useState(null);

  const onCheckAll = () => {
    if (selectedParticipants.length === participantsData.length) {
      setSelectedParticipants([]);
    } else {
      setSelectedParticipants(participantsData.map((vendor) => vendor.id));
    }
  };
  const onCheckRow = (id, checked = true) => {
    if (checked) {
      if (!selectedParticipants.includes(id)) {
        setSelectedParticipants((prevState) => [id, ...prevState]);
      }
    } else {
      if (selectedParticipants.includes(id)) {
        let newSelectedList = selectedParticipants.filter((value) => value !== id);
        setSelectedParticipants(newSelectedList);
      }
    }
  };

  const [reviewData, setReviewData] = useState({
    active_stage: '',
    scores: [],
    vendors: []
  });

  const handleNextStage = () => {
    nextParticipants
      .post(id, stage, {
        participants: selectedParticipants
      })
      .then(() => {
        navigate(`/dashboard/biddings`);
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: 'SHOW',
            text: error.response.data.message,
            toast_type: 'error'
          });
        } else {
          dispatch({
            type: 'SHOW',
            text: 'An error has occurred',
            toast_type: 'error'
          });
        }
      });
  };

  const dispatch = useDispatch();
  const handleChangeFile = (e) => {
    const { files } = e.target;
    Papaparse.parse(files[0], {
      dynamicTyping: true,
      complete: (results) => {
        e.target.value = null;
        results.data.forEach((rows, i) => {
          if (i > 0) {
            Object.values(rows).forEach((rowValue, j) => {
              if (j > 0) {
                setValue(i - 1, j - 1, rowValue);
              }
            });
          }
        });
        dispatch({
          type: 'SHOW',
          text: 'CSV imported',
          toast_type: 'success'
        });
      },
      error: (err, file, inputElem, reason) => {
        console.error(err, file, inputElem, reason);
        dispatch({
          type: 'SHOW',
          text: err.message,
          toast_type: 'error'
        });
        e.target.value = null;
      }
    });
  };

  const exportCSV = () => {
    let vendors = reviewData.vendors.map((vendor) => [
      vendor.name,
      ...vendor.scores.map((score) => score.weight)
    ]);
    let data = new Blob(
      [
        Papaparse.unparse({
          fields: ['Vendors', ...reviewData.scores.map((score) => score.section)],
          data: vendors
        })
      ],
      { type: 'text/csv' }
    );
    let csvURL = window.URL.createObjectURL(data);
    let tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `bidding-scores-${id}-stage-${stage}.csv`);
    tempLink.click();
  };

  useEffect(() => {
    review.show(id, stage).then((response) => {
      setReviewData(response.data);
    });

    review.charts(id, stage).then((response) => {
      setCharts(response.data);
    });
  }, [id, stage]);

  const getValue = (vendorId, scoreId) => {
    return (
      reviewData.vendors
        .find((vendor) => vendor.id === vendorId)
        .scores.find((score) => score.score_id === scoreId).weight ?? 0
    );
  };
  const setValue = (vendorIndex, scoreIndex, value) => {
    let preparedValue = value;
    if (isNaN(preparedValue)) {
      preparedValue = 0;
    } else {
      if (preparedValue > 100) preparedValue = 100;
      if (preparedValue < 0) preparedValue = 0;
    }

    let temporary = reviewData;
    temporary.vendors[vendorIndex].scores[scoreIndex].weight = preparedValue;
    setReviewData({ ...temporary });
  };

  useEffect(() => {
    if (contactOpen2 === true) {
      nextParticipants.show(id, stage).then((response) => setParticipantsData(response.data));
    } else {
      setParticipantsData([]);
    }
  }, [contactOpen2]);

  const handleCloseSaveModal = () => {
    review
      .post(id, stage, reviewData)
      .then((response) => {
        setReviewData(response.data);
        dispatch({
          type: 'SHOW',
          text: 'Scores was successfully uploaded',
          toast_type: 'success'
        });
        review.charts(id, stage).then((response) => {
          setCharts(response.data);
        });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: 'SHOW',
            text: error.response?.data?.message,
            toast_type: 'error'
          });
        } else {
          dispatch({
            type: 'SHOW',
            text: 'An error has occurred',
            toast_type: 'error'
          });
        }
      });
  };

  const modalHandler = () => {
    setContactOpen(true);
  };
  const handleCloseModal = () => {
    review
      .post(id, stage, reviewData)
      .then((response) => {
        setReviewData(response.data);
        setContactOpen(false);
        setContactOpen2(true);
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: 'SHOW',
            text: error.response?.data?.message,
            toast_type: 'error'
          });
        } else {
          dispatch({
            type: 'SHOW',
            text: 'An error has occurred',
            toast_type: 'error'
          });
        }
      });
  };
  const handleCancelModal = () => {
    setContactOpen(false);
  };

  const handleCancelModal2 = () => {
    setSelectedParticipants([]);
    setParticipantsData([]);
    setContactOpen2(false);
  };

  const calculateTotal = (vendorIndex) => {
    return parseFloat(
      Object.values(reviewData.vendors[vendorIndex].scores)
        .map(
          (score, index) =>
            (parseFloat(score.weight) * parseFloat(reviewData.scores[index].weight)) / 100
        )
        .reduce((partialSum, a) => partialSum + a, 0)
    ).toFixed(2);
  };

  const renderChartBlock = (type) => {
    switch (type) {
      case 'rfi':
        return <RFIChartPanel data={charts.charts ?? {}} showDownload={false} />;
      case 'rfp':
      case 'rfq':
      case 'rfpq':
        return <CommonChartPanel data={charts.charts ?? {}} stage={type} showDownload={false} />;
    }

    return '';
  };

  const renderStatistics = () => {
    if (charts === null) {
      return '';
    }

    return (
      <>
        {renderChartBlock(stage)}
        <BidderPanel title={'Vendor Valoration'}>
          <VendorValorationTable values={charts.valoration} />
        </BidderPanel>
      </>
    );
  };

  const getColorForVendor = (status) => {
    if (status === 'won') {
      return '#9CE7CC';
    }
    if (status === 'lost') {
      return '#EB3800';
    }

    return '#C4C4C4';
  };

  return (
    <>
      <PageWrapper>
        <Header title="Scores Review" />
        <Content>
          <Stack spacing={1}>
            <BidderPanel>
              <BidderTable>
                <BidderTableHeader>
                  <tr>
                    <th></th>
                    {reviewData.scores.map((score) => {
                      return (
                        <th key={score.id} style={{ width: '150px' }}>
                          {score.section}
                          <br />({score.weight})
                        </th>
                      );
                    })}
                    <th>Final score</th>
                  </tr>
                </BidderTableHeader>
                <BidderTableBody>
                  {reviewData.vendors.map((vendor, vendorIndex) => {
                    return (
                      <tr tabIndex={-1} key={vendor.id}>
                        <td style={{ minWidth: '150px' }}>
                          <Typography
                            variant="body1"
                            fontWeight={'bold'}
                            color={getColorForVendor(vendor.status)}>
                            {vendor.name}
                          </Typography>
                        </td>

                        {reviewData.scores.map((score, scoreIndex) => {
                          return (
                            <td key={vendor.id + '-' + score.id}>
                              <TextField
                                size={'small'}
                                placeholder={'0'}
                                inputProps={{
                                  step: 0.01,
                                  min: 0,
                                  max: 100,
                                  type: 'number'
                                }}
                                variant={'standard'}
                                onChange={(event) => {
                                  console.log(event.target.value);
                                  setValue(vendorIndex, scoreIndex, event.target.value);
                                }}
                                value={
                                  getValue(vendor.id, score.id) === 0
                                    ? ''
                                    : getValue(vendor.id, score.id)
                                }
                              />
                            </td>
                          );
                        })}

                        <td style={{ width: '150px' }}>{calculateTotal(vendorIndex)}%</td>
                      </tr>
                    );
                  })}
                </BidderTableBody>
              </BidderTable>
            </BidderPanel>
            <BidderPanel>
              <OutlinedButtonsWrapper>
                <Button variant={'fm-outlined'} onClick={() => exportCSV()}>
                  Export CSV
                </Button>
                <Button
                  variant={'fm-outlined'}
                  component={'label'}
                  disabled={reviewData.active_stage !== stage}>
                  Import CSV
                  <input onChange={handleChangeFile} accept=".csv" name="file" type="file" hidden />
                </Button>
                <Button variant="fm-outlined" onClick={handleCloseSaveModal}>
                  Save
                </Button>
                <Button
                  variant="fm-outlined"
                  size="wide"
                  onClick={modalHandler}
                  disabled={reviewData.active_stage !== stage}>
                  Submit
                </Button>
                <Button variant="fm-outlined" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </OutlinedButtonsWrapper>
            </BidderPanel>

            {renderStatistics()}
          </Stack>
        </Content>
      </PageWrapper>
      {contactOpen ? (
        <div className={'authorization-overlay'}>
          <div className={'authorization-modal authorization-modal-wider'}>
            <div className={'authorization-modal-content'}>
              <div className={'authorization-modal-logo'}>
                <img src={BidderLogo} alt={'Bidder Logo'} />
              </div>
              <div className={'authorization-modal-header'}>Are you sure?</div>
              <div className={'authorization-modal-body'}>
                <div className={'stack stack-centered'}>
                  <div>Are you sure you want to Close the stage</div>
                </div>
              </div>
              <div className={'authorization-modal-footer'}>
                <Button variant={'fm-outlined'} onClick={handleCloseModal}>
                  Close
                </Button>
                <Button variant={'fm-outlined'} onClick={handleCancelModal}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {contactOpen2 ? (
        <div className={'authorization-overlay'}>
          <div className={'authorization-modal authorization-modal-fit-content'}>
            <div className={'authorization-modal-content'}>
              <div className={'authorization-modal-logo'}>
                <img src={BidderLogo} alt={'Bidder Logo'} />
              </div>
              <div className={'authorization-modal-header'}>Next Stage Participants</div>
              <div className={'authorization-modal-body'}>
                <div className={'stack'}>
                  <div>
                    Please go ahead and choose the following vendors to move them to the next phase:
                  </div>
                  <BidderTable className={'bidder-table-review'}>
                    <BidderTableHeader>
                      <tr>
                        <th
                          onClick={(event) => {
                            event.stopPropagation();
                            onCheckAll();
                          }}>
                          <Checkbox
                            color={'success'}
                            checked={selectedParticipants.length === participantsData.length}
                          />
                        </th>
                        <th key="1">Company</th>
                        <th key="2">Score</th>
                      </tr>
                    </BidderTableHeader>
                    <BidderTableBody>
                      {participantsData.map((row) => {
                        return (
                          <tr tabIndex={-1} key={row.id}>
                            <td
                              onClick={(event) => {
                                event.stopPropagation();
                                onCheckRow(row.id, !selectedParticipants.includes(row.id));
                              }}>
                              <Checkbox
                                checked={selectedParticipants.includes(row.id)}
                                color="success"
                              />
                            </td>
                            <td>{row.name}</td>
                            <td>{parseFloat(row.final_score).toFixed(2)}%</td>
                          </tr>
                        );
                      })}
                    </BidderTableBody>
                  </BidderTable>
                </div>
              </div>
              <div className={'authorization-modal-footer'}>
                <Button
                  variant={'fm-outlined'}
                  disabled={selectedParticipants.length === 0}
                  onClick={() => handleNextStage()}>
                  Accept
                </Button>
                <Button variant={'fm-outlined'} onClick={handleCancelModal2}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default BiddingReview;
