import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Typography } from '@mui/material';

export const CircleRequirementsProgress = ({ value }) => {
  return (
    <>
      <div>
        <div>
          <CircularProgressbar
            strokeWidth={12}
            styles={{
              // Customize the root svg element
              root: {
                // Size of the progress ring
                height: '220px',
                width: '220px',
                margin: '15px'
              },
              // Customize the path, i.e. the "completed progress"
              path: {
                // Path color
                stroke: `#46837F`,
                strokeLinecap: 'round',
                transformOrigin: 'center center',
                strokeDashoffset: '20px'
              },
              trail: {
                // Trail color
                stroke: '#F3F9F8',
                strokeLinecap: 'round',
                transformOrigin: 'center center'
              },
              // Customize the text
              text: {
                // Text color
                fill: '#46837F',
                // Text size
                fontSize: '20px',
                fontFamily: 'Poppins'
              }
            }}
            value={value}
            backgroundPadding={20}
            text={
              <>
                <tspan dy={0}>{value}%</tspan>
              </>
            }></CircularProgressbar>
        </div>
        <div style={{ marginTop: '24px' }}>
          <Typography variant={'body1'} color={'#464646'}>
            Requirements met
          </Typography>
        </div>
      </div>
    </>
  );
};
