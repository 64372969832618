import { useEffect, useState } from 'react';
import { ReactComponent as Down } from '../../../bidder/assets/icons/down.svg';
import { cutString } from '../../helpers/string-helper';

const BiddingSelector = ({ biddings, currentId, onClick }) => {
  const [currentBidding, setCurrentBidding] = useState(null);
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    if (biddings !== null) {
      setCurrentBidding(biddings.find((bidding) => bidding.id == currentId));
    }
  }, [biddings, currentId]);

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', showList);

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [showList]);

  return (
    <>
      <div className={'bidder-selector'}>
        <div
          className={'bidder-selector__main cursor-pointer'}
          onClick={() => setShowList(!showList)}>
          {cutString(currentBidding?.name ?? '', 25)}
          <span className={'bidder-selector__main-icon'}>
            <Down />
          </span>
        </div>
        {showList && (
          <>
            <div
              className={'bidder-selector__overlay'}
              onClick={() => setShowList(!showList)}></div>
            <div className={'bidder-selector__list'}>
              {biddings.map((item) => (
                <div
                  className={
                    'bidder-selector__list__item cursor-pointer' +
                    (item.id == currentId ? ' selected' : '')
                  }
                  key={item.id}
                  onClick={() => {
                    setShowList(false);
                    onClick(item);
                  }}>
                  {item.name}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BiddingSelector;
