export default function ChartWrapper({
  children,
  headerCallback = null,
  downloadCallbackAsImage = null,
  downloadCallbackAsCSV = null
}) {
  const renderHeader = () => {
    if (headerCallback !== null) {
      return headerCallback();
    }

    return '';
  };

  const renderButtons = () => {
    if (downloadCallbackAsImage !== null || downloadCallbackAsCSV !== null) {
      return (
        <div className={'bidder-chart-block__buttons'}>
          {downloadCallbackAsImage !== null ? (
            <button
              className={'bidder-chart-block__buttons__button'}
              onClick={downloadCallbackAsImage}>
              Download .png
            </button>
          ) : (
            ''
          )}
          {downloadCallbackAsCSV !== null ? (
            <button
              className={'bidder-chart-block__buttons__button'}
              onClick={downloadCallbackAsCSV}>
              Download .csv
            </button>
          ) : (
            ''
          )}
        </div>
      );
    }
  };

  return (
    <>
      <div className={'bidder-chart-block-wrapper'}>
        {renderHeader()}
        <div className={'bidder-chart-block'}>
          <div className={'bidder-chart-block__chart'}>{children}</div>
          {renderButtons()}
        </div>
      </div>
    </>
  );
}
