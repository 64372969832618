import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import MenuLink from '../../../MenuLink/MenuLink';
import Button from '@mui/material/Button';
import * as React from 'react';

const DetailsBiddingModal = ({ bidding = null, onClose, onAction }) => {
  if (bidding === null) {
    return '';
  }
  let buttons = [];
  if (bidding.status !== 'design') {
    buttons.push(
      <MenuLink
        key={'Basic information'}
        label="Bid Dashboard"
        href={
          bidding.parent_id === null
            ? `/dashboard/biddings/${bidding.id}`
            : `/dashboard/biddings/${bidding.parent_id}/dashboard/${bidding.id}`
        }
      />
    );
  }

  if (bidding.parent_id === null) {
    buttons.push(
      <MenuLink
        key={'bidding-structure'}
        label="Bidding Structure"
        href={`/dashboard/biddings/${bidding.id}/setup/bidding-structure`}
      />
    );
  }

  if (bidding.parent_id !== null) {
    if (bidding.status === 'design') {
      buttons.push(
        <MenuLink
          key={'scope-and-description'}
          label="Scope and Description"
          href={`/dashboard/biddings/${bidding.id}/setup/scope-and-description`}
        />
      );
    }

    buttons.push(
      <MenuLink
        key={'requirements'}
        label="Requirement Selection"
        href={`/dashboard/biddings/${bidding.id}/setup/requirements`}
      />
    );
  }

  if (bidding.allowed.vendor_invitations) {
    buttons.push(
      <MenuLink
        key={'invitations'}
        label="Vendor Invitations"
        href={`/dashboard/biddings/${bidding.id}/setup/vendor-invitations`}
      />
    );
  }

  buttons.push(
    <MenuLink
      key={'edit'}
      label="Edit"
      onClick={() => {
        onAction('edit', bidding);
        onClose();
      }}
    />
  );

  return (
    <Dialog
      open={true}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle>Want to continue?</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Typography variant="dialog-text">
            You can save any progress made in any of the stages of bidding design
          </Typography>
          {buttons}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="fm-light" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailsBiddingModal;
