import { createTheme } from '@mui/material/styles';
//import { colors } from '@mui/material/colors';
import components from './components';
import typography from './typography';

export const FMHouse = createTheme({
  palette: {
    accepted: {
      main: 'rgba(16, 201, 134, 0.72)',
      contrastText: '#fff'
    },
    rejected: {
      main: '#7271FF',
      contrastText: '#fff'
    },
    conditioned: {
      main: '#FF8743',
      contrastText: '#fff'
    },
    statusActive: {
      main: '#41C053',
      contrastText: '#fff'
    },
    statusInactive: {
      main: '#CFCFCF',
      contrastText: '#fff'
    },
    progressRFI: {
      main: '#64AD90',
      contrastText: '#2E5855'
    },
    progressRFP: {
      main: '#6F94AB',
      contrastText: '#2E5855'
    },
    progressRFQ: {
      main: '#67B1C5',
      contrastText: '#2E5855'
    },
    progressRFPQ: {
      main: '#67B1C5',
      contrastText: '#2E5855'
    },
    progressTOTAL: {
      main: '#46837F',
      contrastText: '#2E5855'
    }
  },
  typography: {
    pageTitle: {
      fontFamily: 'Poppins',
      fontWeight: '700',
      fontSize: '36px',
      color: '#000000'
    },
    modalTitle: {
      fontFamily: 'Poppins',
      fontWeight: '500',
      fontSize: '20px',
      color: '#04040B'
    },
    smallGreyText: {
      fontFamily: 'Poppins',
      color: '#949494',
      fontSize: '12px',
      fontWeight: '300'
    },
    GreyText16: {
      fontFamily: 'Poppins',
      color: '#40464C',
      fontSize: '16px',
      fontWeight: '600'
    },
    GreyText16_400: {
      fontFamily: 'Poppins',
      color: '#8A92A6',
      fontSize: '16px',
      fontWeight: '400'
    },
    NewBiddingText16: {
      fontFamily: 'Poppins',
      color: '#B3B3B3',
      fontSize: '16px',
      fontWeight: '600'
    },
    docTitle: {
      fontFamily: 'Poppins',
      color: '#5061FF',
      fontSize: '14px',
      fontWeight: '600'
    },
    DarkGreyText14: {
      fontFamily: 'Poppins',
      color: '#40464C',
      fontSize: '14px',
      fontWeight: '500'
    },
    GreyText14: {
      fontFamily: 'Poppins',
      color: '#696C71',
      fontSize: '14px',
      fontWeight: '500'
    },
    authTitle: {
      fontFamily: 'Poppins',
      color: '#212121',
      fontSize: '20px',
      fontWeight: '600'
    },
    smallLink: {
      fontFamily: 'Poppins',
      color: '#7271FF',
      fontSize: '12px',
      fontWeight: '300',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    ...typography
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .Mui-selected': {
            border: '2px solid #af00b2'
          }
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          padding: '30px 24px'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: '5px 12px',
          fontWeight: '600',
          fontSize: '12px',
          color: '#FBFBFB',
          height: '28px',
          borderRadius: '0'
        },
        label: {
          padding: '0'
        }
      }
    },
    ...components
  }
});
