import { Box, Stack, styled, TextField, Typography } from '@mui/material';
import {
  DateRangePickerDay,
  LocalizationProvider,
  StaticDateRangePicker
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../../services/string';
import { LicenseInfo } from '@mui/x-license-pro';

import Table from '../../../bidder/components/Table/Table';
import TableHeader from '../../../bidder/components/Table/TableHeader';
import TableBody from '../../../bidder/components/Table/TableBody';
import { formatUTCDateToBrowser, dateIsDue } from '../../../services/date';

LicenseInfo.setLicenseKey('YOUR_LICENSE_KEY');

const CircleBidding = styled('div', {
  shouldForwardProp: (prop) => prop !== 'event'
})(({ event }) => {
  return {
    ...getStylesByDate(event)
  };
});

const getLabelByDate = (date) => {
  switch (date.identity) {
    case 'stage':
      return date.stage.toUpperCase() + ' ' + capitalizeFirstLetter(date.type);
    case 'qa':
      return 'Q&A' + ' ' + (date.type === 'start' ? 'Start' : 'Deadline');
    case 'requirement':
      return (
        'Requirement' +
        ' "' +
        date.additional_title +
        '" ' +
        (date.type === 'start' ? 'Start' : 'Deadline')
      );
  }

  return date.identity;
};

const getStylesByDate = (date) => {
  let color = '#fff';
  let borderWidth = 0;

  if (date.type === 'finish') {
    borderWidth = 2;
  }
  if (date.identity === 'qa') {
    color = '#6F94AB';
  }

  if (date.identity === 'requirement') {
    color = '#48B16D';
  }

  if (date.identity === 'stage') {
    if (date.type === 'finish') {
      borderWidth = 4;
    }
    switch (date.stage) {
      case 'rfi':
        color = '#BBE0D1';
        break;
      case 'rfp':
        color = '#AFCED4';
        break;
      case 'rfq':
        color = '#67B1C5';
        break;
      case 'rfpq':
        color = '#67B1C5';
        break;
    }
  }

  let size = `${date.type === 'start' ? 22 : 22 - borderWidth * 2}px`;

  return {
    backgroundColor: (date.type === 'finish' ? '#fff' : color) + ' !important',
    borderColor: color + ' !important',
    borderWidth: borderWidth + 'px',
    borderStyle: 'solid',
    width: size,
    height: size,
    borderRadius: '18px',
    color: '#000 !important'
  };
};

export default function ClientUpcomingDeadlines({ events }) {
  const [period, setPeriod] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);

  const handleSelectDateOnRangePicker = (event) => {
    if (event[1] === null || event[1] === undefined) {
      setSelectedDay(event[0]);
    } else {
      setSelectedDay(event[1]);
    }

    setPeriod(event);
  };

  useEffect(() => {
    console.log('Hi!');
  }, [selectedDay]);

  const renderWeekPickerDay = (day, dateRangePickerDayProps) => {
    const customPickersDayProps = { ...dateRangePickerDayProps };

    const selectedItem = events.find((event) => {
      return (
        formatUTCDateToBrowser(event.triggered_at, 'YYYY-MM-DD') === dayjs(day).format('YYYY-MM-DD')
      );
    });

    return (
      <DateRangePickerDay
        {...customPickersDayProps}
        selected={!!selectedItem}
        event={selectedItem}
        sx={
          selectedItem
            ? getStylesByDate(selectedItem)
            : {
                '.MuiDateRangePickerDay-root': {
                  backgroundColor: '#fff'
                }
              }
        }
      />
    );
  };

  useEffect(() => {
    let xpath = "//div[text()='MUI X: Invalid license key']";
    let matchingElement = document.evaluate(
      xpath,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    if (matchingElement) {
      matchingElement.remove();
    }
  }, []);

  return (
    <>
      <Stack direction={'row'}>
        <Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              value={period}
              calendars={3}
              renderDay={renderWeekPickerDay}
              onChange={handleSelectDateOnRangePicker}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </>
              )}
            />
          </LocalizationProvider>
          {/*<Stack direction={'row'}>
            <Box sx={{ marginLeft: '30px' }}>
              <CalendarInput fill={getEventsByDate().length ? '#BD5018' : 'grey'} />
            </Box>
            <Box ml={7} aria-disabled={true}>
              {getEventsByDate().map((event) => (
                <Typography variant={'body2'} key={event.id}>
                  {getDescriptionByDate(event)}
                </Typography>
              ))}
            </Box>
          </Stack>*/}
        </Stack>

        <div className={'upcoming-deadlines-table-wrapper-wrapper'}>
          <div className={'upcoming-deadlines-table-wrapper'}>
            <Table className={'upcoming-deadlines-table'}>
              <TableHeader>
                <tr>
                  <th></th>
                  <th>Event</th>
                  <th>Date</th>
                </tr>
              </TableHeader>
              <TableBody>
                {events.map((event) => {
                  return (
                    <tr
                      tabIndex={-1}
                      key={event.id}
                      className={dateIsDue(event.triggered_at) ? 'date-is-due' : ''}>
                      <td>
                        <CircleBidding event={event} />
                      </td>
                      <td>
                        <Typography variant="body2">{getLabelByDate(event)}</Typography>
                      </td>
                      <td style={{ width: '120px' }}>
                        <Typography variant="body2">
                          {formatUTCDateToBrowser(event.triggered_at)}
                        </Typography>
                      </td>
                    </tr>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
      </Stack>
    </>
  );
}
