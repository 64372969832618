import axios from './axios';

const prefix = '/api/client';

export const biddingService = {
  getBiddings: (params = {}) => {
    return axios.get(`${prefix}/biddings`, { params });
  },
  getBidding: (id) => {
    return axios.get(`${prefix}/biddings/${id}`);
  },
  getStage: (id, stage) => {
    return axios.get(`${prefix}/biddings/${id}/${stage}`);
  },
  authorizeStage: (id, stage, payload) => {
    return axios.post(`${prefix}/biddings/${id}/${stage}/authorize-stage`, payload);
  },
  approveAuthorizationRequirement: (id, stage, requirement, payload) => {
    return axios.post(
      `${prefix}/biddings/${id}/${stage}/${requirement}/authorize-requirement`,
      payload
    );
  }
};
