const defaultState = {
  show: false,
  text: '',
  toast_type: 'info'
};
const toast = (state = defaultState, action) => {
  const possibleToastTypes = ['success', 'error', 'info', 'warning'];
  const toastType = possibleToastTypes.includes(action.toast_type)
    ? action.toast_type
    : defaultState.toast_type;
  switch (action.type) {
    case 'SHOW':
      return { ...state, show: true, text: action.text, toast_type: toastType };
    case 'HIDE':
      return { ...state, show: false };
    default:
      return state;
  }
};
export default toast;
