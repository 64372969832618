import { Stack, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import * as PropTypes from 'prop-types';
import React from 'react';

function SubBiddingForm(props) {
  return (
    <Stack spacing={2}>
      <TextField
        fullWidth
        variant="outlined"
        error={!!props.errors.name}
        disabled={!!props.disabledInputs.name}
        label="Name"
        size={'small'}
        value={props.values.name}
        onChange={props.onChangeName}
        helperText={props.errors.name}
      />
      <TextField
        fullWidth
        variant="outlined"
        error={!!props.errors.file_code}
        disabled={!!props.disabledInputs.file_code}
        label="File code"
        size={'small'}
        value={props.values.file_code}
        onChange={props.onChangeFileCode}
        helperText={props.errors.file_code}
      />
      <DesktopDatePicker
        label="Start date"
        inputFormat="DD/MM/YYYY"
        value={props.values.start_date}
        disabled={!!props.disabledInputs.start_date}
        onChange={props.onChangeStartDate}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={!!props.errors.start_date}
            helperText={props.errors.start_date}
            variant="outlined"
            size="small"
          />
        )}
      />
      <DesktopDatePicker
        label="End date"
        inputFormat="DD/MM/YYYY"
        value={props.values.end_date}
        disabled={!!props.disabledInputs.end_date}
        onChange={props.onChangeEndDate}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={!!props.errors.end_date}
            helperText={props.errors.end_date}
            variant="outlined"
            size="small"
          />
        )}
      />
    </Stack>
  );
}

SubBiddingForm.propTypes = {
  errors: PropTypes.shape({}),
  disabledInputs: PropTypes.shape({}),
  values: PropTypes.shape({
    end_date: PropTypes.string,
    file_code: PropTypes.string,
    name: PropTypes.string,
    start_date: PropTypes.string
  }),
  onChangeName: PropTypes.func,
  onChangeFileCode: PropTypes.func,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func
};

export default SubBiddingForm;
