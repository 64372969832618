import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { newUserStatuses } from '../../../../../constants/statuses';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { capitalizeFirstLetter } from '../../../../../services/string';

export function UserForm(props) {
  return (
    <Stack spacing={2}>
      <TextField
        fullWidth
        variant="outlined"
        error={!!props.errors.name}
        label="Name"
        size={'small'}
        value={props.name}
        onChange={props.onChangeName}
        helperText={props.errors.name}
      />
      <TextField
        fullWidth
        variant="outlined"
        error={!!props.errors.surname}
        label="Surname"
        size={'small'}
        value={props.surname}
        onChange={props.onChangeSurname}
        helperText={props.errors.surname}
      />
      <TextField
        fullWidth
        variant="outlined"
        error={!!props.errors.email}
        label="Email"
        size={'small'}
        value={props.email}
        onChange={props.onChangeEmail}
        helperText={props.errors.email}
      />
      <TextField
        fullWidth
        variant="outlined"
        error={!!props.errors.job_position}
        label="Job position"
        size={'small'}
        value={props.jobPosition}
        onChange={props.onChangeJobPosition}
        helperText={props.errors.job_position}
      />
      <FormControl fullWidth size={'small'} error={!!props.errors.company_id}>
        <InputLabel id="user-create-company_id-label">Company</InputLabel>
        <Select
          labelId="user-create-company_id-label"
          id="user-create-company_id"
          value={props.company_id}
          disabled={props.companyIdReadOnly}
          input={<OutlinedInput label="Company" error={!!props.errors.company_id} />}
          onChange={props.onChangeCompanyId}>
          <MenuItem value={''}>Select company</MenuItem>
          {props.companies.map((company) => (
            <MenuItem value={company.id} key={company.id}>
              {capitalizeFirstLetter(company.label)}
            </MenuItem>
          ))}
        </Select>
        {typeof props.errors.company_id !== 'undefined' && (
          <FormHelperText>{props.errors.company_id}</FormHelperText>
        )}
      </FormControl>
      <TextField
        fullWidth
        variant="outlined"
        error={!!props.errors.phone}
        label="Phone"
        size={'small'}
        value={props.phone}
        onChange={props.onChangePhone}
        helperText={props.errors.phone}
      />
      <FormControl fullWidth size={'small'} error={!!props.errors.status}>
        <InputLabel id="user-create-status-label">Status</InputLabel>
        <Select
          labelId="user-create-status-label"
          id="user-create-status"
          value={props.status}
          label="Status"
          input={<OutlinedInput label="Status" error={!!props.errors.status} />}
          onChange={props.onChangeStatus}>
          <MenuItem value={''}>Select status</MenuItem>
          {newUserStatuses.map((status) => (
            <MenuItem value={status} key={status}>
              {capitalizeFirstLetter(status)}
            </MenuItem>
          ))}
        </Select>
        {typeof props.errors.status !== 'undefined' && (
          <FormHelperText>{props.errors.status}</FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
}

UserForm.propTypes = {
  errors: PropTypes.shape({}),
  name: PropTypes.string,
  onChangeName: PropTypes.func,
  surname: PropTypes.string,
  onChangeSurname: PropTypes.func,
  email: PropTypes.string,
  onChangeEmail: PropTypes.func,
  jobPosition: PropTypes.string,
  onChangeJobPosition: PropTypes.func,
  phone: PropTypes.string,
  onChangePhone: PropTypes.func,
  status: PropTypes.string,
  onChangeStatus: PropTypes.func,
  onChangeRole: PropTypes.func,
  onChangeCompanyId: PropTypes.func,
  company_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  companyIdReadOnly: PropTypes.bool,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  preset: PropTypes.shape({})
};

export default UserForm;
