import axios from '../axios';

export const review = {
  show: (id, stage) => {
    return axios.get(`/api/dashboard/biddings/${id}/review/${stage}`);
  },
  post: (id, stage, formData) => {
    return axios.post(`/api/dashboard/biddings/${id}/review/${stage}`, formData);
  },
  charts: (id, stage) => {
    return axios.get(`/api/dashboard/biddings/${id}/review/${stage}/charts`);
  }
};

export const nextParticipants = {
  show: (id, stage) => {
    return axios.get(`/api/dashboard/biddings/${id}/review/${stage}/next-participants`);
  },
  post: (id, stage, formData) => {
    return axios.post(`/api/dashboard/biddings/${id}/review/${stage}/next-participants`, formData);
  }
};
