import { ReactComponent as event_upcoming } from '../assets/icons/notifications/event_upcoming.svg';
import { ReactComponent as file_open } from '../assets/icons/notifications/file_open.svg';
import { ReactComponent as info } from '../assets/icons/notifications/info.svg';
import { ReactComponent as note } from '../assets/icons/notifications/note.svg';
import { ReactComponent as new_meeting } from '../assets/icons/notifications/new_meeting.svg';
import { Box } from '@mui/material';

const NotificationIcon = ({ type }) => {
  const Icons = { event_upcoming, file_open, info, note, new_meeting };
  const Icon = Icons[type] ?? Icons['info'];
  let sx = {};

  if (type === 'event_upcoming') {
    sx = { svg: { fill: '#BD5018' } };
  }

  return (
    <Box sx={sx}>
      <Icon />
    </Box>
  );
};
export default NotificationIcon;
