import RequirementAuthorizationModal from './RequirementAuthorizationModal';
import React, { useEffect, useState } from 'react';

export default function RotateAuthorizationModal({ requirements, onApprove, onClose }) {
  const [currentRequirement, setCurrentRequirement] = useState(null);
  const [needReload, setNeedReload] = useState(false);

  useEffect(() => {
    setCurrentRequirement(requirements[0]);
  }, [requirements]);
  const setNextRequirement = () => {
    let index = requirements.findIndex((requirement) => requirement.id === currentRequirement.id);
    if (index === requirements.length - 1) {
      setCurrentRequirement(null);
    } else {
      setCurrentRequirement(requirements[index + 1]);
    }
  };

  const isLastRequirement = () => {
    let index = requirements.findIndex((requirement) => requirement.id === currentRequirement.id);

    return index === requirements.length - 1;
  };

  const handleApprove = () => {
    setNeedReload(true);
    if (isLastRequirement()) {
      onApprove();
    } else {
      setNextRequirement();
    }
  };

  const handleClose = () => {
    if (isLastRequirement()) {
      if (needReload) {
        onApprove();
      } else {
        onClose();
      }
    } else {
      setNextRequirement();
    }
  };
  if (currentRequirement === null) {
    return <></>;
  }

  return (
    <>
      <RequirementAuthorizationModal
        requirement={currentRequirement}
        onApprove={handleApprove}
        onClose={handleClose}
      />
    </>
  );
}
