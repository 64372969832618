import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack
} from '@mui/material';
import React, { useState } from 'react';
import { requirementSelectionService } from '../../../../../../services/admin';
import ScoreExtractedFields from './ScoreExtractedFields';
import { useDispatch } from 'react-redux';

const StoreScoreStructureModal = ({
  open = false,
  subBidding = null,
  stage = null,
  onSuccess,
  onClose
}) => {
  const [errors, setErrors] = useState({});
  const [section, setSection] = useState('');
  const [weight, setWeight] = useState(0);
  const [comment, setComment] = useState('');
  const dispatch = useDispatch();

  const apiService = requirementSelectionService(subBidding.id).stage(stage).scoreStructure;

  const handleSubmit = (event) => {
    event.preventDefault();

    apiService
      .store({
        section,
        weight,
        comment
      })
      .then((result) => {
        onSuccess(result);
        handleClose();
        showSuccessScoreAddToast();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  const showSuccessScoreAddToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'Scoring structure has been added',
      toast_type: 'success'
    });
  };

  const handleClose = () => {
    setErrors({});
    setSection('');
    setWeight(0);
    setComment('');
    onClose();
  };

  if (open === false) {
    return '';
  }

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
      <Box component="form" onSubmit={handleSubmit} sx={{ width: '535px' }}>
        <DialogTitle>New score</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <ScoreExtractedFields
              errors={errors}
              value={section}
              onChange={(event) => setSection(event.target.value)}
              value1={weight}
              onChange1={(event) => setWeight(event.target.value)}
              value2={comment}
              onChange2={(event) => setComment(event.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} type="submit">
            Accept
          </Button>
          <Button variant={'fm-light'} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default StoreScoreStructureModal;
