import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as BidderLogo } from '../../assets/images/bidder.svg';
import Link from '@mui/material/Link';
import { Link as ReactLink } from 'react-router-dom';
import React from 'react';
import { Box, Popover, Stack, Typography } from '@mui/material';
import { useAuth } from '../../../hooks/auth';
import MenuLink from '../../../components/MenuLink/MenuLink';

function HeaderMenu({ isLogout = true }) {
  const { logout } = useAuth({
    middleware: 'logout',
    redirectIfAuthenticated: ''
  });
  const log = (event) => {
    event.preventDefault();
    logout().then((r) => r);
  };

  return (
    <Box
      sx={{
        padding: '30px 24px',
        width: '320px'
      }}>
      <Stack spacing={1} alignItems={'end'}>
        <Typography variant={'modal-title'} fontWeight={'bold'} sx={{ alignSelf: 'start' }}>
          Menu
        </Typography>
        <MenuLink label="About us" href={'/about-us'} />
        {/*<MenuLink label="Contact us" href={'/contact-us} />*/}
        <MenuLink label="FAQ" href={'/faq'} />
        <MenuLink label="Instructions" href={'/instructions'} />
        {isLogout && <MenuLink label="Logout" onClick={(e) => log(e)} />}
      </Stack>
    </Box>
  );
}

export default function Header({
  title,
  renderHeaderContentCallback = null,
  widgets = null,
  hasUser = true
}) {
  const renderHeaderContent = () => {
    if (renderHeaderContentCallback === null) {
      return '';
    }

    return <div className={'header__content'}>{renderHeaderContentCallback()}</div>;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <header className="header">
        <div className="header__menu">
          <MenuIcon onClick={handleClick} />
          <Popover
            id="menu"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}>
            <HeaderMenu isLogout={hasUser} />
          </Popover>
        </div>
        <div className={'header__widgets'}>{widgets}</div>
        <div className="header__title">
          <h1>{title}</h1>
        </div>
        <div className="header__logo">
          <Link component={ReactLink} to="/">
            <BidderLogo />
          </Link>
        </div>
      </header>
      {renderHeaderContent()}
    </>
  );
}
