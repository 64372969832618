import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch
} from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { autoCompleteService, biddingService } from '../../../../services/admin';
import BiddingForm from './BiddingForm';
import dayjs from 'dayjs';

const EditBiddingModal = ({ bidding = null, onSuccess, onClose }) => {
  const [companies, setCompanies] = useState(null);
  const [timezones, setTimezones] = useState(null);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    name: '',
    company_id: '',
    file_code: '',
    start_date: '',
    end_date: '',
    timezone: '',
    client_anonymous: false
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeCheckbox = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };
  const handleChangeDate = (prop) => (date) => {
    setValues({ ...values, [prop]: dayjs(date).format('YYYY-MM-DD') });
  };

  useEffect(() => {
    if (bidding) {
      setValues(bidding);
      autoCompleteService.timezones().then((res) => {
        setTimezones(res.data);
      });
      autoCompleteService.companies().then((res) => {
        setCompanies(res.data);
      });
    }
  }, [bidding]);

  const handleOnClose = () => {
    setErrors({});
    setValues({
      name: '',
      company_id: '',
      file_code: '',
      start_date: '',
      end_date: '',
      timezone: '',
      client_anonymous: false
    });
    onClose();
  };

  const acceptHandle = (event) => {
    event.preventDefault();
    biddingService
      .update(bidding.id, {
        end_date: values.end_date
      })
      .then(() => {
        onSuccess();
        handleOnClose();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  if (bidding === null || companies === null || timezones === null) {
    return '';
  }

  return (
    <Dialog
      open={true}
      keepMounted
      onClose={handleOnClose}
      aria-describedby="alert-dialog-slide-description">
      <Box component="form" onSubmit={acceptHandle} sx={{ width: '535px' }}>
        <DialogTitle>{`Edit Bidding`}</DialogTitle>
        <DialogContent>
          <BiddingForm
            errors={errors}
            disabledInputs={{
              name: true,
              company_id: true,
              file_code: true,
              start_date: true,
              end_date: false,
              timezone: true,
              client_anonymous: true
            }}
            values={values}
            companies={companies}
            timezones={timezones}
            onChangeName={handleChange('name')}
            onChangeCompanyId={handleChange('company_id')}
            onChangeFileCode={handleChange('file_code')}
            onChangeStartDate={handleChangeDate('start_date')}
            onChangeEndDate={handleChangeDate('end_date')}
            onChangeTimezone={handleChange('timezone')}
          />
        </DialogContent>
        <DialogActions>
          <Box sx={{ alignSelf: 'middle', flexGrow: 2 }}>
            <FormControlLabel
              checked={values.client_anonymous}
              labelPlacement="start"
              onChange={handleChangeCheckbox('client_anonymous')}
              control={<Switch color={'success'} />}
              label="Client anonymous"
            />
          </Box>
          <Button variant={'fm-light'} type="submit">
            Accept
          </Button>
          <Button variant={'fm-light'} onClick={handleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditBiddingModal;
