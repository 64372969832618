import { ReactComponent as ArrowDown } from '../../../bidder/assets/icons/ui-kit/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../bidder/assets/icons/ui-kit/arrow-up.svg';

export default function TableHeaderCell({ column, filter = {}, onSort = null }) {
  const getFilterValue = (key) => {
    let index = filter['columns'].findIndex((item) => item.key === key);
    if (index > -1) {
      return filter['columns'][index];
    }

    return null;
  };

  const renderOrderIcon = () => {
    if (column?.sortable === true) {
      switch (getFilterValue(column.key)?.sort) {
        case 'asc':
          return (
            <ArrowUp className={'icon-20 icon-pointer'} onClick={() => onSort(column.key, null)} />
          );
        case 'desc':
          return (
            <ArrowDown
              className={'icon-20 icon-pointer'}
              onClick={() => onSort(column.key, 'asc')}
            />
          );
        default:
          return (
            <ArrowDown
              className={'icon-20 icon-inactive icon-pointer'}
              onClick={() => onSort(column.key, 'desc')}
            />
          );
      }
    }
  };

  return (
    <>
      <th
        colSpan={column.colspan}
        rowSpan={column.rowspan}
        className={`bidder-table__header-cell ${column.class ?? ''}`}>
        <div className={'header-wrapper'}>
          <div>{column.name}</div>
          <div>{renderOrderIcon()}</div>
        </div>
      </th>
    </>
  );
}
