import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { capitalizeFirstLetter } from '../../../../services/string';
import * as PropTypes from 'prop-types';
import React from 'react';

function BiddingForm(props) {
  return (
    <Stack spacing={2}>
      <TextField
        fullWidth
        variant="outlined"
        error={!!props.errors.name}
        disabled={!!props.disabledInputs.name}
        label="Name"
        size={'small'}
        value={props.values.name}
        onChange={props.onChangeName}
        helperText={props.errors.name}
      />
      <FormControl
        fullWidth
        size={'small'}
        error={!!props.errors.company_id}
        disabled={!!props.disabledInputs.company_id}>
        <InputLabel id="user-create-company_id-label">Company</InputLabel>
        <Select
          labelId="user-create-company_id-label"
          id="user-create-company_id"
          value={props.values.company_id}
          input={<OutlinedInput label="Company" error={!!props.errors.company_id} />}
          onChange={props.onChangeCompanyId}>
          <MenuItem value={''}>Select company</MenuItem>
          {props.companies.map((company) => (
            <MenuItem value={company.id} key={company.id}>
              {capitalizeFirstLetter(company.label)}
            </MenuItem>
          ))}
        </Select>
        {typeof props.errors.company_id !== 'undefined' && (
          <FormHelperText>{props.errors.company_id}</FormHelperText>
        )}
      </FormControl>
      <TextField
        fullWidth
        variant="outlined"
        error={!!props.errors.file_code}
        disabled={!!props.disabledInputs.file_code}
        label="File code"
        size={'small'}
        value={props.values.file_code}
        onChange={props.onChangeFileCode}
        helperText={props.errors.file_code}
      />
      <DesktopDatePicker
        label="Start date"
        inputFormat="DD/MM/YYYY"
        value={props.values.start_date}
        disabled={!!props.disabledInputs.start_date}
        onChange={props.onChangeStartDate}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={!!props.errors.start_date}
            helperText={props.errors.start_date}
            variant="outlined"
            size="small"
          />
        )}
      />
      <DesktopDatePicker
        label="End date"
        inputFormat="DD/MM/YYYY"
        value={props.values.end_date}
        disabled={!!props.disabledInputs.end_date}
        onChange={props.onChangeEndDate}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={!!props.errors.end_date}
            helperText={props.errors.end_date}
            variant="outlined"
            size="small"
          />
        )}
      />
      <FormControl
        fullWidth
        size={'small'}
        error={!!props.errors.timezone}
        disabled={!!props.disabledInputs.timezone}>
        <InputLabel id="user-create-timezone-label">Timezone</InputLabel>
        <Select
          labelId="user-create-timezone-label"
          id="user-create-timezone"
          value={props.values.timezone}
          input={<OutlinedInput label="Timezone" error={!!props.errors.timezone} />}
          onChange={props.onChangeTimezone}>
          <MenuItem value={''}>Select timezone</MenuItem>
          {props.timezones.map((timezone) => (
            <MenuItem value={timezone.id} key={timezone.id}>
              {capitalizeFirstLetter(timezone.label)}
            </MenuItem>
          ))}
        </Select>
        {typeof props.errors.timezone !== 'undefined' && (
          <FormHelperText>{props.errors.timezone}</FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
}

BiddingForm.propTypes = {
  errors: PropTypes.shape({}),
  disabledInputs: PropTypes.shape({}),
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  timezones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  values: PropTypes.shape({
    end_date: PropTypes.string,
    file_code: PropTypes.string,
    timezone: PropTypes.string,
    client_anonymous: PropTypes.bool,
    name: PropTypes.string,
    company_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    start_date: PropTypes.string
  }),
  onChangeName: PropTypes.func,
  onChangeCompanyId: PropTypes.func,
  onChangeFileCode: PropTypes.func,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func,
  onChangeTimezone: PropTypes.func
};

export default BiddingForm;
