import axios from '../../../services/axios';

export function getCompanies(params = {}) {
  return axios.get('/api/dashboard/companies', params);
}

export function getCompany(id) {
  return axios.get(`/api/dashboard/companies/${id}`);
}

export function storeCompany(payload = {}) {
  return axios.post('/api/dashboard/companies', payload);
}

export function updateCompany(id, payload = {}) {
  return axios.post(`/api/dashboard/companies/${id}?_method=PUT`, payload);
}

export function deleteCompany(id) {
  return axios.delete(`/api/dashboard/companies/${id}`);
}

export function getCompanyBiddings(id, params = {}) {
  return axios.get(`/api/dashboard/companies/${id}/biddings`, params);
}

export function getCompanyUsers(id, params = {}) {
  return axios.get(`/api/dashboard/companies/${id}/users`, params);
}
