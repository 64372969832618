import { ReactComponent as download } from '../assets/icons/requirements/Download.svg';
import { ReactComponent as upload } from '../assets/icons/requirements/Upload.svg';
import { ReactComponent as sign } from '../assets/icons/requirements/Sign.svg';
import { Box } from '@mui/material';

const BidderRequirementIcon = ({ type }) => {
  const Icons = { download, upload, sign };
  const Icon = Icons[type] ?? Icons['info'];
  let sx = {};

  if (type === 'event_upcoming') {
    sx = { svg: { fill: '#BD5018' } };
  }

  return (
    <Box sx={sx}>
      <Icon />
    </Box>
  );
};
export default BidderRequirementIcon;
