import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Stack
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { authService } from '../../services/authService';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const NewPasswordModal = () => {
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const acceptHandle = (event) => {
    event.preventDefault();
    authService
      .newPassword({
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
        token: token
      })
      .then((response) => {
        console.log('res', response);
        showSuccessCreatedNewPasswordToast(response.data.status);
        navigate('/');
        handleOnClose();
      })
      .catch((error) => {
        console.log('error', error);
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  const handleOnClose = () => {
    setErrors({});
    setEmail('');
    setPassword('');
    setPasswordConfirmation('');
  };
  const onChangeEmail = (event) => setEmail(event.target.value);

  const showSuccessCreatedNewPasswordToast = (text) => {
    dispatch({
      type: 'SHOW',
      text: `${text}`,
      toast_type: 'success'
    });
  };

  if (open === false) {
    return '';
  }

  return (
    <Dialog
      open={true}
      keepMounted
      onClose={handleOnClose}
      aria-describedby="alert-dialog-slide-description">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          width: '535px'
        }}
        component="form"
        onSubmit={acceptHandle}>
        <DialogTitle>
          <Typography variant="modal-title" marginBottom={'10px'} gutterBottom>
            New Password
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              variant="outlined"
              error={errors.email}
              label="Email"
              size={'small'}
              value={email}
              onChange={onChangeEmail}
              helperText={errors.email}
            />
            <TextField
              fullWidth
              error={errors.password}
              label="Password"
              size={'small'}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              helperText={errors.password}
            />
            <TextField
              fullWidth
              error={errors.password_confirmation}
              size={'small'}
              label="Password confirmation"
              value={passwordConfirmation}
              onChange={(event) => setPasswordConfirmation(event.target.value)}
              helperText={errors.password_confirmation}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} type="submit">
            Save
          </Button>
          <Button variant={'fm-light'} onClick={handleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default NewPasswordModal;
