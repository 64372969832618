import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  Switch,
  TextField
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { requirementSelectionService } from '../../../../../../services/admin';
import { useDispatch } from 'react-redux';

const StoreBiddingDocument = ({
  open = false,
  subBidding = null,
  stage = null,
  onSuccess,
  onClose
}) => {
  const defaultDocument = {
    name: '',
    description: '',
    start_date: null,
    deadline: null,
    aperture_at: null,
    controlled_open: false,
    file: null,
    action: 'upload'
  };
  const [document, setDocument] = useState(defaultDocument);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const handleClose = () => {
    setErrors({});
    setDocument(defaultDocument);
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDocument((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangeFile = (e) => {
    const { name, files } = e.target;
    setDocument((prevState) => ({
      ...prevState,
      [name]: files[0]
    }));
  };

  const handleChangeActionButton = (value) => {
    if (value !== null) {
      setDocument((prevState) => ({
        ...prevState,
        action: value
      }));
    }
  };

  const handleChangeDeadline = (value) => {
    setDocument((prevState) => ({
      ...prevState,
      deadline: dayjs(value).format('YYYY-MM-DD HH:mm')
    }));
  };

  const handleChangeStartDate = (value) => {
    setDocument((prevState) => ({
      ...prevState,
      start_date: dayjs(value).format('YYYY-MM-DD HH:mm')
    }));
  };

  const handleChangeAperture = (value) => {
    setDocument((prevState) => ({
      ...prevState,
      aperture_at: dayjs(value).format('YYYY-MM-DD HH:mm')
    }));
  };

  const handleChangeSwitcher = (value) => {
    setDocument((prevState) => ({
      ...prevState,
      controlled_open: value
    }));
  };

  const storeDocument = (event) => {
    event.preventDefault();

    const formData = new FormData();
    if (document.action !== 'upload') {
      formData.append('file', document.file);
    }
    formData.append('name', document.name);
    formData.append('description', document.description);
    formData.append('start_date', document.start_date);
    formData.append('deadline', document.deadline);
    if (document.controlled_open) {
      formData.append('aperture_at', document.aperture_at);
    }
    formData.append('controlled_open', document.controlled_open ? 1 : 0);
    formData.append('action', document.action);

    requirementSelectionService(subBidding.id)
      .stage(stage)
      .biddingDocument.store(formData)
      .then((response) => {
        onSuccess(response);
        handleClose();
        showSuccessDocumentUploadToast();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  const showSuccessDocumentUploadToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'Document was successfully uploaded',
      toast_type: 'success'
    });
  };

  const renderFileInput = () => {
    if (document.action === 'upload') {
      return '';
    }

    return (
      <>
        <FormControl>
          <Button variant="fm-outlined" component="label">
            <FileUploadIcon />
            <input onChange={handleChangeFile} name="file" type="file" hidden />
            {document.file ? document.file.name : ''}
          </Button>
          <FormHelperText error>{errors.file}</FormHelperText>
        </FormControl>
      </>
    );
  };

  return (
    <Dialog open={open} keepMounted aria-describedby="alert-dialog-slide-description">
      <Box component="form" onSubmit={storeDocument} sx={{ width: '535px' }}>
        <DialogTitle>New requirement</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              variant="outlined"
              error={!!errors.name}
              label="Name"
              name="name"
              size={'small'}
              value={document.name}
              onChange={handleChange}
              helperText={errors.name}
            />
            <TextField
              fullWidth
              variant="outlined"
              error={!!errors.description}
              label="Description"
              name="description"
              size={'small'}
              value={document.description}
              onChange={handleChange}
              helperText={errors.description}
            />

            <DateTimePicker
              label="Start date"
              inputFormat="DD/MM/YYYY HH:mm"
              value={document.start_date}
              onChange={handleChangeStartDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!errors.start_date}
                  helperText={errors.start_date}
                  name="deadline"
                  variant="outlined"
                  size="small"
                />
              )}
            />

            <DateTimePicker
              label="Deadline"
              inputFormat="DD/MM/YYYY HH:mm"
              value={document.deadline}
              onChange={handleChangeDeadline}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!errors.deadline}
                  helperText={errors.deadline}
                  name="deadline"
                  variant="outlined"
                  size="small"
                />
              )}
            />

            <FormControlLabel
              control={<Switch name="controlled" color={'success'} />}
              label="Controlled Open"
              labelPlacement="start"
              sx={{ marginLeft: '0' }}
              onChange={(event) => handleChangeSwitcher(event.target.checked)}
            />

            {!!document.controlled_open && (
              <DateTimePicker
                label="Aperture at"
                inputFormat="DD/MM/YYYY HH:mm"
                value={document.aperture_at}
                onChange={handleChangeAperture}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!errors.aperture_at}
                    helperText={errors.aperture_at}
                    name="aperture_at"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            )}

            {renderFileInput()}
            <Stack spacing={2} direction={'row'} justifyContent={'center'}>
              <Button
                variant={'fm-mini'}
                onClick={() => handleChangeActionButton('upload')}
                disabled={document.action === 'upload'}>
                Upload
              </Button>
              <Button
                variant={'fm-mini'}
                onClick={() => handleChangeActionButton('download')}
                disabled={document.action === 'download'}>
                Download
              </Button>
              <Button
                variant={'fm-mini'}
                onClick={() => handleChangeActionButton('sign')}
                disabled={document.action === 'sign'}>
                Signature
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant={'fm-light'} type="submit">
            Accept
          </Button>
          <Button variant={'fm-light'} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default StoreBiddingDocument;
