import DeleteModal from '../../../../DeleteModal/DeleteModal';
import { useDispatch } from 'react-redux';
import { vendorService } from '../../../../../services/dashboard/vendorsService';

const DestroyNotificationModal = ({ question = null, onSuccess, onClose }) => {
  const dispatch = useDispatch();
  const handleSubmit = () => {
    vendorService.destroyQuestion(question.id).then((result) => {
      onSuccess(result);
      showSuccessScoreDeleteToast();
    });
  };

  const showSuccessScoreDeleteToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'Question has been deleted',
      toast_type: 'success'
    });
  };

  const handleClose = () => {
    onClose();
  };

  if (question === null) {
    return '';
  }

  return (
    <DeleteModal
      closeDeleteModal={handleClose}
      onDeleteHandle={() => handleSubmit()}
      open={true}
      text={`Are you sure you want to delete the question?`}
    />
  );
};

export default DestroyNotificationModal;
