import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { autoCompleteService, invitationsService } from '../../../../../services/admin';
import { useDispatch } from 'react-redux';

const AssignVendorModal = ({ open, bidding = [], excludedIds = [], onSuccess, onClose }) => {
  const [selectedVendor, setSelectedVendor] = useState('');
  const [selectedSubBiddings, setSelectedSubBiddings] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    autoCompleteService.vendors('', excludedIds).then((response) => {
      setVendorList(response.data);
    });
  }, [open]);

  const handleSubmit = (event) => {
    event.preventDefault();

    invitationsService(bidding.id)
      .assignVendorSubBiddings({
        vendor_id: selectedVendor,
        sub_bidding_ids: selectedSubBiddings
      })
      .then(() => {
        onSuccess();
        handleOnClose();
        showSuccessSentInvitationToast();
      });
  };

  const showSuccessSentInvitationToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'invitation has been added',
      toast_type: 'success'
    });
  };

  const handleOnClose = () => {
    onClose();
    setSelectedVendor([]);
    setSelectedSubBiddings([]);
  };

  if (open === false) {
    return '';
  }

  if (vendorList.length === 0) {
    return (
      <Dialog
        open={open}
        keepMounted
        onClose={handleOnClose}
        aria-describedby="alert-dialog-slide-description">
        <Box component="form" onSubmit={handleSubmit} sx={{ width: '535px' }}>
          <DialogTitle>Invitation</DialogTitle>
          <DialogContent>
            <Typography variant={'body1'}>All vendors invited</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant={'fm-light'} onClick={handleOnClose}>
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleOnClose}
      aria-describedby="alert-dialog-slide-description">
      <Box component="form" onSubmit={handleSubmit} sx={{ width: '535px' }}>
        <DialogTitle>Invitation</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <FormControl>
              <InputLabel id="vendor-input">Vendor</InputLabel>
              <Select
                labelId="vendor-input"
                value={selectedVendor}
                onChange={(event) => setSelectedVendor(event.target.value)}
                input={<OutlinedInput label="Vendors" />}>
                {vendorList.map((vendor) => (
                  <MenuItem key={vendor.id} value={vendor.id}>
                    {vendor.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="sub-biddings-input">Biddings</InputLabel>
              <Select
                labelId="sub-biddings-input"
                multiple
                value={selectedSubBiddings}
                onChange={(event) => {
                  const {
                    target: { value }
                  } = event;
                  setSelectedSubBiddings(typeof value === 'string' ? value.split(',') : value);
                }}
                input={<OutlinedInput label="Biddings" />}>
                {bidding.sub_biddings.map((subBidding) => (
                  <MenuItem key={subBidding.id} value={subBidding.id}>
                    {subBidding.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant={'fm-light'}
            disabled={selectedVendor.length === 0 || selectedSubBiddings.length === 0}
            type="submit">
            Assign
          </Button>
          <Button variant={'fm-light'} onClick={handleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AssignVendorModal;
