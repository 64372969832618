export function getColorForVendorModerationStatus(status) {
  if (status === 'approved') {
    return '#9CE7CC';
  }
  if (status === 'rejected') {
    return '#EB3800';
  }
  if (status === 'uploaded') {
    return '#464646';
  }

  return '#C4C4C4';
}
