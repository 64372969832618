import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicLayout from '../../../bidder/layouts/PublicLayout';

const Faq = () => {
  const qa = [
    {
      question: '¿Qué es Bidder®?',
      answer:
        'El proceso de licitaciones generalmente implica la presentación de una propuesta u oferta de una empresa a un cliente en respuesta a una solicitud de propuesta (RFP), una solicitud de información, y/o una solicitud de cotización. Cada propuesta y requerimientos serán diferentes dependiendo de las necesidades del cliente. FMHOUSE® a través de su herramienta Bidder® revisará las propuestas y seleccionará un postor ganador en función a una evaluación detallada de varios factores, desarrollados para cada licitación.'
    },
    {
      question:
        '¿Cómo funciona el proceso de licitación bajo la metodología de las 3R (RFI, RFP y RFQ) utilizado en Bidder®?',
      answer:
        'Las 3R son las siglas de las tres etapas del proceso de licitación: Request for Information (RFI), Request for Proposal (RFP) y Request for Quotation (RFQ). \n' +
        'En la primera etapa, la RFI, entre otros, se solicita información sobre la propia compañia, los productos y/o servicios que se ofrecen, así como la capacidad de proveer el servicio objeto de licitación. Esta etapa permite seleccionar a los proveedores de servicios que mejor se adaptan a las condiciones y necesidades del servicio.\n' +
        'En la segunda etapa, la RFP, se solicita una propuesta detallada sobre cómo se proponen proveer los servicios en base al nivel de servicio y condiciones definidas por el cliente, incluyendo, entre otros, información sobre recursos, procedimientos, metodología, reporting, etc.\n' +
        'En la última etapa, la RFQ, se solicita una propuesta economica para la provisión de los servicios ofertados. Esta etapa, junto con los resultados de las anteriores, permite garantizar la selección del proveedor que mejor se adapta al servicio y con el mejor equilibrio entre la propuesta técnica y economica.\n' +
        'En resumen, el proceso de licitación de las 3R es un proceso estructurado para la adquisición de productos y/o servicios. Está compuesto por tres etapas en las que cada una de las cuales ofrece una información específica para ayudar al comprador a tomar la mejor decisión.'
    },
    {
      question: '¿Cómo sé si mi documento ha sido subido/presentado correctamente?',
      answer:
        'Cuando se sube o firma un documento, queda registrada su carga en la linea del propio requerimiento indicandose la fecha de carga, el nombre del archivo subido y el mensaje "uploaded" que confirma que la carga se ha realizado correctamente. Si no ve dicha información quiere decir que el archivo no se ha subido correctamente, por lo que debera volver a intentarlo hasta que aparezca. Una vez subido el archivo adecuadamente, este quedará pendiente de revisión y aceptación por parte de los gestores de la licitación. '
    },
    {
      question: '¿Cómo sé si mi documento fue aceptado?',
      answer:
        'Una vez se ha subido correctamente el archivo en cuestion, los gestores de la licitación se encargarán de revisar si el archivo subido es correcto y validarlo. En el caso de que este sea aceptado, aparecerá el mensaje "Accepted". En el caso de que este haya sido rechazado, recibirá un correo electronico notificandoselo e indicandole el motivo del rechazo y qué debe hacer para corregirlo. Una vez corregido, deberá volver a subir el archivo para ser nuevamente revisado.'
    },
    {
      question: '¿Puedo modificar mis propuestas despues de que estas hayan sido aceptadas?',
      answer:
        'Por lo general, no es posible modificar las propuestas después de que se hayan aceptado, a no ser que sea requerido por parte de los gestores de la licitación. Si tiene dudas sobre cualquier aspecto relacionado con el proceso, es importante plantearlas antes de presentar una propuesta, para ello todas las fases del proceso disponen de un apartado de preguntas y respuestas (Q&A) en cada etapa del proceso de licitación.'
    },
    {
      question: '¿Cómo sé si he sido seleccionado para la siguiente fase (RFP ó RFQ)?',
      answer:
        'Una vez evaluados los documentos presentados por cada proveedor y validadas las valoraciones por parte del cliente, los gestores de la licitación se encargarán de notificar al proveedor su resultado. En el caso de ser seleccionado para participar en la siguiente fase  esta quedará desbloqueada pudiendo acceder a la información y contenido de esta. En el caso de no ser seleccionado, el acceso a la licitación quedará inhabilitado.'
    },
    {
      question: '¿Puedo retirar una oferta o documento que ya he presentado?',
      answer:
        'De manera general, no es posible retirar una oferta o documento una vez que se ha presentado y finalizado el plazo de presentación. Sin embargo, si hay errores significativos o se considera que el documento presentado no cumple con los requerimientos, se puede solicitar una revisión del mismo. Es importante revisar cada documento y requerimiento detenidamente para asegurarse de que se comprenden los requisitos y términos de lo solicitado. En todo caso, siempre y cuando no haya finalizado el plazo de presentación, se pueden reemplazar todos y cada uno de los documentos presentados tantas veces como se desee, quedando registrada en la plataforma toda la actividad al respecto para su posterior certificación.'
    },
    {
      question: '¿Cómo sé si existe un nuevo requerimiento o notificación?',
      answer:
        'En el caso de existir un nuevo requerimiento o notificación, este le será comunicado a través del correo electronico con el que se ha registrado y que esta vinculado al proceso de licitación. En el apartado de notificaciones puede llevar un control de las notificaciones ya leidas clicando en el botón "Read".'
    },
    {
      question: '¿Puedo acceder a la información de una fase finalizada?',
      answer:
        'No, una vez se finaliza una fase, independientemente de si el participante ha resultado seleccionado para la siguiente, no se puede acceder a la información de fases anteriores.'
    },
    {
      question:
        '¿Puedo acceder a la información una vez ha finalizado la licitación o mi participación en esta? ',
      answer:
        'No, una vez se ha finalizado la participación en la licitacion o esta ha finalizado, no se puede acceder a la información de esta. '
    },
    {
      question:
        '¿Cómo puedo comunicarme con el equipo de gestión de la licitación si tengo un problema con la plataforma?',
      answer:
        'Si tiene cualquier duda técnica referente al funcionamiento de Bidder® puede comunicarse con el equipo de gestión de la licitación a través del siguiente correo electronico: licitaciones@fm-house.com '
    },
    {
      question:
        '¿Cómo puedo comunicarme con el equipo de gestión de la licitación si tengo una duda sobre el proceso de licitación?',
      answer:
        'En el caso de que tenga una duda referente al proceso de licitación, deberá ir a la pantalla de la fase en la que se encuentra y en el apartado "Q&A" clicar en "Ask a question" y escribir su consulta y enviarla en el menu emergente que aparece. Recordar que todas las consultas realizadas sobre el proceso de licitación serán compartidas con todos los participantes para participar en igualdad de condiciones.'
    },
    {
      question: '¿Cómo protejo mi propiedad intelectual durante el proceso?',
      answer:
        'Bidder® es una herramienta diseñada para cuidar cada punto del proceso de desarrollo de una licitación, es por esto que está diseñada para proteger la información compartida por cada licitante. Además al inicio de cualquier licitación se firma un NDA entre las partes con el que se protege de manera legal la información compartida entre estas.'
    }
  ];

  return (
    <PublicLayout title={'FAQ'}>
      {qa.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" gutterBottom component="p">
              {item.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </PublicLayout>
  );
};
export default Faq;
