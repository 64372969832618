import { useNavigate, useParams, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { vendorService } from '../../../services/dashboard/vendorsService';
import BiddingSelector from '../../../bidder/components/Selectors/BiddingSelector';
import { Loading } from '../../../components/Loading/Loading';
import {
  StageTab,
  StageTabPanel,
  StageTabs,
  StageTabsList
} from '../../../components/FMHouse/StageTabs';
import { formatDateTimeWithTimezone } from '../../../services/date';
import VendorForm from '../../../bidder/components/Dashboard/Vendor/VendorForm';
import RequirementsReviewModal from '../../../components/Dashboard/Vendors/Show/Modals/RequirementsReviewModal';
import OutlinedButtonsWrapper from '../../../components/FMHouse/OutlinedButtonsWrapper';
import AnswerQuestionModal from '../../../components/Dashboard/Vendors/Show/Modals/AnswerQuestionModal';
import AccessConfirmation from '../../../components/Dashboard/Vendors/AccessConfirmation';
import PageWrapper from '../../../bidder/components/Main/PageWrapper';
import Header from '../../../bidder/components/Main/Header';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import Content from '../../../bidder/components/Main/Content';
import ClientQATable from '../../../bidder/components/Client/ClientQATable';
import DestroyQuestionModal from '../../../components/Dashboard/Vendors/Show/Modals/DestroyQuestionModal';
import TableHeader from '../../../bidder/components/Table/TableHeader';
import Table from '../../../bidder/components/Table/Table';
import TableBody from '../../../bidder/components/Table/TableBody';
import VendorStatusNotificationsPanel from '../../../bidder/components/Dashboard/Vendor/VendorStatusNotificationsPanel';
import { cutString } from '../../../bidder/helpers/string-helper';
import NewActivityLog from '../../../bidder/components/Dashboard/Vendor/ActivityLog';

export default function VendorDetails() {
  const { id, bidding = null, stage = null } = useParams();
  const navigate = useNavigate();
  const [vendor, setVendor] = useState(null);
  const [activeMacroBidding, setActiveMacroBidding] = useState(null);
  const [activeBidding, setActiveBidding] = useState(null);
  const [selectedQA, setSelectedQA] = useState(null);

  const [activeStage, setActiveStage] = useState(null);
  const [stageData, setStageData] = useState(null);

  const [questionToAnswer, setQuestionToAnswer] = useState(null);
  const [questionToDestroy, setQuestionToDestroy] = useState(null);
  const [requirementToChangeStatus, setRequirementToChangeStatus] = useState(null);
  const [statusOfRequirementToChangeStatus, setStatusOfRequirementToChangeStatus] = useState(null);

  const fetchStageData = () => {
    vendorService
      .showStage(id, activeBidding.id, activeStage)
      .then((response) => setStageData(response.data.data));
  };

  useEffect(() => {
    vendorService.show(id).then((response) => setVendor(response.data.data));
  }, [id]);

  useEffect(() => {
    if (vendor !== null) {
      if (bidding === null) {
        setActiveMacroBidding(vendor.macro_biddings[0] ?? null);
        setActiveBidding(
          vendor.biddings.filter((x) => (x.parent_id = vendor.macro_biddings[0].id))[0] ?? null
        );
      } else {
        let childBidding = vendor.biddings.find((item) => parseInt(item.id) === parseInt(bidding));
        setActiveBidding(childBidding);
        console.log(
          vendor.macro_biddings.find(
            (item) => parseInt(item.id) === parseInt(childBidding.parent_id)
          )
        );
        console.log(childBidding);
        setActiveMacroBidding(
          vendor.macro_biddings.find(
            (item) => parseInt(item.id) === parseInt(childBidding.parent_id)
          )
        );
      }
    }
  }, [vendor]);

  useEffect(() => {
    if (vendor !== null) {
      setActiveStage(null);
      setStageData(null);
      setActiveBidding(null);
      if (bidding === null) {
        setActiveBidding(vendor.biddings[0] ?? null);
      } else {
        setActiveBidding(vendor.biddings.find((item) => parseInt(item.id) === parseInt(bidding)));
      }
    }
  }, [bidding]);

  useEffect(() => {
    if (activeBidding !== null) {
      if (stage !== null) {
        setActiveStage(stage);
      } else {
        setActiveStage(activeBidding?.stage);
      }
    }
  }, [activeBidding]);

  useEffect(() => {
    if (activeBidding !== null && activeStage !== null) {
      vendorService
        .showStage(id, activeBidding.id, activeStage)
        .then((response) => setStageData(response.data.data));
    }
  }, [activeBidding, activeStage]);

  return (
    <>
      <PageWrapper>
        <Header title={`Vendor profile: ${vendor?.name}`} />
        <Content>
          {vendor === null ? (
            <Loading />
          ) : (
            <>
              <BidderPanel title={'User Vendor Information'}>
                <VendorForm vendor={vendor} />
              </BidderPanel>
              <Grid container spacing={2} sx={{ marginTop: '100px' }}>
                <Grid item xs={3}>
                  {vendor.macro_biddings.length === 0 ? (
                    <Typography variant="body1" fontWeight={'bold'}>
                      No active macro biddings
                    </Typography>
                  ) : (
                    <BiddingSelector
                      biddings={vendor.macro_biddings}
                      onClick={(item) => setActiveMacroBidding(item)}
                      currentId={activeMacroBidding?.id ?? null}
                      buttonOptions={{ flex: '0 0 calc(50% - 12px)' }}
                    />
                  )}
                </Grid>
                <Grid item xs={9}>
                  {vendor.biddings.length === 0 ? (
                    <Typography variant="body1" fontWeight={'bold'}>
                      No active sub biddings
                    </Typography>
                  ) : (
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {vendor.biddings
                          .filter((item) => item.parent_id === activeMacroBidding?.id)
                          .map((item) => (
                            <button
                              key={item.id}
                              style={{ flex: '0 0 calc(25% - 30px)' }}
                              onClick={() => navigate(`/dashboard/vendors/${id}/${item.id}`)}
                              className={
                                `bidder-big-button__button` +
                                (activeBidding?.id === item.id ? ' active' : '')
                              }
                              title={item.name}>
                              {cutString(item.name, 20)}
                            </button>
                          ))}
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>
              {activeBidding !== null ? (
                <>
                  <AccessConfirmation
                    vendorId={id}
                    biddingId={activeBidding.id}
                    stageData={stageData}
                    onUpdate={() => {
                      vendorService
                        .showStage(id, activeBidding.id, activeStage)
                        .then((response) => setStageData(response.data.data));
                    }}
                  />
                  <br />
                  <br />
                  <StageTabs
                    defaultValue={activeBidding.stage}
                    value={activeStage}
                    onChange={(event, value) => setActiveStage(value)}>
                    <StageTabsList>
                      {activeBidding.stages.map((stage) => (
                        <StageTab stage={stage.type} key={stage.type} />
                      ))}
                    </StageTabsList>
                    {activeBidding.stages.map((item) => (
                      <StageTabPanel value={item.type} key={item.type}>
                        {stageData !== null && activeStage === item.type && (
                          <Stack spacing={2}>
                            <BidderPanel title={'Requirements review'}>
                              <Table>
                                <TableHeader>
                                  <tr>
                                    <th className={'align-left-column'}>Requirement</th>
                                    <th>Date</th>
                                    <th>Actions</th>
                                  </tr>
                                </TableHeader>
                                <TableBody>
                                  {stageData.requirements.map((requirement) => (
                                    <tr key={requirement.id}>
                                      <td className={'align-left-column'}>
                                        <Typography
                                          variant={'body1'}
                                          fontWeight={'bold'}
                                          color={'#40464C'}>
                                          {requirement.name}
                                        </Typography>
                                        <Typography variant={'body2'} color={'#40464C'}>
                                          {requirement.file_code}
                                        </Typography>
                                        <Typography variant={'body2'} color={'#40464C'}>
                                          {requirement.description}
                                        </Typography>
                                      </td>
                                      <td>
                                        {requirement.timestamp !== null &&
                                          formatDateTimeWithTimezone(
                                            requirement.timestamp,
                                            requirement.timezone
                                          )}
                                      </td>
                                      <td>
                                        <Stack
                                          direction={'row'}
                                          spacing={1}
                                          justifyContent={'center'}>
                                          <Button
                                            target={'_blank'}
                                            href={requirement.download_link}
                                            variant={'fm-light-outlined'}
                                            disabled={!requirement.download_link}>
                                            Download
                                          </Button>
                                          <Button
                                            target={'_blank'}
                                            href={requirement.view_link}
                                            variant={'fm-light-outlined'}
                                            disabled={!requirement.view_link}>
                                            View
                                          </Button>
                                          <Button
                                            variant={'fm-light-outlined'}
                                            onClick={() => {
                                              setRequirementToChangeStatus(requirement);
                                              setStatusOfRequirementToChangeStatus('accepted');
                                            }}
                                            disabled={
                                              requirement.has_file === false ||
                                              requirement.condition === 'accepted'
                                            }>
                                            Accept
                                          </Button>
                                          <Button
                                            variant={'fm-light-outlined'}
                                            onClick={() => {
                                              setRequirementToChangeStatus(requirement);
                                              setStatusOfRequirementToChangeStatus('rejected');
                                            }}
                                            disabled={
                                              requirement.has_file === false ||
                                              requirement.condition === 'rejected'
                                            }>
                                            Reject
                                          </Button>
                                        </Stack>
                                      </td>
                                    </tr>
                                  ))}
                                </TableBody>
                              </Table>
                              <RequirementsReviewModal
                                requirement={requirementToChangeStatus}
                                status={statusOfRequirementToChangeStatus}
                                onClose={() => {
                                  setRequirementToChangeStatus(null);
                                  setStatusOfRequirementToChangeStatus(null);
                                }}
                                onSuccess={() => {
                                  vendorService
                                    .showStage(id, activeBidding.id, activeStage)
                                    .then((response) => setStageData(response.data.data));
                                  setRequirementToChangeStatus(null);
                                  setStatusOfRequirementToChangeStatus(null);
                                }}
                              />
                            </BidderPanel>
                            <BidderPanel title={'Activity log'}>
                              <NewActivityLog items={stageData.activity_log} />
                              <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                spacing={3}>
                                <Button
                                  variant={'fm-outlined'}
                                  target={'_blank'}
                                  href={stageData.activity_log_download}>
                                  Download activity log
                                </Button>
                              </Stack>
                            </BidderPanel>
                            {/*
                            <BidderPanel title={'Activity log'}>
                              <ActivityLog activity={stageData.activity} />
                            </BidderPanel>*/}
                            <BidderPanel title={'Vendor status of notifications'}>
                              <VendorStatusNotificationsPanel
                                notifications={stageData.notifications}
                              />
                            </BidderPanel>
                            <BidderPanel title={'Message center'}>
                              <ClientQATable
                                questions={stageData.questions}
                                onRowClick={(question) => setSelectedQA(question)}
                              />
                              <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                spacing={3}>
                                {selectedQA !== null && (
                                  <>
                                    <Button
                                      variant={'fm-outlined'}
                                      onClick={() => setQuestionToAnswer(selectedQA)}>
                                      Answer & edit
                                    </Button>
                                    <Button
                                      variant={'fm-outlined'}
                                      onClick={() => setQuestionToDestroy(selectedQA)}>
                                      Delete
                                    </Button>
                                  </>
                                )}
                              </Stack>
                              <AnswerQuestionModal
                                question={questionToAnswer}
                                onClose={() => {
                                  setQuestionToAnswer(null);
                                }}
                                onSuccess={() => {
                                  fetchStageData();
                                  setQuestionToAnswer(null);
                                }}
                                onError={() => {
                                  console.log('error');
                                }}
                              />
                              <DestroyQuestionModal
                                question={questionToDestroy}
                                onClose={() => {
                                  setQuestionToDestroy(null);
                                }}
                                onSuccess={() => {
                                  fetchStageData();
                                  setQuestionToDestroy(null);
                                }}
                                onError={() => {
                                  console.log('error');
                                }}
                              />
                            </BidderPanel>
                          </Stack>
                        )}
                      </StageTabPanel>
                    ))}
                  </StageTabs>
                </>
              ) : (
                ''
              )}
            </>
          )}
          <OutlinedButtonsWrapper>
            <Button href={`mailto:${vendor?.email}`} variant={'fm-outlined'} size={'wide'}>
              Contact
            </Button>
            <Button
              component={Link}
              to={'/dashboard/vendors'}
              variant={'fm-outlined'}
              size={'wide'}>
              Cancel
            </Button>
          </OutlinedButtonsWrapper>
        </Content>
      </PageWrapper>
    </>
  );
}
