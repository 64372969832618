import { Route, Routes, BrowserRouter } from 'react-router-dom';
import LogIn from '../../pages/general/LogIn/LogIn';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { ThemeProvider } from '@mui/material/styles';
import Profile from '../../pages/general/Profile';
import { FMHouse } from '../../theme/FMHouse';
import MainAboutUs from '../../pages/general/AboutUs/AboutUs';
import MainFaq from '../../pages/general/Faq/Faq';
import MainInstructions from '../../pages/general/Instructions/Instructions';
import MainContactUs from '../../pages/general/ContactUs/ContactUs';
import Dashboard from '../../pages/admin/Dashboard';
import DashboardVendors from '../../pages/admin/Vendors/Vendors';
import DashboardUsers from '../../pages/admin/Users/Users';
import DashboardBiddings from '../../pages/admin/Biddings/Biddings';
import DashboardBiddingSetupBiddingStructure from '../../pages/admin/Biddings/Setup/BiddingStructure';
import DashboardBiddingSetupScopeAndDescription from '../../pages/admin/Biddings/Setup/ScopeAndDescription';
import DashboardBiddingSetupVendorInvitations from '../../pages/admin/Biddings/Setup/VendorInvitations';
import BiddingDashboard from '../../pages/admin/Biddings/BiddingDashboard';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import BiddingReview from '../../pages/admin/Biddings/BiddingReview';
import VendorBiddings from '../../pages/vendor/Biddings/Biddings';
import VendorBiddingDetails from '../../pages/vendor/Biddings/BiddingDetails';
import VendorBiddingAccept from '../../pages/vendor/Biddings/BiddingAccept';
import ClientBiddings from '../../pages/client/Biddings/Biddings';
import ClientBiddingDetails from '../../pages/client/Biddings/BiddingDetails';
import ErrorPage from '../../pages/ErrorPage';
import * as React from 'react';
import { Toast } from '../SnackBar/Toast/Toast';
import AuthLink from '../../pages/general/AuthLink/AuthLink';
import NewPasswordModal from '../NewUserPassword/NewPassword';
import Requirements from '../../pages/admin/Biddings/Setup/Requirements';
import DashboardVendorDetails from '../../pages/admin/Vendors/VendorDetails';
import dayjs from 'dayjs';
import List from '../../pages/admin/Companies/List';
import Show from '../../pages/admin/Companies/Show';
import Create from '../../pages/admin/Companies/Create';
import Activity from '../../pages/admin/Users/Activity';
import ActivityLogsList from '../../pages/admin/Additional/ActivityLogs/List';
import FailedJobsList from '../../pages/admin/Additional/FailedJobs/List';

var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={FMHouse}>
            <BrowserRouter>
              <Routes>
                <Route path={'/login'} element={<LogIn />} exact />
                <Route path="/dashboard">
                  <Route index={true} element={<Dashboard />} />
                  <Route path="biddings">
                    <Route index={true} element={<DashboardBiddings />} />

                    <Route path=":id">
                      <Route index={true} element={<BiddingDashboard />} />
                      <Route path="dashboard">
                        <Route path=":bidding" element={<BiddingDashboard />} />
                        <Route path=":bidding/:stage" element={<BiddingDashboard />} />
                      </Route>

                      <Route path="access-section/:stage" element={<BiddingReview />} />
                      <Route path="setup">
                        <Route
                          path="bidding-structure"
                          element={<DashboardBiddingSetupBiddingStructure />}
                        />
                        <Route path="requirements" element={<Requirements />} />
                        <Route
                          path="scope-and-description"
                          element={<DashboardBiddingSetupScopeAndDescription />}
                        />
                        <Route
                          path="vendor-invitations"
                          element={<DashboardBiddingSetupVendorInvitations />}
                        />
                      </Route>
                    </Route>
                  </Route>

                  <Route path="users">
                    <Route index={true} element={<DashboardUsers />} />
                    <Route path=":id/activity" element={<Activity />} />
                  </Route>

                  <Route path="vendors">
                    <Route index={true} element={<DashboardVendors />} />
                    <Route path=":id">
                      <Route index={true} element={<DashboardVendorDetails />} />
                      <Route path=":bidding" element={<DashboardVendorDetails />} />
                      <Route path=":bidding/:stage" element={<DashboardVendorDetails />} />
                    </Route>
                  </Route>
                  <Route path="companies">
                    <Route index={true} element={<List />} />
                    <Route path={'create'} element={<Create />} />
                    <Route path=":id" element={<Show />} />
                  </Route>
                  <Route path="additional/failed-jobs">
                    <Route index={true} element={<FailedJobsList />} />
                  </Route>
                  <Route path="additional/activity-logs">
                    <Route index={true} element={<ActivityLogsList />} />
                  </Route>
                </Route>

                <Route path="/client">
                  <Route path="biddings">
                    <Route index={true} element={<ClientBiddings />} />
                    <Route path=":id" element={<ClientBiddingDetails />} />
                  </Route>
                </Route>

                <Route path="vendor">
                  <Route path="biddings">
                    <Route index={true} element={<VendorBiddings />} />
                    <Route path=":id/accept/:document" element={<VendorBiddingAccept />} />
                    <Route path=":id" element={<VendorBiddingDetails />} />
                  </Route>
                  <Route path="auth-link/:link" element={<AuthLink />} />
                </Route>

                <Route path="auth-link/:link" element={<AuthLink />} />
                <Route path="/profile/edit" element={<Profile />} exact />

                <Route path="password-reset/:token" element={<NewPasswordModal />} />

                <Route path={'/about-us'} element={<MainAboutUs />} />
                <Route path={'/faq'} element={<MainFaq />} />
                <Route path={'/instructions'} element={<MainInstructions />} />
                <Route path={'/contact-us'} element={<MainContactUs />} />

                <Route path="/" element={<LogIn />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </LocalizationProvider>
      </ErrorBoundary>
      <Toast></Toast>
    </div>
  );
}

export default App;
