import axios from './axios';

export const profileService = {
  index: () => {
    return axios.get(`api/profile`);
  },
  update: (data) => {
    return axios.post(`api/profile`, data);
  }
};
