import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import { Loading } from '../../../../Loading/Loading';
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import DestroyBiddingDocumentModal from './Modals/DestroyBiddingDocumentModal';
import StoreBiddingDocumentModal from './Modals/StoreBiddingDocumentModal';
import UpdateBiddingDocumentModal from './Modals/UpdateBiddingDocumentModal';
import { formatDateTime } from '../../../../../services/date';
import BidderPanel from '../../../../../bidder/components/Panels/BidderPanel';
import BidderRequirementIcon from '../../../../../bidder/components/BidderRequirementIcon';

const DocumentsArea = ({
  readOnly = true,
  subBidding = null,
  stage = null,
  documents = null,
  onUpdateEvent
}) => {
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [documentsToDestroy, setDocumentsToDestroy] = useState([]);
  const [documentToUpdate, setDocumentToUpdate] = useState(null);
  const [showStoreModal, setShowStoreModal] = useState(false);

  const selectedService = {
    isChecked: (id) => {
      return selectedDocuments.indexOf(id) !== -1;
    },
    toggle: (id) => {
      let selected = [...selectedDocuments];
      let index = selected.indexOf(id);
      if (index === -1) {
        selected.push(id);
      } else {
        selected.splice(index, 1);
      }
      setSelectedDocuments(selected);
    },
    clear: () => {
      setSelectedDocuments([]);
    }
  };

  const handleDeleteRequirement = () => {
    console.log(documents.filter((document) => selectedService.isChecked(document.id)));
    setDocumentsToDestroy(documents.filter((document) => selectedService.isChecked(document.id)));
  };

  if (documents === null) {
    return <Loading />;
  }

  return (
    <>
      <BidderPanel title={'Standard Requirements Selection'}>
        <Stack spacing={2}>
          <BidderPanel>
            <List sx={{ maxHeight: '600px', boxSizing: 'border-box', overflowY: 'overlay' }}>
              {documents.map((document) => {
                return (
                  <ListItem
                    key={document.id}
                    disablePadding
                    onClick={() => {
                      selectedService.toggle(document.id);
                    }}>
                    <ListItemButton sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      {readOnly ? (
                        ''
                      ) : (
                        <Checkbox
                          onChange={() => selectedService.toggle(document.id)}
                          checked={selectedService.isChecked(document.id)}
                        />
                      )}
                      <Box sx={{ margin: '0 15px' }}>
                        <IconButton aria-label="comments">
                          <BidderRequirementIcon type={document.action} />
                        </IconButton>
                      </Box>
                      <Box sx={{ flex: '0 1 auto', width: '100%' }}>
                        <Typography variant="modalTitle">{document.name}</Typography>
                        <Box>
                          {document.deadline && (
                            <Typography variant="GreyText16_400">
                              Deadline: {formatDateTime(document.deadline)}
                            </Typography>
                          )}
                        </Box>
                        <Typography variant="GreyText16_400">{document.description}</Typography>
                      </Box>
                      <IconButton
                        disabled={readOnly}
                        onClick={(event) => {
                          event.stopPropagation();
                          setDocumentToUpdate(document);
                        }}>
                        <AppRegistrationRoundedIcon fontSize="large" />
                      </IconButton>
                    </ListItemButton>
                  </ListItem>
                );
              })}
              {documents.length === 0 ? (
                <ListItem key={0} disablePadding>
                  <Typography variant={'body2'}>Requirements not exist</Typography>
                </ListItem>
              ) : (
                ''
              )}
            </List>
          </BidderPanel>
          {readOnly ? (
            ''
          ) : (
            <BidderPanel>
              <Stack direction={'row'} spacing={2} justifyContent={'center'}>
                <Button
                  disabled={readOnly}
                  variant="fm-outlined"
                  size={'small'}
                  onClick={() => setShowStoreModal(true)}>
                  New requirement
                </Button>
                <Button
                  variant="fm-outlined"
                  size={'small'}
                  disabled={selectedDocuments.length !== 1 || readOnly}
                  onClick={() => {
                    let selectedId = selectedDocuments.pop();
                    let indexOfDocument = documents.findIndex(
                      (document) => document.id === selectedId
                    );
                    setDocumentToUpdate(documents[indexOfDocument]);
                  }}>
                  Edit requirement
                </Button>
                <Button
                  variant="fm-outlined"
                  size={'small'}
                  onClick={() => handleDeleteRequirement()}
                  disabled={selectedDocuments.length === 0 || readOnly}>
                  Delete requirement
                </Button>
              </Stack>
            </BidderPanel>
          )}
        </Stack>
      </BidderPanel>
      <UpdateBiddingDocumentModal
        subBidding={subBidding}
        stage={stage}
        document={documentToUpdate}
        onSuccess={() => {
          onUpdateEvent();
          setDocumentToUpdate(null);
        }}
        onClose={() => setDocumentToUpdate(null)}
      />
      <StoreBiddingDocumentModal
        open={showStoreModal}
        subBidding={subBidding}
        stage={stage}
        onSuccess={() => {
          onUpdateEvent();
          setShowStoreModal(false);
        }}
        onClose={() => setShowStoreModal(false)}
      />
      <DestroyBiddingDocumentModal
        subBidding={subBidding}
        stage={stage}
        documents={documentsToDestroy}
        onSuccess={() => {
          onUpdateEvent();
          setDocumentsToDestroy([]);
          selectedService.clear();
        }}
        onClose={() => setDocumentsToDestroy([])}
      />
    </>
  );
};

export default DocumentsArea;
