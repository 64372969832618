import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Loading } from '../../../components/Loading/Loading';
import BiddingsTable from '../../../components/Dashboard/Biddings/BiddingsTable';
import { biddingService } from '../../../services/admin';
import DetailsBiddingModal from '../../../components/Dashboard/Biddings/Modals/DetailsBiddingModal';
import CreateBiddingModal from '../../../components/Dashboard/Biddings/Modals/CreateBiddingModal';
import EditBiddingModal from '../../../components/Dashboard/Biddings/Modals/EditBiddingModal';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { useDispatch } from 'react-redux';

import SearchInput from '../../../components/SearchInput/SearchInput';
import PageWrapper from '../../../bidder/components/Main/PageWrapper';
import Header from '../../../bidder/components/Main/Header';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import Content from '../../../bidder/components/Main/Content';
import TablePagination from '@mui/material/TablePagination';

const Biddings = () => {
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [biddingToEdit, setBiddingToEdit] = useState(null);
  const [biddingDetailActions, setBiddingDetailActions] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [checkedlist, setCheckedList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchBiddings = () => {
    biddingService
      .index({
        params: {
          filter: {
            status: status,
            search
          },
          per_page: rowsPerPage,
          page: page + 1
        }
      })
      .then((res) => {
        setData(res.data);
      });
  };

  useEffect(() => {
    setCheckedList([]);
    fetchBiddings();
  }, [search, rowsPerPage, page]);

  const onAction = (action, bidding) => {
    switch (action) {
      case 'details':
        setBiddingDetailActions(bidding);
        break;
      case 'edit':
        setBiddingToEdit(bidding);
        break;
    }
  };

  const onEditBiddingClick = () => {
    if (checkedlist.length === 1) {
      let index = data.data.find((bidding) => bidding.id === checkedlist[0]);
      if (index === undefined) {
        let biddings = data.data.map((bidding) => bidding.biddings).flat();
        index = biddings.find((bidding) => bidding.id === checkedlist[0]);
      }
      setBiddingDetailActions(index);
    }
  };

  const onDeleteBiddingClick = () => {
    if (checkedlist.length === 0) {
      return false;
    } else {
      setOpenDeleteModal(true);
    }
  };

  useEffect(() => {
    fetchBiddings();
  }, [search, status]);
  const renderHeaderContentCallback = () => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '45px',
            padding: '0 10px'
          }}>
          <Box sx={{ width: '520px' }}>
            <SearchInput value={search} onChange={(event) => setSearch(event.target.value)} />
          </Box>
          <Stack direction="row" spacing={1}>
            <Button variant={'fm-action'} onClick={() => setShowCreateModal(true)}>
              New bidding
            </Button>
            <Button
              variant={'fm-action'}
              onClick={() => onEditBiddingClick()}
              disabled={checkedlist.length !== 1}>
              Edit bidding
            </Button>
            <Button
              variant={'fm-action'}
              onClick={() => onDeleteBiddingClick()}
              disabled={checkedlist.length === 0}>
              Delete biddings
            </Button>
          </Stack>
        </Box>
      </>
    );
  };

  const onDeleteHandle = () => {
    setOpenDeleteModal(false);
    biddingService
      .destroyMany({
        ids: checkedlist
      })
      .then(() => {
        dispatch({
          type: 'SHOW',
          text: `${checkedlist.length > 1 ? 'Biddings' : 'Bidding'} has been successfully removed`,
          toast_type: 'success'
        });
        fetchBiddings();
      })
      .catch((err) => console.log('error', err));
  };

  const showSuccessfullyCreatedToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'Bidding was successfully created',
      toast_type: 'success'
    });
  };

  const onCheckRow = (id, checked = true) => {
    if (checked) {
      if (!checkedlist.includes(id)) {
        setCheckedList((prevState) => [id, ...prevState]);
      }
    } else {
      if (checkedlist.includes(id)) {
        let newSelectedList = checkedlist.filter((value) => value !== id);
        setCheckedList(newSelectedList);
      }
    }
  };

  return (
    <>
      <PageWrapper>
        <Header title="Admin Panel" renderHeaderContentCallback={renderHeaderContentCallback} />
        <Content>
          <BidderPanel>
            <ToggleButtonGroup
              color="primary"
              value={status}
              exclusive
              onChange={(event, newStatus) => {
                setPage(0);
                setStatus(newStatus);
              }}
              size="small"
              aria-label="Filter by status">
              <ToggleButton value="">All</ToggleButton>
              <ToggleButton value="active">Active</ToggleButton>
              <ToggleButton value="inactive">Inactive</ToggleButton>
              <ToggleButton value="complete">Complete</ToggleButton>
              <ToggleButton value="design">Design</ToggleButton>
            </ToggleButtonGroup>
            {data === null ? (
              <Loading />
            ) : (
              <BiddingsTable
                data={data.data}
                onAction={onAction}
                selected={checkedlist}
                onCheckRow={onCheckRow}
              />
            )}
            {data !== null && (
              <TablePagination
                rowsPerPageOptions={[5, 25, 100]}
                component="div"
                count={data.meta.total}
                rowsPerPage={data.meta.per_page}
                page={data.meta.current_page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </BidderPanel>
        </Content>
      </PageWrapper>
      <EditBiddingModal
        bidding={biddingToEdit}
        onSuccess={() => fetchBiddings()}
        onClose={() => setBiddingToEdit(null)}
      />
      <CreateBiddingModal
        show={showCreateModal}
        onSuccess={() => {
          fetchBiddings();
          showSuccessfullyCreatedToast();
        }}
        onClose={() => setShowCreateModal(false)}
      />
      <DetailsBiddingModal
        bidding={biddingDetailActions}
        onClose={() => setBiddingDetailActions(null)}
        onAction={onAction}
      />
      <DeleteModal
        closeDeleteModal={() => setOpenDeleteModal(false)}
        onDeleteHandle={onDeleteHandle}
        open={openDeleteModal}
        text="Are you sure you want to delete the biddings?"
      />
    </>
  );
};
export default Biddings;
