import React from 'react';

const BidderPanel = ({ children, title = '' }) => {
  const renderTitle = () => {
    if (typeof title === 'object') {
      return title;
    }

    if (typeof title === 'string' && title.length !== 0) {
      return (
        <div className={'bidder-panel__title'}>
          <h2>{title}</h2>
        </div>
      );
    }

    return '';
  };

  return (
    <>
      <div className={'bidder-panel'}>
        {renderTitle()}
        <div className={'bidder-panel__content'}>{children}</div>
      </div>
    </>
  );
};

export default BidderPanel;
