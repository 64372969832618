import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAuth } from '../../../hooks/auth';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ForgotPasswordModal from '../../../components/ForgotPassword/ForgotPasswordModal';
import PublicLayout from '../../../bidder/layouts/PublicLayout';

const LogIn = () => {
  const [errors, setErrors] = useState({});
  const { login } = useAuth({
    middleware: 'guest',
    redirectIfAuthenticated: '/'
  });
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    login({ email, password, remember: true, setErrors }).then((r) => {
      return r;
    });
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const showSuccessemailedPasswordResetLinkToast = () => {
    dispatch({
      type: 'SHOW',
      text: 'We have emailed your password reset link!',
      toast_type: 'info'
    });
  };

  return (
    <>
      <PublicLayout>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            margin: '0 auto',
            boxSizing: 'border-box',
            width: 622,
            padding: '58px 106px 86px 106px',
            boxShadow:
              '0px 100px 80px rgba(0, 0, 0, 0.1), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0718854), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0596107), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.05), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0403893), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0281146)'
          }}>
          <Typography variant="h3" gutterBottom>
            Log in
          </Typography>
          <Typography variant="body2" color={'#949494'} mb={'30px'}>
            Accept the invitation by log in into you existing account
          </Typography>
          <Stack spacing={4} mb={'40px'}>
            <FormControl fullWidth size={'small'} error={!!errors.email} variant="outlined">
              <InputLabel htmlFor="login-email">Email</InputLabel>
              <OutlinedInput
                id="login-email"
                type={'text'}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                label="Email"
              />
              {typeof errors.email !== 'undefined' && (
                <FormHelperText>{errors.email}</FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth size={'small'} error={!!errors.password} variant="outlined">
              <InputLabel htmlFor="login-password">Password</InputLabel>
              <OutlinedInput
                id="login-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {typeof errors.password !== 'undefined' && (
                <FormHelperText>{errors.password}</FormHelperText>
              )}
            </FormControl>
          </Stack>
          <Stack spacing={3} flexWrap={'wrap'} alignItems={'center'}>
            <Button variant={'fm-outlined'} size={'wide'} type="submit">
              Log In
            </Button>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <Typography variant="smallGreyText">Forgot password?</Typography>&nbsp;
              <Typography
                variant="smallLink"
                onClick={() => {
                  setShowForgotPasswordModal(true);
                }}>
                Click here
              </Typography>
            </Box>
          </Stack>
        </Box>
      </PublicLayout>
      <ForgotPasswordModal
        open={showForgotPasswordModal}
        onClose={() => setShowForgotPasswordModal(false)}
        onSuccess={() => {
          console.log('Success');
          showSuccessemailedPasswordResetLinkToast();
        }}
        onError={() => {
          console.log('error');
        }}
      />
    </>
  );
};
export default LogIn;
