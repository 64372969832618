export default {
  fontFamily: 'Poppins',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  fontSize: 16,
  h1: {
    fontWeight: '600',
    fontSize: '44px',
    lineHeight: '48px',
    letterSpacing: '-0.5px',
    color: '#2E5855'
  },
  h2: {
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '-0.5px',
    color: '#2E5855'
  },
  h3: {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '-0.5px',
    color: '#2E5855'
  },
  h4: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.5px',
    color: '#2E5855'
  },
  body1: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.25px',
    wordBreak: 'break-word',
    color: '#252627'
  },
  body2: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.25px',
    wordBreak: 'break-word',
    color: '#252627'
  },
  body3: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '-0.25px',
    wordBreak: 'break-word',
    color: '#949494'
  },
  'modal-title': {
    fontFamily: 'Poppins',
    color: '#5F5F5F',
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: 600
  },
  'modal-text': {
    fontFamily: 'Poppins',
    color: '#848484',
    fontSize: '16px',
    lineHeight: '150%'
  }
};
