import { useParams } from 'react-router-dom';
import { authService } from '../../../services/authService';
import { useNavigate } from 'react-router-dom';

const AuthLink = () => {
  const { link } = useParams();
  const navigate = useNavigate();
  authService
    .checkAuthLink({
      link: link
    })
    .then((res) => {
      switch (res.data.user.role) {
        case 'admin':
          navigate('/dashboard');
          break;
        case 'vendor':
          navigate('/profile/edit');
          break;
        case 'client':
          navigate('/profile/edit');
          break;
      }
    })
    .catch((err) => console.log('error', err));
};
export default AuthLink;
