import { useLocation } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import React from 'react';

const ErrorPage = () => {
  const location = useLocation();
  const state = location.state || { status: 404, message: 'Unknown error!' };

  if (state.status === 401) {
    state.message = 'You are not authorized to access this page, please log in!';
  }

  return (
    <>
      <Container>
        <Box
          sx={{
            boxSizing: 'border-box',
            width: '100%',
            maxWidth: '1220px',
            padding: '38px 69px 38px 76px',
            boxShadow:
              '0px 100px 80px rgba(0, 0, 0, 0.1), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0718854), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0596107), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.05), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0403893), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0281146)'
          }}>
          <h1>{state.status ?? 404}</h1>

          {state.status === 401 ? (
            <>
              <h2>Unauthorized</h2>
              <a href={'/login'}>Log in</a>
            </>
          ) : (
            <h2>Something went wrong!</h2>
          )}

          <p>{state.message ?? 'Error!'}</p>
        </Box>
      </Container>
    </>
  );
};

export default ErrorPage;
