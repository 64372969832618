import { Checkbox, Link, Typography } from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';
import * as React from 'react';
import { formatDateWithTimezone } from '../../../services/date';
import { useState } from 'react';
import { ReactComponent as Down } from '../../../bidder/assets/icons/down.svg';
import { linkOfBidding } from '../../../bidder/services/links';
import TableHeaderCell from '../../../bidder/components/Table/TableHeaderCell';
import TableHeader from '../../../bidder/components/Table/TableHeader';
import TableBody from '../../../bidder/components/Table/TableBody';
import StatusButton from '../../../bidder/components/Buttons/Bidding/StatusButton';

function BiddingTableRow({ bidding, onAction, onCheckRow, selected }) {
  const [open, setOpen] = useState(true);

  return (
    <>
      <tr className={'biddings-table__row-macro'} onClick={() => onAction(bidding)}>
        <td
          className={'cursor-pointer'}
          onClick={(event) => {
            event.stopPropagation();
            setOpen(!open);
          }}>
          <Down style={open ? { transform: `rotate(180deg)` } : {}} />
        </td>
        <td onClick={(event) => onCheckRow(event, bidding)}>
          <Checkbox checked={selected.includes(bidding.id)} color="success" />
        </td>
        <td className={'align-left-column'}>
          <Link
            to={linkOfBidding(bidding)}
            component={ReactLink}
            onClick={(event) => event.stopPropagation()}
            sx={{ textDecoration: 'none !important' }}>
            <Typography variant="body1" fontWeight={'bold'}>
              {bidding.name}
            </Typography>
            <Typography variant="body2">{bidding.file_code}</Typography>
            {bidding.parent_id ? (
              <Typography variant={'body2'}>{bidding?.parent_biding_name}</Typography>
            ) : (
              ''
            )}
          </Link>
        </td>
        <td>
          <Typography variant="body2">{bidding.company}</Typography>
        </td>
        <td>
          <StatusButton bidding={bidding} />
        </td>
        <td>
          <Typography variant="body2">
            {formatDateWithTimezone(bidding.start_date, bidding.timezone)}
          </Typography>
        </td>
        <td>
          <Typography variant="body2">
            {formatDateWithTimezone(bidding.end_date, bidding.timezone)}
          </Typography>
        </td>
      </tr>
      {open &&
        bidding.biddings.map((row) => {
          return (
            <tr key={row.id} className={'pointer'} onClick={() => onAction(row)}>
              <td />
              <td onClick={(event) => onCheckRow(event, row)}>
                <Checkbox checked={selected.includes(row.id)} color="success" />
              </td>
              <td className={'align-left-column'}>
                <Link
                  to={linkOfBidding(row)}
                  component={ReactLink}
                  onClick={(event) => event.stopPropagation()}
                  sx={{ textDecoration: 'none !important' }}>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {row.name}
                  </Typography>
                  <Typography variant="body2">{row.file_code}</Typography>
                  {row.parent_id ? (
                    <Typography variant={'body2'}>{row?.parent_biding_name}</Typography>
                  ) : (
                    ''
                  )}
                </Link>
              </td>
              <td>
                <Typography variant="body2">{row.company}</Typography>
              </td>
              <td>
                <StatusButton bidding={row} />
              </td>
              <td>
                <Typography variant="body2">
                  {formatDateWithTimezone(row.start_date, row.timezone)}
                </Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {formatDateWithTimezone(row.end_date, row.timezone)}
                </Typography>
              </td>
            </tr>
          );
        })}
    </>
  );
}

const BiddingsTable = ({ data, onAction, selected = [], onCheckRow }) => {
  const headerDefinitions = [
    {
      name: '',
      key: 'none'
    },
    {
      name: '',
      key: 'checkbox'
    },
    {
      name: 'Bidding name',
      key: 'name',
      sortable: false,
      class: 'align-left-column'
    },
    {
      name: 'Company Client',
      key: 'status'
    },
    {
      name: 'Status',
      key: 'stage'
    },
    {
      name: 'Start date',
      key: 'start_date',
      sortable: false
    },
    {
      name: 'Finish date',
      key: 'end_date',
      sortable: false
    }
  ];

  return (
    <>
      <table className={'biddings-table'}>
        <TableHeader>
          <tr>
            {headerDefinitions.map((column) => (
              <TableHeaderCell key={column.key} column={column} />
            ))}
          </tr>
        </TableHeader>
        <TableBody>
          {data.map((row) => (
            <BiddingTableRow
              bidding={row}
              key={row.id}
              onAction={(bidding) => onAction('details', bidding)}
              onCheckRow={(event, bidding) => {
                event.stopPropagation();
                onCheckRow(bidding.id, !selected.includes(bidding.id));
              }}
              selected={selected}
            />
          ))}
          {data.length === 0 && (
            <tr>
              <td colSpan={headerDefinitions.length} style={{ textAlign: 'center' }}>
                <Typography variant="body1" fontWeight={'bold'}>
                  No biddings found
                </Typography>
              </td>
            </tr>
          )}
        </TableBody>
      </table>
    </>
  );
};

export default BiddingsTable;
