import { Button, Stack, Typography } from '@mui/material';
import { formatDateTime } from '../../../services/date';
import { useState } from 'react';
import SignatureReviewModal from './SignatureReviewModal';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import TableHeader from '../../../bidder/components/Table/TableHeader';
import Table from '../../../bidder/components/Table/Table';
import TableBody from '../../../bidder/components/Table/TableBody';

export default function AccessConfirmation({ vendorId, biddingId, stageData, onUpdate }) {
  const [signatureToChangeStatus, setSignatureToChangeStatus] = useState(null);
  const [statusOfSignatureToChangeStatus, setStatusOfSignatureToChangeStatus] = useState(null);

  return (
    <BidderPanel title={'Access confirmation'}>
      <Table>
        <TableHeader>
          <tr>
            <th className={'align-left-column'}>Document</th>
            <th>Actions</th>
            <th style={{ width: '150px' }}>Updated</th>
          </tr>
        </TableHeader>
        <TableBody>
          {stageData !== null &&
            stageData.documents.map((document) => (
              <tr key={document.id}>
                <td className={'align-left-column'}>
                  <Typography variant={'body1'} fontWeight={'bold'} color={'#40464C'}>
                    {document.type}
                  </Typography>
                </td>
                <td>
                  <Stack direction={'row'} spacing={1} justifyContent={'center'}>
                    <Button
                      target={'_blank'}
                      href={document.src_download}
                      variant={'fm-light-outlined'}>
                      Download
                    </Button>
                    <Button
                      target={'_blank'}
                      href={document.src_view}
                      variant={'fm-light-outlined'}>
                      View
                    </Button>
                    <Button
                      variant={'fm-light-outlined'}
                      onClick={() => {
                        setSignatureToChangeStatus(document);
                        setStatusOfSignatureToChangeStatus('accepted');
                      }}
                      disabled={document.status === 'accepted'}>
                      Accept
                    </Button>
                    <Button
                      variant={'fm-light-outlined'}
                      onClick={() => {
                        setSignatureToChangeStatus(document);
                        setStatusOfSignatureToChangeStatus('rejected');
                      }}
                      disabled={document.status === 'rejected'}>
                      Reject
                    </Button>
                  </Stack>
                </td>
                <td>{document.timestamp !== null && formatDateTime(document.updated_at)}</td>
              </tr>
            ))}
        </TableBody>
      </Table>
      <SignatureReviewModal
        vendor_id={vendorId}
        bidding_id={biddingId}
        signature={signatureToChangeStatus}
        status={statusOfSignatureToChangeStatus}
        onClose={() => {
          setSignatureToChangeStatus(null);
          setStatusOfSignatureToChangeStatus(null);
        }}
        onSuccess={() => {
          onUpdate();
          setSignatureToChangeStatus(null);
          setStatusOfSignatureToChangeStatus(null);
        }}
      />
    </BidderPanel>
  );
}
