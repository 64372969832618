import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { userService } from '../../../services/admin';
import CreateUserModal from '../../../components/Dashboard/Users/Modals/UserManagement/CreateUserModal';
import EditUserModal from '../../../components/Dashboard/Users/Modals/UserManagement/EditUserModal';
import { useDispatch } from 'react-redux';
import { capitalizeFirstLetter } from '../../../services/string';
import { formatDateTime } from '../../../services/date';
import SearchInput from '../../../components/SearchInput/SearchInput';

import PageWrapper from '../../../bidder/components/Main/PageWrapper';
import Header from '../../../bidder/components/Main/Header';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import Content from '../../../bidder/components/Main/Content';
import UserAvatar from '../../../bidder/components/Dashboard/Users/UserAvatar';
import { notify } from '../../../bidder/helpers/notify';
import TablePagination from '@mui/material/TablePagination';
import Table from '../../../bidder/components/Table/Table';
import TableHeader from '../../../bidder/components/Table/TableHeader';
import TableBody from '../../../bidder/components/Table/TableBody';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { Link } from 'react-router-dom';

const Users = () => {
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [userForEdit, setUserForEdit] = useState(null);
  const [userForDelete, setUserForDelete] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const dispatch = useDispatch();
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('active');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const showSuccessfullyCreatedToast = (user) => {
    notify(dispatch, `${capitalizeFirstLetter(user.role)} successfully created`, 'success');
  };

  const onCheckRow = (id, checked = true) => {
    if (checked) {
      if (!checkedList.includes(id)) {
        setCheckedList((prevState) => [id, ...prevState]);
      }
    } else {
      if (checkedList.includes(id)) {
        let newSelectedList = checkedList.filter((value) => value !== id);
        setCheckedList(newSelectedList);
      }
    }
  };

  const onEditUserClick = () => {
    if (checkedList.length === 1) {
      setUserForEdit(data.data.find((user) => user.id === checkedList[0]));
    }
  };
  const onDeleteUserClick = () => {
    if (checkedList.length === 1) {
      setUserForDelete(data.data.find((user) => user.id === checkedList[0]));
    }
  };
  const getUsersData = () => {
    userService
      .index({
        params: {
          filter: {
            search,
            role,
            status
          },
          page: page + 1,
          per_page: rowsPerPage
        }
      })
      .then((res) => {
        setData(res.data);
        setCheckedList([]);
      });
  };

  const onDeleteHandle = () => {
    userService
      .destroy(userForDelete.id)
      .then((response) => {
        setUserForDelete(null);
        getUsersData();
        notify(dispatch, response.data.message, 'success');
      })
      .catch((error) => {
        notify(dispatch, error.response.data.message, 'error');
      });
  };

  useEffect(() => {
    setCheckedList([]);
    getUsersData();
  }, [search, page, rowsPerPage, role, status]);

  const renderHeaderContentCallback = () => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '45px',
            padding: '0 10px'
          }}>
          <Box sx={{ width: '520px' }}>
            <SearchInput
              value={search}
              onChange={(event) => {
                setPage(0);
                setSearch(event.target.value);
              }}
            />
          </Box>
          <Stack direction="row" spacing={1}>
            <Button
              variant={'fm-action'}
              size={'small'}
              color={'success'}
              onClick={() => {
                setShowCreateUserModal(true);
              }}>
              New
            </Button>
            <Button
              variant={'fm-action'}
              disabled={checkedList.length !== 1}
              onClick={() => onEditUserClick()}>
              Edit
            </Button>
            <Button
              variant={'fm-action'}
              disabled={checkedList.length !== 1}
              onClick={() => {
                onDeleteUserClick();
              }}>
              Delete
            </Button>
          </Stack>
        </Box>
      </>
    );
  };

  return (
    <>
      <PageWrapper>
        <Header
          title="User Management Dashboard"
          renderHeaderContentCallback={renderHeaderContentCallback}
        />
        <Content>
          <BidderPanel>
            <div>
              <Stack direction="row" spacing={1}>
                <ToggleButtonGroup
                  color="primary"
                  value={status}
                  exclusive
                  onChange={(event, newFilterStatus) => {
                    setPage(0);
                    setStatus(newFilterStatus);
                  }}
                  size="small"
                  aria-label="Filter by status">
                  <ToggleButton value="active">Active</ToggleButton>
                  <ToggleButton value="inactive">Inactive</ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                  color="primary"
                  value={role}
                  exclusive
                  onChange={(event, newFilterRole) => {
                    setPage(0);
                    setRole(newFilterRole);
                  }}
                  size="small"
                  aria-label="Filter by role">
                  <ToggleButton value="">All</ToggleButton>
                  <ToggleButton value="admin">Admin</ToggleButton>
                  <ToggleButton value="vendor">Vendor</ToggleButton>
                  <ToggleButton value="client">Client</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </div>
            <Table>
              <TableHeader>
                <tr>
                  <th />
                  <th className={'align-left-column'} style={{ width: '200px' }}>
                    Name
                  </th>
                  <th style={{ width: '200px' }}>Company</th>
                  <th>Email</th>
                  <th>Last In</th>
                </tr>
              </TableHeader>
              <TableBody>
                {data !== null &&
                  data.data.map((user) => {
                    return (
                      <tr key={user.id}>
                        <td
                          onClick={(event) => {
                            event.stopPropagation();
                            onCheckRow(user.id, !checkedList.includes(user.id));
                          }}>
                          <Checkbox checked={checkedList.includes(user.id)} color="success" />
                        </td>
                        <td className={'align-left-column'}>
                          <Grid container justifyContent="center" alignItems="center">
                            <Grid item xs={3}>
                              <UserAvatar user={user} />
                            </Grid>
                            <Grid item xs={9}>
                              {user.role === 'vendor' ? (
                                <Link to={`/dashboard/vendors/${user.id}`}>
                                  <Typography variant="body1" fontWeight={'bold'}>
                                    {user.name}
                                  </Typography>
                                  <Typography variant="body2">{user.role}</Typography>
                                </Link>
                              ) : (
                                <>
                                  <Typography variant="body1" fontWeight={'bold'}>
                                    {user.name}
                                  </Typography>
                                  <Typography variant="body2">{user.role}</Typography>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </td>
                        <td>
                          <Typography variant="DarkGreyText14">{user.company}</Typography>
                        </td>
                        <td>
                          <Typography variant={'body2'}>{user.email}</Typography>
                        </td>
                        <td>
                          <Typography variant={'body2'}>
                            <Link to={`/dashboard/users/${user.id}/activity`}>
                              {user.last_in ? formatDateTime(user.last_in) : "didn't visit"}
                            </Link>
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
              </TableBody>
            </Table>
            {data !== null && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.meta.total}
                rowsPerPage={data.meta.per_page}
                page={data.meta.current_page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </BidderPanel>
        </Content>
      </PageWrapper>
      <CreateUserModal
        open={showCreateUserModal}
        onClose={() => setShowCreateUserModal(false)}
        onSuccess={(user) => {
          getUsersData();
          showSuccessfullyCreatedToast(user);
          setCheckedList([]);
        }}
        onError={() =>
          notify(dispatch, 'Please check the correctness of the entered data', 'error')
        }
      />
      <EditUserModal
        user={userForEdit}
        onClose={() => setUserForEdit(null)}
        onSuccess={() => {
          getUsersData();
          notify(dispatch, 'User successfully updated');
          setCheckedList([]);
        }}
      />
      <DeleteModal
        closeDeleteModal={() => setUserForDelete(null)}
        onDeleteHandle={onDeleteHandle}
        open={userForDelete !== null}
        text="Are you sure you want to delete the user?"
      />
    </>
  );
};
export default Users;
