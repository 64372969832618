import axios from './axios';

export const authService = {
  checkAuthLink: (data) => {
    return axios.post('api/check-link', data);
  },
  resetPassword: (data) => {
    return axios.post('forgot-password', data);
  },
  newPassword: (data) => {
    return axios.post('password-reset', data);
  }
};
