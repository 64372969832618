import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@mui/material';
import React from 'react';
import Stack from '@mui/material/Stack';
import FileUploadIcon from '@mui/icons-material/FileUpload';

export default function CompanyForm({ model, errors, onChangeHandler }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <div style={{ justifyContent: 'center', display: 'flex', height: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '15px',
              alignSelf: 'center',
              alignContent: 'center'
            }}>
            {model.logo_url && (
              <img
                style={{
                  maxWidth: '186px',
                  maxHeight: '186px',
                  borderRadius: '9px',
                  border: '1px solid 0, 0, 0, 0.20'
                }}
                src={model.logo_url}
                alt={'logo'}
              />
            )}
            {!model.logo_url && (
              <div
                style={{
                  maxWidth: '186px',
                  maxHeight: '186px',
                  borderRadius: '9px',
                  border: '1px solid 0, 0, 0, 0.20'
                }}>
                <svg
                  width="186"
                  height="186"
                  viewBox="0 0 186 186"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M92.998 52.7632V117.762"
                    stroke="black"
                    strokeWidth="2.08134"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M49.666 111.572V136.333H136.331V111.572"
                    stroke="black"
                    strokeWidth="2.08134"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M74.4281 68.2393C81.6806 60.9868 85.7467 56.9206 92.9992 49.6682L111.57 68.2393"
                    stroke="black"
                    strokeWidth="2.08134"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="0.779817"
                    y="0.779817"
                    width="184.44"
                    height="184.44"
                    rx="8.57798"
                    stroke="black"
                    strokeOpacity="0.2"
                    strokeWidth="1.55963"
                  />
                </svg>
              </div>
            )}
            <div>
              <FormControl>
                <Button variant="fm-outlined" component="label">
                  <FileUploadIcon />
                  <input onChange={onChangeHandler} name="logo" type="file" hidden />
                  {model.logo ? model.logo.name : ''}
                </Button>
                {typeof errors.logo !== 'undefined' && (
                  <FormHelperText error>{errors.logo}</FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={9}>
        <Stack spacing={2}>
          <TextField
            value={model.name}
            onChange={onChangeHandler}
            error={!!errors.name}
            helperText={errors.name}
            name={'name'}
            fullWidth
            variant="outlined"
            label="Company name"
            size={'small'}
          />
          <TextField
            value={model.nif}
            onChange={onChangeHandler}
            error={!!errors.nif}
            helperText={errors.nif}
            name={'nif'}
            fullWidth
            variant="outlined"
            label="NIF"
            size={'small'}
          />
          <TextField
            value={model.product_category}
            onChange={onChangeHandler}
            error={!!errors.product_category}
            helperText={errors.product_category}
            name={'product_category'}
            fullWidth
            variant="outlined"
            label="Product category"
            size={'small'}
          />
          <TextField
            value={model.country}
            onChange={onChangeHandler}
            error={!!errors.country}
            helperText={errors.country}
            name={'country'}
            fullWidth
            variant="outlined"
            label="Country of operation"
            size={'small'}
          />
          <FormControl fullWidth size={'small'} error={!!errors.status}>
            <InputLabel id="status-input">Status</InputLabel>
            <Select
              fullWidth
              labelId="status-input"
              label="Status"
              name={'status'}
              value={model.status}
              input={<OutlinedInput label="Company" error={!!errors.status} />}
              onChange={onChangeHandler}>
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
            {(errors?.status ?? false) && <FormHelperText>{errors.status}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth size={'small'} error={!!errors.role}>
            <InputLabel id="role-input">Role</InputLabel>
            <Select
              fullWidth
              labelId="role-input"
              label="Role"
              name={'role'}
              value={model.role}
              input={<OutlinedInput label="Company" error={!!errors.role} />}
              onChange={onChangeHandler}>
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="client">Client</MenuItem>
              <MenuItem value="vendor">Vendor</MenuItem>
            </Select>
            {(errors?.role ?? false) && <FormHelperText>{errors.role}</FormHelperText>}
          </FormControl>
          <TextField
            value={model.color}
            onChange={onChangeHandler}
            error={!!errors.color}
            helperText={errors.color}
            name={'color'}
            fullWidth
            type={'color'}
            variant="outlined"
            label="Color"
            size={'small'}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
