import { Stack } from '@mui/material';

const OutlinedButtonsWrapper = ({ children }) => {
  return (
    <Stack
      direction={'row'}
      spacing={3}
      justifyContent={'center'}
      sx={{ marginTop: '35px', marginBottom: '35px' }}>
      {children}
    </Stack>
  );
};
export default OutlinedButtonsWrapper;
