import * as React from 'react';
import { Box, Button, Container, Stack, TextField } from '@mui/material';
import PublicLayout from '../../../bidder/layouts/PublicLayout';

const ContactUs = () => {
  const acceptHandle = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <PublicLayout title="Contact us">
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Box
            component="form"
            onSubmit={acceptHandle}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '30px 24px',
              width: '500px',
              boxShadow:
                '0px 100px 80px rgba(0, 0, 0, 0.1), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0718854), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0596107), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.05), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0403893), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0281146)'
            }}>
            <Stack spacing={2}>
              <TextField size={'small'} type={'text'} variant={'outlined'} label="Name" />
              <TextField size={'small'} type={'text'} variant={'outlined'} label="Phone" />
              <TextField size={'small'} type={'text'} variant={'outlined'} label="Email" />
              <TextField
                rows={10}
                multiline
                type={'textarea'}
                variant={'outlined'}
                label="Message"
              />

              <Button type="submit" variant="fm-outlined" size={'wide'}>
                Send
              </Button>
            </Stack>
          </Box>
        </Container>
      </PublicLayout>
    </>
  );
};
export default ContactUs;
