import { useEffect, useState } from 'react';

export default function NotificationWidget({ notifications = [] }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (notifications.length === 0) {
      setOpen(false);
    }
  }, [notifications]);
  const getButtonClassName = () => {
    if (open) {
      return 'notification-widget__button notification-widget__button--purple';
    }

    if (notifications.length > 0) {
      return 'notification-widget__button notification-widget__button--orange';
    }

    return 'notification-widget__button notification-widget__button--disabled';
  };
  const outsideClickHandler = (e) => {
    if (e.target.closest('.notification-widget')) {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    document.addEventListener('click', outsideClickHandler);

    return () => {
      document.removeEventListener('click', outsideClickHandler);
    };
  }, []);

  return (
    <>
      <div className={'notification-widget'}>
        <button
          className={getButtonClassName()}
          onClick={() => (notifications.length > 0 ? setOpen(!open) : null)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M13 2L3 14H12L11 22L21 10H12L13 2Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {open && (
          <div className={'notification-widget__content'}>
            <div className={'notification-widget__content__header'}>
              <span>Notifications</span>
            </div>
            <div className={'notification-widget__content__body'}>
              {notifications.map((notification) => (
                <div
                  className={'notification-widget__content__body__item'}
                  key={notification.title}>
                  <div className={'notification-widget__content__body__item__header'}>
                    {notification.title}
                  </div>
                  <div className={'notification-widget__content__body__item__body'}>
                    {notification.body}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
