export default function Table({ children, layout = 'auto', className = '' }) {
  const styles = {
    tableLayout: layout
  };

  const computedClass = `bidder-table ${className}`;

  return (
    <>
      <table className={computedClass} style={styles}>
        {children}
      </table>
    </>
  );
}
