import { Button, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { biddingService } from '../../../../services/client';
import * as React from 'react';
import { useState } from 'react';
import BidderLogo from '../../../assets/images/bidder.svg';

export default function RequirementAuthorizationModal({ requirement, onApprove, onClose }) {
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState('');
  const handleApproveAuthorization = () => {
    console.log('Approve');

    biddingService
      .approveAuthorizationRequirement(requirement.bidding_id, requirement.stage, requirement.id, {
        password
      })
      .then((result) => {
        onApprove();
        console.log(result);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  const handleCloseAuthorization = () => {
    console.log('Close');
    onClose();
  };

  return (
    <>
      <div className={'authorization-overlay'}>
        <div className={'authorization-modal authorization-modal-with-background'}>
          <div className={'authorization-modal-content'}>
            <div className={'authorization-modal-logo'}>
              <img src={BidderLogo} alt={'Bidder Logo'} />
            </div>
            <div className={'authorization-modal-header'}>Action Needed</div>
            <div className={'authorization-modal-body'}>
              <div className={'stack'}>
                <div>
                  The subsequent requirement is meant to be accessible under controlled conditions.
                  The bidding administrator is currently seeking approval to release the documents.
                </div>

                <div>
                  <strong>Macro Bidding “ {requirement.macro_title} “</strong>
                  <br />
                  <strong>SubBidding “ {requirement.micro_title} ”</strong>
                  <br />
                  <strong>{requirement.name}</strong>
                </div>
                <div>If you authorize the controlled access, kindly input your password below.</div>
              </div>
              <div className={'authorization-modal-body--password-holder'}>
                <FormControl fullWidth size={'small'} error={!!errors.password} variant="outlined">
                  <InputLabel htmlFor="login-password">Password</InputLabel>
                  <OutlinedInput
                    id="login-password"
                    type={'password'}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    label="Password"
                  />
                  {typeof errors.password !== 'undefined' && (
                    <FormHelperText>{errors.password}</FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
            <div className={'authorization-modal-footer'}>
              <Button variant="fm-outlined" onClick={handleApproveAuthorization}>
                Accept
              </Button>
              <Button variant="fm-outlined" onClick={handleCloseAuthorization}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
