import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch
} from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { autoCompleteService, biddingService } from '../../../../services/admin';
import dayjs from 'dayjs';
import BiddingForm from './BiddingForm';

const CreateBiddingModal = ({ show, onSuccess, onClose }) => {
  const [companies, setCompanies] = useState(null);
  const [timezones, setTimezones] = useState(null);
  const [errors, setErrors] = useState({});
  const [disabledInputs] = useState({});
  const [values, setValues] = useState({
    name: '',
    file_code: '',
    start_date: '',
    end_date: '',
    timezone: '',
    client_anonymous: false
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeCheckbox = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };
  const handleChangeDate = (prop) => (date) => {
    setValues({ ...values, [prop]: dayjs(date).format('YYYY-MM-DD') });
  };

  useEffect(() => {
    if (show) {
      autoCompleteService.timezones().then((res) => {
        setTimezones(res.data);
      });
      autoCompleteService
        .companies({
          params: {
            filter: {
              status: 'active',
              role: 'client'
            }
          }
        })
        .then((res) => {
          setCompanies(res.data);
        });
    }
  }, [show]);

  const handleOnClose = () => {
    setErrors({});
    setValues({
      name: '',
      company_id: '',
      file_code: '',
      start_date: '',
      end_date: '',
      timezone: '',
      client_anonymous: false
    });
    onClose();
  };

  const acceptHandle = (event) => {
    event.preventDefault();
    biddingService
      .store(values)
      .then(() => {
        onSuccess();
        handleOnClose();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          let response = error.response.data;
          setErrors(response.errors);
        }
      });
  };

  if (show === false || companies === null || timezones === null) {
    return '';
  }

  return (
    <Dialog
      open={true}
      keepMounted
      onClose={handleOnClose}
      aria-describedby="alert-dialog-slide-description">
      <Box component="form" onSubmit={acceptHandle} sx={{ width: '535px' }}>
        <DialogTitle>New Bidding</DialogTitle>
        <DialogContent>
          <BiddingForm
            errors={errors}
            disabledInputs={disabledInputs}
            values={values}
            companies={companies}
            timezones={timezones}
            onChangeName={handleChange('name')}
            onChangeCompanyId={handleChange('company_id')}
            onChangeFileCode={handleChange('file_code')}
            onChangeStartDate={handleChangeDate('start_date')}
            onChangeEndDate={handleChangeDate('end_date')}
            onChangeTimezone={handleChange('timezone')}
          />
        </DialogContent>
        <DialogActions sx={{ flexWrap: 'wrap' }}>
          <Box sx={{ alignSelf: 'middle', flexGrow: 2 }}>
            <FormControlLabel
              checked={values.client_anonymous}
              labelPlacement="start"
              onChange={handleChangeCheckbox('client_anonymous')}
              control={<Switch color={'success'} />}
              label="Client anonymous"
            />
          </Box>
          <Button variant={'fm-light'} type="submit">
            Accept
          </Button>
          <Button variant={'fm-light'} onClick={handleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreateBiddingModal;
