import React, { useEffect, useState } from 'react';
import { biddingService } from '../../../services/client';
import BiddingsTable from '../../../components/Client/BiddingsTable';
import PageWrapper from '../../../bidder/components/Main/PageWrapper';
import Header from '../../../bidder/components/Main/Header';
import BidderPanel from '../../../bidder/components/Panels/BidderPanel';
import Content from '../../../bidder/components/Main/Content';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import SearchInput from '../../../components/SearchInput/SearchInput';

export default function Biddings() {
  const [biddings, setBiddings] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');

  async function fetchBiddings(params) {
    biddingService.getBiddings(params).then((result) => setBiddings(result.data));
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchBiddings({
      filter: {
        status: status,
        search: search
      },
      page: page + 1,
      per_page: rowsPerPage
    });
  }, [page, rowsPerPage, search, status]);

  return (
    <>
      <PageWrapper>
        <Header
          title={'Client Active Biddings'}
          renderHeaderContentCallback={() => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '45px'
              }}>
              <Box sx={{ width: '380px' }}>
                <SearchInput
                  value={search}
                  onChange={(event) => {
                    setPage(0);
                    setSearch(event.target.value);
                  }}
                />
              </Box>
            </Box>
          )}
        />
        <Content>
          <BidderPanel>
            <ToggleButtonGroup
              color="primary"
              value={status}
              exclusive
              onChange={(event, newStatus) => {
                setPage(0);
                setStatus(newStatus);
              }}
              size="small"
              aria-label="Filter by status">
              <ToggleButton value="">All</ToggleButton>
              <ToggleButton value="active">Active</ToggleButton>
              <ToggleButton value="inactive">Inactive</ToggleButton>
              <ToggleButton value="complete">Complete</ToggleButton>
            </ToggleButtonGroup>
            <BiddingsTable
              biddings={biddings}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </BidderPanel>
        </Content>
      </PageWrapper>
    </>
  );
}
