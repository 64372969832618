import axios from './axios';

const prefix = '/api/dashboard';

export const userService = {
  index: (params = {}) => {
    return axios.get(`${prefix}/users`, params);
  },
  store: (user) => {
    return axios.post(`${prefix}/users`, { ...user });
  },
  update: (id, user) => {
    return axios.put(`${prefix}/users/${id}`, { ...user });
  },
  resetPassword: (id) => {
    return axios.post(`${prefix}/users/${id}/reset-password`);
  },
  sendVendorInvite: (id) => {
    return axios.post(`${prefix}/users/${id}/send-invitation`);
  },
  destroy: (id) => {
    return axios.delete(`${prefix}/users/${id}`);
  },
  activity: (id, params = {}) => {
    return axios.get(`${prefix}/users/${id}/activity`, params);
  }
};

export const biddingService = {
  index: (params = {}) => {
    return axios.get(`${prefix}/biddings`, params);
  },
  show: (id) => {
    return axios.get(`${prefix}/biddings/${id}`);
  },
  store: (data) => {
    return axios.post(`${prefix}/biddings`, data);
  },
  update: (id, data) => {
    return axios.put(`${prefix}/biddings/${id}`, data);
  },
  destroy: (id) => {
    return axios.delete(`${prefix}/biddings/${id}`);
  },
  destroyMany: (ids) => {
    return axios.post(`${prefix}/biddings/mass-delete`, ids);
  }
};

export const subBiddingService = (biddingId) => {
  return {
    index: () => {
      return axios.get(`${prefix}/biddings/${biddingId}/biddings`);
    },
    store: (data) => {
      return axios.post(`${prefix}/biddings/${biddingId}/biddings`, data);
    },
    update: (id, data) => {
      return axios.put(`${prefix}/biddings/${biddingId}/biddings/${id}`, data);
    },
    destroy: (id) => {
      return axios.delete(`${prefix}/biddings/${biddingId}/biddings/${id}`);
    }
  };
};

export const autoCompleteService = {
  vendors: (filter = '', exclude = []) => {
    return axios.get(`${prefix}/auto-complete/vendors`, {
      params: { filter, exclude }
    });
  },
  timezones: (filter = '', exclude = []) => {
    return axios.get(`${prefix}/auto-complete/timezones`, {
      params: { filter, exclude }
    });
  },
  companies: (params = {}) => {
    return axios.get(`${prefix}/auto-complete/companies`, params);
  }
};

export const invitationsService = (biddingId) => {
  let invitationsPrefix = `${prefix}/biddings/${biddingId}/vendor-invitations`;

  return {
    index: () => {
      return axios.get(`${invitationsPrefix}`);
    },
    sendInvitations: () => {
      return axios.post(`${invitationsPrefix}/send-invites`);
    },
    finish: () => {
      return axios.post(`${invitationsPrefix}/finish`);
    },
    assignedVendorSubBiddings: (vendorId) => {
      return axios.get(`${invitationsPrefix}/vendor/${vendorId}`);
    },
    removedVendorSubBiddings: (vendorId) => {
      return axios.delete(`${invitationsPrefix}/vendor/${vendorId}`);
    },
    assignVendorSubBiddings: (data) => {
      return axios.post(`${invitationsPrefix}/vendor/assign`, data);
    },
    changeVendorSubBiddings: (data) => {
      return axios.post(`${invitationsPrefix}/vendor/change`, data);
    }
  };
};

export const requirementSelectionService = (biddingId) => {
  let requirementSelectionPrefix = `${prefix}/biddings/${biddingId}/requirements`;

  return {
    stage: (type) => {
      return {
        show: () => {
          return axios.get(`${requirementSelectionPrefix}/${type}`);
        },
        update: (data) => {
          return axios.post(`${requirementSelectionPrefix}/${type}`, data);
        },
        biddingDocument: {
          index: () => {
            return axios.get(`${requirementSelectionPrefix}/${type}/documents`);
          },
          store: (data) => {
            return axios.post(`${requirementSelectionPrefix}/${type}/documents`, data);
          },
          update: (id, data) => {
            return axios.post(
              `${requirementSelectionPrefix}/${type}/documents/${id}?_method=PUT`,
              data
            );
          },
          unlock: (id) => {
            return axios.post(`${requirementSelectionPrefix}/${type}/documents/${id}/unlock`);
          },
          destroy: (id) => {
            return axios.delete(`${requirementSelectionPrefix}/${type}/documents/${id}`);
          },
          massDestroy: (ids = []) => {
            return axios.post(`${requirementSelectionPrefix}/${type}/documents/mass-destroy`, {
              ids
            });
          }
        },
        scoreStructure: {
          index: () => {
            return axios.get(`${requirementSelectionPrefix}/${type}/score`);
          },
          store: (data) => {
            return axios.post(`${requirementSelectionPrefix}/${type}/score`, data);
          },
          update: (id, data) => {
            return axios.put(`${requirementSelectionPrefix}/${type}/score/${id}`, data);
          },
          destroy: (id) => {
            return axios.delete(`${requirementSelectionPrefix}/${type}/score/${id}`);
          }
        }
      };
    }
  };
};
